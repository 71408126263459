import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import { useState } from "react";
import { toast } from "react-toastify";
import axiosApi from "../../../../utils/axios";
import TextField from "../../../../components/Form/TextField/TextField";
import Buttons from "../../../../components/Form/Button/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const EnteryourPassword = (props) => {
  const user = useSelector((state) => state.auth.user);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (password?.length > 0) {
      setPasswordError(false);
    }
  }, [password]);

  const checkUser = async () => {
    if (password === "") {
      setPasswordError("Requried");
      return false;
    }
    ///===================== recapcha ==============================///
    window.grecaptcha.ready(async () => {
      try {
        const token = await window.grecaptcha.execute(
          `${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`,
          { action: "submit" }
        );
        ///===================== recapcha ==============================///
        let data = {
          email: user.email,
          password: password,
          token,
        };
        try {
          const result = await axiosApi.post("/users/public/login", data);
          if (result?.data?.error) {
            toast.error("Password is not correct");
            return;
          } else {
            setPasswordError(false);
            toast.success("Information was correct");
            props.cancleButtonOperation();
            props.setShowFullBirthDay(true);
          }
        } catch (err) {
          console.log(err);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    });
  };
  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center !important",
              fontSize: "24px !important",
              fontWeight: "600 !important",
            }}
          >
            Enter your password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center !important",
              fontSize: "14px !important",
              fontWeight: "400 !important",
            }}
          >
            To access certain information, please confirm your password
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label=""
            placeholder="Confirm password"
            validation={false}
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            password={!isChecked ? "password" : ""}
            error={password?.length === 0 ? passwordError : ""}
            helperText={
              passwordError ? (
                <span style={{ color: "#BA1A1A" }}>{passwordError}</span>
              ) : (
                ""
              )
            }
            value={password}
            handleChange={(val) => setPassword(val)}
            endAdornment={
              <span
                style={{ marginTop: "4px" }}
                onClick={() => setIsChecked(() => !isChecked)}
              >
                {!isChecked ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </span>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Buttons
            label="Continue"
            buttonColor="#5423FF"
            // border="#E4E8EC 1px solid"
            color="#fff"
            width="100%"
            height="48px"
            fontSize="14px"
            fontWeight={600}
            // link=""
            onClick={(e) => checkUser()}
          />
          <Buttons
            label="cancel"
            buttonColor="transparent"
            // border="#E4E8EC 1px solid"
            color="#5423FF"
            width="100%"
            height="60px"
            fontSize="14px"
            fontWeight={500}
            // link=""
            onClick={() => props.cancleButtonOperation()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EnteryourPassword;
