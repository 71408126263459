import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


import styles from './AddMore.module.css';


function AddMore(props) {

  const [inputList, setinputList] = useState([{ firstName: '', lastName: '' }]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setinputList(list);

  }

  const handleremove = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setinputList(list);
  }

  const handleaddclick = () => {
    setinputList([...inputList, {  lastName: '' }]);
  }

  return (
    <div className={styles.Container}>

      {props.PrimaryLabelStatus && 
      <div className={styles.FieldPrimary}>
        <StarRateRoundedIcon /> Primary
      </div>
      }
      {
        inputList.map((x, i) => {
          return (
            <>

              <div className={styles.BodyContainer}>
                {props.body}
                {
                  inputList.length !== 1 && 
                  <Button className={styles.RemoveButton} onClick={() => handleremove(i)}><CloseRoundedIcon /></Button>
                }
              </div>


              {inputList.length - 1 === i &&
                <Button className={styles.AddMoreButton} onClick={handleaddclick}><AddRoundedIcon />{props.AddMoreButtonlabel}</Button>
              }

            </>
          );
        })}

    </div>
  );
}

export default AddMore;
