import React, { useEffect, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import Table from "../../../components/Table/AttendanceTable";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  useAddSingleStudentCourseAttendanceMutation,
  useAddSingleStudentMainAttendanceMutation,
  useGetAccademicYearsQuery,
} from "../../../redux/features/parentPortal/students/studentsApi";
import useProtected from "../../../hooks/useProtected";
import { capitalizeWords } from "../../../hooks/HelperFunctions";

const StudentAttendance = () => {
  const { id } = useParams();

  // =========================== apis   ============================ //
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [studentMainAttendance, setStudentMainAttendance] = useState([]);
  const [studentCourseAttendance, setStudentCourseAttendance] = useState([]);
  const [getAttendanceByDateRange, getAttendanceByDateRangeOption] =
    useAddSingleStudentMainAttendanceMutation();
  const [getCourseAttendanceByDateRange, getCourseAttendanceByDateRangeOption] =
    useAddSingleStudentCourseAttendanceMutation();

  useProtected(getAttendanceByDateRangeOption?.error);
  useProtected(getCourseAttendanceByDateRangeOption?.error);
  // =========================== apis   ============================ //

  // this code show the month in this format May 2023
  const [historyShowDate, setHistoryShowDate] = useState(dayjs());
  const date = new Date(historyShowDate.$y, historyShowDate.$M);
  const dateOptions = { year: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", dateOptions);

  // =========================== academic year part start   ============================ //
  const [academicYear, setAcademicYear] = React.useState("");
  const [schoolYearsInfo, setSchoolYearsInfo] = useState({});
  const [schoolYearsOptions, setSchoolYearOptions] = useState([]);
  const [schoolYear, setSchoolYear] = useState("");
  const {
    data: schoolYearData,
    isLoading: schoolYearIsLoading,
    isError: schoolYearIsError,
    error: schoolYearError,
  } = useGetAccademicYearsQuery();
  useProtected(schoolYearError);

  const handleChanges = (event) => {
    setSchoolYear(event.target.value);
    setAcademicYear(event.target.value);
  };
  useEffect(() => {
    if (!schoolYearIsLoading && !schoolYearIsError) {
      setSchoolYearsInfo(schoolYearData?.body?.data);
    }
  }, [schoolYearData, schoolYearIsLoading, schoolYearIsError]);

  useEffect(() => {
    setSchoolYearOptions(prepareOptions(schoolYearsInfo));
    const today = new Date().getFullYear();
    if (schoolYearsInfo?.length > 0) {
      const courrentYear = schoolYearsInfo?.filter((item) => {
        let endYear = new Date(item?.endDate).getFullYear();
        let startYear = new Date(item?.startDate).getFullYear();
        return today === startYear && today === endYear;
      });
      // console.log(courrentYear, 328);
      setSchoolYear(courrentYear[0]?.name);
    }
  }, [schoolYearsInfo]);

  const prepareOptions = (rows) => {
    if (rows?.length > 0) {
      const options = rows.flatMap((single_row) => {
        if (single_row?.terms.length === 0) {
          return {
            label: capitalizeWords(single_row.name),
            value: capitalizeWords(single_row.name),
            // value: single_row._id,
          };
        } else {
          return single_row?.terms?.map((item) => ({
            label: `${capitalizeWords(item.termName)} : ${capitalizeWords(
              single_row.name
            )}`,
            value: capitalizeWords(single_row.name),
            // value: single_row._id,
          }));
        }
      });
      return options;
    }
    return [];
  };

  // =========================== academic year part end   ============================ //

  //========================= attendance part start =======================================//
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [rows, setRows] = useState([]);
  const [studentAttendanceData, setStudentAttendanceData] = useState([]);

  //========================== get start and end day of the week ==========================//
  const getMondayAndSunday = () => {
    const today = new Date(
      historyShowDate.$y,
      historyShowDate.$M,
      historyShowDate.$D
    );
    const currentDayOfWeek = today.getUTCDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday
    const monday = new Date(today);
    monday.setUTCDate(today.getUTCDate() - currentDayOfWeek + 1); // Subtract days to get to Monday
    const nextSunday = new Date(today);
    nextSunday.setUTCDate(today.getUTCDate() - currentDayOfWeek + 7); // Add days to get to next Sunday
    return {
      thisWeekMonday: monday?.toISOString(),
      nextWeekSunday: nextSunday?.toISOString(),
    };
  };

  //========================== get start and end day of the week ==========================//

  //============================ arranged data ========================================//
  const getAttendanceByDateRanges = (studentsAttendenceData) => {
    const data = studentDataArrangeBydate(studentsAttendenceData);
    let arrangedStudents = studentDataArrange(data);
    // const arrangedStudents = studentDataArrangeBydate(studentsAttendenceData);
    setStudentMainAttendance(arrangedStudents);
  };

  const getAttendanceByCourses = (studentsAttendenceData) => {
    const data = studentDataArrangeBydate2(studentsAttendenceData);
    let arrangedStudents = studentDataArrangeBySectionName(data);
    setStudentCourseAttendance(arrangedStudents);
  };

  const studentDataArrangeBydate = (data) => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const start = new Date(thisWeekMonday); // Replace with your desired date
    const end = new Date(nextWeekSunday);
    // Replace with your desired date
    let studentsToShow = [];
    data?.forEach((studentData) => {
      let attendenceDate = new Date(studentData.date);
      let foundStudent = studentsToShow.find((student) => {
        let singleAttendenceDate = new Date(student?.date);
        return (
          singleAttendenceDate.toLocaleDateString("en-US") ===
            attendenceDate.toLocaleDateString("en-US") &&
          student.userUniqueID == studentData.userUniqueID
        );
      });
      if (!foundStudent) studentsToShow.push(studentData);
    });

    setStartDate(start);
    setEndDate(end);

    return studentsToShow;
  };
  const studentDataArrangeBydate2 = (data) => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const start = new Date(thisWeekMonday); // Replace with your desired date
    const end = new Date(nextWeekSunday);
    // Replace with your desired date
    let studentsToShow = [];
    data?.forEach((studentData) => {
      let attendenceDate = new Date(studentData.date);
      // console.log(attendenceDate, 328);
      let foundStudent = studentsToShow.find((student) => {
        let singleAttendenceDate = new Date(student?.date);
        return (
          singleAttendenceDate.toLocaleDateString("en-US") ===
            attendenceDate.toLocaleDateString("en-US") &&
          student.sectionName == studentData.sectionName
        );
      });
      if (!foundStudent) studentsToShow.push(studentData);
    });

    setStartDate(start);
    setEndDate(end);

    return studentsToShow;
  };
  const studentDataArrange = (attendanceData) => {
    const transformedData = attendanceData.reduce((acc, attendance) => {
      const { _id, userUniqueID, name, date, status, sectionName, courseName } =
        attendance;

      if (!acc[userUniqueID]) {
        acc[userUniqueID] = {
          _id,
          userUniqueID,
          name,
          sectionName,
          courseName,
          weeks_present: [],
        };
      }

      acc[userUniqueID]?.weeks_present.push({ date, status });

      return acc;
    }, {});
    const result = Object.values(transformedData);
    return result;
  };
  const studentDataArrangeBySectionName = (attendanceData) => {
    // console.log(attendanceData, 328);
    const transformedData = attendanceData.reduce((acc, attendance) => {
      const { _id, userUniqueID, name, date, status, sectionName, courseName } =
        attendance;

      if (!acc[sectionName]) {
        acc[sectionName] = {
          _id,
          userUniqueID,
          name,
          sectionName,
          courseName,
          weeks_present: [],
        };
      }

      acc[sectionName]?.weeks_present.push({ date, status });

      return acc;
    }, {});
    // console.log(transformedData, 328);
    const result = Object.values(transformedData);
    return result;
  };

  //============================ arranged data ========================================//

  //============================== fetaching data by date ==================================//
  const getAttendanceByDateRangeInitail = async () => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const startFormattedDate = dateFormatted(thisWeekMonday);
    const endFormattedDate = dateFormatted(nextWeekSunday);
    let studentMainAttendenceData = await getAttendanceByDateRange({
      id: id,
      startDate: startFormattedDate,
      endDate: endFormattedDate,
    });
    if (!studentMainAttendenceData?.data?.error) {
      getAttendanceByDateRanges(studentMainAttendenceData?.data?.body?.data);
    }
  };

  const getAttendanceByAcademicYear = async (data) => {
    try {
      let studentCourseAttendence = await getCourseAttendanceByDateRange(data);

      const attendanceData = studentCourseAttendence?.data?.body?.data;

      if (attendanceData) {
        const transformedData = attendanceData.flatMap((course) => {
          const { courseName, sectionName, attendance } = course;

          return attendance.map((record) => ({
            _id: record._id,
            courseName,
            sectionName,
            studentId: record.studentId,
            courseId: record.courseId,
            date: record.date,
            status: record.attendance,
            schoolId: record.schoolId,
            sectionId: record.sectionId,
            createdAt: record.createdAt,
            updatedAt: record.updatedAt,
          }));
        });
        getAttendanceByCourses(transformedData);
        setStudentAttendanceData(transformedData);
      } else {
        console.error("Attendance data structure is not as expected.", 328);
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error, 328);
    }
  };

  useEffect(() => {
    getAttendanceByDateRangeInitail();
  }, [historyShowDate]);

  useEffect(() => {
    const { thisWeekMonday, nextWeekSunday } = getMondayAndSunday();
    const startFormattedDate = dateFormatted(thisWeekMonday);
    const endFormattedDate = dateFormatted(nextWeekSunday);

    getAttendanceByAcademicYear({
      id: id,
      startDate: startFormattedDate,
      endDate: endFormattedDate,
      academicYear: schoolYear,
    });
    // console.log(schoolYear, 328);
  }, [schoolYear, historyShowDate]);

  //============================== fetaching data by date ==================================//

  //===================== date formatted=============================================//
  const dateFormatted = (date) => {
    const originalDate = new Date(date);
    const year = originalDate.getUTCFullYear();
    const month = String(originalDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(originalDate.getUTCDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  //===================== date formatted=============================================//
  useEffect(() => {
    setStudentAttendance([
      ...studentMainAttendance,
      ...studentCourseAttendance,
    ]);
  }, [studentMainAttendance, studentCourseAttendance]);

  useEffect(() => {
    setRows(convertDataToCellRows(studentAttendance));
    // console.log(studentAttendance, 328);
  }, [studentAttendance]);

  const studentAttendanceStatus = (data) => {
    const status = studentAttendanceData?.filter(
      (item) => item?.status === data
    );
    return status?.length > 0 ? status?.length : 0;
  };
  //========================= attendance part end =======================================//

  //========================== table head part functional start ================================//
  let headCellsWeek = [];

  const slideHead = () => {
    let calenderHead = [];
    const thisDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (
      let currentDate = new Date(startDate);
      currentDate <= endDate;
      currentDate.setUTCDate(currentDate.getUTCDate() + 1)
    ) {
      const dayAsInteger = currentDate.getUTCDate();
      const monthAsInteger = currentDate.getUTCMonth();
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayName = daysOfWeek[currentDate.getUTCDay()];
      const thisDateAsInteger = thisDate.getUTCDate();
      const thisMonthAsInteger = thisDate.getUTCMonth();
      const activeDate = historyShowDate.$D;
      // console.log(dayAsInteger, 345, activeDate);

      let weekHeader = {
        Day: dayName.slice(0, 3).toUpperCase(),
        Date: dayAsInteger,
        DateStatus: true,

        DayActicve:
          dayAsInteger == thisDateAsInteger &&
          monthAsInteger == thisMonthAsInteger
            ? "DayActicve"
            : dayAsInteger == activeDate
            ? "DayActicve2"
            : "",
      };
      calenderHead.push(weekHeader);
    }
    headCellsWeek = [
      {
        YearStatus: true,
        Year: `${dayjs(historyShowDate)?.format(
          "MMM"
        )} ${thisDate.getFullYear()} `,
        colSpan: "",
      },
      ...calenderHead,
    ];
    return calenderHead;
  };

  const dateSliderData = [
    {
      Slides: (
        <div className="WeekSldier" style={{ padding: 12 }}>
          {slideHead()}
        </div>
      ),
    },
  ];
  //========================== table head part functional end ================================//

  const headCells = [
    {
      id: "ID",
      numeric: false,
      disablePadding: true,
      label: "ID",
      width: "0%",
      textAlign: "left",
      textWeight: 100,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "30%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
  ];

  //==== WEEK DATE CODE //
  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      // this section shows every students weekly present status
      return arr.map((item) => {
        let cell = [
          // { text: "" },
          {
            // linkText: `item?.courseName? ${item?.courseName}:${item?.sectionName} : "Main attendance"`,
            linkText: item?.courseName
              ? `${capitalizeWords(item.courseName)}
              : ${capitalizeWords(item?.sectionName)}`
              : "Main attendance",
            linkTextStatus: true,
            link: "#",
          },
        ];
        for (
          let currentDate = new Date(startDate);
          currentDate <= endDate;
          currentDate.setUTCDate(currentDate.getUTCDate() + 1)
        ) {
          const daysOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          const dayName = daysOfWeek[currentDate.getUTCDay()];

          const today = new Date();

          if (dayName === "Sunday" || dayName === "Saturday") {
            cell.push({
              textBold: "WKD",
              CellColor: "#E1EAFB",
              TextAlign: "center",
              textcolor: "#0450E1",
            });
          } else {
            let found_day = item?.weeks_present?.find((w) => {
              let a = new Date(w.date).toISOString().split("T")[0];
              let b = currentDate.toISOString().split("T")[0];
              return a === b;
            });
            if (found_day) {
              // console.log(found_day, 328);
              let presentStatus = "";
              let presentBackground = "";
              let presentTextColor = "";

              if (found_day.status === "P") {
                presentStatus = "P";
                presentBackground = "#E2F4E2";
                presentTextColor = "#10A711";
              } else if (found_day.status === "A") {
                presentStatus = "A";
                presentBackground = "#F7E4E4";
                presentTextColor = "#BA1A1A";
              } else if (found_day.status === "L") {
                presentStatus = "L";
                presentBackground = "#FFF3E0";
                presentTextColor = "#10A711";
              } else if (found_day.status === "HOL") {
                presentStatus = "HOL";
                presentBackground = "#E0E3EA";
                presentTextColor = "#00164E";
              }

              cell.push({
                textBold: presentStatus,
                CellColor: presentBackground,
                TextAlign: "center",
                textcolor: presentTextColor,
              });
            } else {
              cell.push({
                textBold: "",
                CellColor: "",
                TextAlign: "center",
                textcolor: "",
              });
            }
          }
        }
        return {
          cell,
        };
      });
    } else {
      return [];
    }
  };

  return (
    <div>
      <h1 style={{ color: "#001233" }}>Attendance</h1>
      <div className="AttendanceStatus">
        <li>
          <div className="AttendanceStatusIcon green">
            <CheckCircleRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Present</p>
            <h2>{studentAttendanceStatus("P")}</h2>
          </div>
        </li>

        <li>
          <div className="AttendanceStatusIcon red">
            <CancelRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Absent</p>
            <h2>{studentAttendanceStatus("A")}</h2>
          </div>
        </li>

        <li>
          <div className="AttendanceStatusIcon yellow">
            <WatchLaterRoundedIcon />
          </div>
          <div className="AttendanceStatusInfo">
            <p>Late</p>
            <h2>{studentAttendanceStatus("L")}</h2>
          </div>
        </li>
      </div>
      {/* ======= divider between both section ======= */}
      <div style={{ width: "5px", height: "5px", padding: "16px 0" }}></div>
      {/* ===================================== attendance table  ===================================== */}
      <div className="TableSection TableAttendanceSection">
        <Table
          title=""
          tableHeader={true}
          addLabel="Add entry"
          AddButton={false}
          DateField={true}
          YearLabel={formattedDate}
          value={historyShowDate}
          handleChange={(date) => setHistoryShowDate(date)}
          headCells={[]}
          rows={rows}
          dateSliderData={dateSliderData}
          //onClick={handleOpenDrawer}
          footer={false}
          //   handleSearchText={handleSearchText}
          //   handleSearch={handleSearch}
          //filter part
          //   filterCountStatus={(streams?.length + userTypes?.length) > 0}
          //   filterCountNumber={(streams?.length + userTypes?.length)}
          FilterButtonDropdown={true}
          filterBtnDropdownLabelStatus={false}
          filterBtnDropdownOptions={schoolYearsOptions}
          filterBtnSelectedOption={schoolYear}
          filterBtnHandleChanges={handleChanges}
          //   onClickFilterButton={handleFilterDrawerOpen}
          goToPreviousDate={(e) =>
            setHistoryShowDate(historyShowDate.subtract(1, "day"))
          }
          goToNextDate={(e) =>
            setHistoryShowDate(historyShowDate.add(1, "day"))
          }
          ExportButton={false}
          //   onClickExport={handleOpenAttendanceExport}
          RowsPerPage={15}
          headCellsWeek={headCellsWeek}
        />
      </div>
    </div>
  );
};

export default StudentAttendance;
