import React, { useState } from "react";

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '../Tooltip/Tooltip';
import './PriceTextField.css';




{/* <PriceTextField
  label="Compare-at price"
  TooltipStatus={true}
  TooltipText="Compare-at price"
  cout={4}
/> */}

// const [values, setValues] = React.useState({
//   amount: '',
// });
// const handleChange = (prop) => (event) => {
//   setValues({ ...values, [prop]: event.target.value });
// };







function PriceTextField(props) {

  const { label, required, number, handleChange, values, error } = props;

  const handleOnInput = (e) => {
    let maxNum = props.cout;
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum);
    }
  };

  return (
    <div className="PriceTextField">
      <div className="PriceTextFieldLabel">{label} {required && <span>*</span>}
        {props.TooltipStatus &&
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        }
      </div>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-adornment-amount"
          type="number"
          value={values}
          onChange={handleChange}
          price startAdornment={<InputAdornment position="start">$</InputAdornment>}
          error={error}
          className="no-spinners"
          onInput={handleOnInput} 
        />
      </FormControl>
    </div>
  );
}


export default PriceTextField;

