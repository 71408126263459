import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

import {
  useLazyGetStripeConfigQuery,
  useGetStripePaymentSessionMutation,
} from "./stripeApi";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  // const { data } = useLazyGetStripeConfigQuery();
  // console.log( data.body.data)
  const [processStripeConfig] = useLazyGetStripeConfigQuery();
  const [createStripePaymentIntent] = useGetStripePaymentSessionMutation();

  //async function to get stripe config

  useEffect(() => {
    const fetchStripeConfig = async () => {
      const data = await processStripeConfig();
      // console.log(data.data.body.data.STRIPE_PUBLISHABLE_KEY)
      setStripePromise(loadStripe(data.data.body.data.STRIPE_PUBLISHABLE_KEY));
    };
    fetchStripeConfig();
  }, []);

  useEffect(() => {
    const fetchStripePaymentIntent = async () => {
      const paymentData = {
        amount: 199,
        currency: "USD",
        applicationId: "oiwuer9839823ie",
      };
      const data = await createStripePaymentIntent(paymentData);
      // console.log(data.data.body.data?.clientSecret)
      setClientSecret(data.data.body.data?.clientSecret);
    };
    fetchStripePaymentIntent();
  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
}

export default Payment;
