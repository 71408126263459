import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';

import TextField from '../../../components/Form/TextField/TextField';
import Datefield from '../../../components/Form/Datefield/Datefield';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';

import { getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';

import CheckBox from '../../../components/Form/CheckBox/CheckBox';


const SchoolRecords = (props) => {


  const { id } = useParams();

  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
    setBirthDate,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear, errors } = props;

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    // console.log(dates, 893547685)

    // console.log(54645, birthMonth, birthYear, dates)
    setDateOptions(dates)
  }, [birthMonth, birthYear]);



  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  return (

    <div className={styles.ApplicationForm}>

      <Grid container spacing={2}>

        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.FieldLabel}>Full name*</div>
          <div className={styles.FormGroup}>
            <TextField
              placeholder="First name"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />
            <TextField
              placeholder="Middle name"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />
            <TextField
              placeholder="Last name"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />

          </div>

        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.FieldLabel}>Contact info</div>
          <div className={styles.FormGroup}>
            <TextField
              placeholder="Email address"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />

            <div className={styles.PhoneField} >
              <PhoneInputField
                phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                country={'us'}
                value={phone.number}
                countryCodeEditable={false}
                onChange={(phone, country) => console.log("onChange", phone, country)}
                onBlur={() => console.log("onBlur")}
                onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                variant="outlined"
                // onlyCountries={["us", "ca", "gb", "my"]}
                containerStyle={{}}
                buttonStyle={{}}
                inputStyle={{
                  marginLeft: "0px",
                  height: "48px",
                  width: "100%",
                  fontFamily: "Roboto-Regular",
                  fontSize: "16px",
                }}
                containerClass={{}}
                errorText={"Phone number is required"}
                errorStyles={{ marginBottom: "5px" }}
                autoFormat={true}
                disableDropdown={false}
                disabled={false}
                disableCountryCode={false}
                enableSearch={false}
                disableSearchIcon={false}
                placeholder={""}
                inputProps={{}}
                specialLabel={'Primary Phone'}
              // preferredCountries={[]}
              // excludeCountries={[]}
              //labelFixed="Field label"
              // LabelStatus={true}
              //helpertext="Helper text"
              //error="error"
              />
            </div>

          </div>

        </Grid>


        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.FormSingleField}>
            <TextField
              LabelStatus={true}
              Fieldlabel="Resident address*"
              placeholder="Full address"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />
          </div>
          <div className={styles.LinkText}><a href='#'>Enter address manually</a></div>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <CheckBox
            label="I authorize [School Name] to request all documents and records relative to my child"
            checked={state.checkedB}
            onChange={handleChangeCheck}
            color="primary"
          />
        </Grid>


        <Grid item xs={12} sm={6} md={6}>


        </Grid>


        <Grid item xs={12} sm={6} md={6}>
          <div className={styles.FieldLabel}>Parent/Guardian Signature*</div>
          <div className={styles.FormGroup}>
            <TextField
              placeholder="Enter your name"
            // value={courseData.Id}
            // handleChange={handleChanges("Id")}
            />

            <div className={styles.PhoneField} >
              <Datefield

              />
            </div>

          </div>

        </Grid>

      </Grid>
    </div>
   
  );
};

export default SchoolRecords;
