import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import './SelectCardTab.css';


//  const [value, setValue] = React.useState(0);

// const onChange = (event, newValue) => {
//   setValue(newValue);
// };


// const tabsData = [
//   {
//     title: "Individual",
//     description: "Customer is an individual",
//   },

//   {
//     title: "Organization",
//     description: "Customer is an organization",
//   },
// ]

// const panelDate = [
//   {
//     id: 0,
//     panel: <Individual />,
//   },

//   {
//     id: 1,
//     panel: <p>scsdsfvjsdvlds</p>,
//   },

// ]

{/* <SelectCardTab
  tabsData={tabsData}
  panelDate={panelDate}
  value={value}
  onChange={onChange}

/> */}




function SelectCardTab(props) {
  const { tabsData, panelDate, onChange, value } = props;

  return (
    <div className={props.FullWidth}>
      <div className="SelectCardTab">
        <Tabs value={value} onChange={onChange}>
          {tabsData.map((item, i) => (
            <Tab disabled={item.disabled} label={
              <div className="SelectCardTabLebel"><h3>{item.title}</h3> <div className="TabCheckIcon"><CheckCircleIcon /></div></div>
            }
              {...a11yProps(0)}


            />
          ))}
        </Tabs>
        {panelDate &&
          <div className="SelectCardTabBody">
            {panelDate.map((item, i) => (
              <TabPanel value={value} index={item.id}>
                {item.panel}
              </TabPanel>
            ))}
          </div>}


      </div>
    </div>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default SelectCardTab;

