import React from "react";
import "./StatusStatic.css";
import Button from "@mui/material/Button";

{
  /* <StatusStatic
    StatusStaticbackground=""
    StatusLabel="Course Code"
    StatusSubLabel="Subject Area"
    StatusColor="#7D8597"
    StatusLine={false}
/> */
}

const StatusStatic = (props) => {
  return (
    <div className="StatusStaticContainer">
      <div
        className="StatusStaticBackground"
        style={{ background: props.StatusColor }}
      ></div>
      {props.StatusLine && (
        <div
          className="StatusStaticLine"
          style={{ background: props.StatusColor }}
        ></div>
      )}
      <div>
        <h1 style={{ color: props.StatusColor }}>{props.StatusLabel}</h1>
        <h2>{props.StatusSubLabel}</h2>
      </div>
    </div>
  );
};

export default StatusStatic;
