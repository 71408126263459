import React, { useEffect, useState } from "react";
import personeAvatar from "../../../assets/imgs/randomPerson.avif";
import "../../../assets/css/students.css";
import { Avatar, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetStudentsListQuery } from "../../../redux/features/parentPortal/students/studentsApi";
import { useSelector } from "react-redux";
import { getProfileAvatarFromName } from "../../../utils/helpers";
import { deepPurple } from "@mui/material/colors";
import { capitalizeWords } from "../../../hooks/HelperFunctions";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from "../../../components/Form/Button/Button";
import { useGetSchoolQuery } from "../../../redux/features/schools/schoolApi";

const StudentsListing = () => {
  const user = useSelector((state) => state.auth.user);

  const [students, setStudents] = useState([]);
  const [schoolData, setSchoolData] = useState({});
  const { data, isLoading, isError } = useGetStudentsListQuery(user?._id, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: schoolInfoData,
    isLoading: schoolIsLoading,
    isError: schoolIsError,
  } = useGetSchoolQuery(user?.schoolId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data && !isError && !isLoading) {
      setStudents(data?.body?.data);
    }
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (schoolInfoData && !schoolIsError && !schoolIsLoading) {
      setSchoolData(schoolInfoData?.body?.data);
    }
  }, [schoolInfoData, schoolIsLoading, schoolIsError]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginLeft: "18px", fontSize: "28px", fontWeight: 700 }}>
          Students
        </h1>
        {/* <Button
          label="Add New Application"
          icon={<AddRoundedIcon />}
          buttonColor="#0450E1"
          border="#0450E1 1px solid"
          borderRadius="8px"
          color="#fff"
          width=""
          height="48px"
          fontSize="14px"
          link="/new-application"
        /> */}
      </div>

      <Grid container>
        {/* ------------ card one --------------- */}
        {students?.map((item) => (
          <Grid item lg={4} md={6} sm={12}>
            <Link
              to={`/students-details/${item?._id}`}
              className="studentCardLink"
            >
              <div className="studentCardContainer">
                <Avatar
                  alt={item?.firstName}
                  src={
                    item?.idPicture || item?.profilePic
                      ? item?.idPicture || item?.profilePic
                      : getProfileAvatarFromName(item?.firstName)
                  }
                ></Avatar>
                <div>
                  <div className="flexedCentered StudentListingCardContainer">
                    <h2>
                      {/* {item?.firstName} {item?.lastName} */}
                      {`${
                        (item?.firstName + " " + item?.lastName).length > 20
                          ? (item?.firstName + " " + item?.lastName).slice(
                              0,
                              26
                            ) + "..."
                          : item?.firstName + " " + item?.lastName
                      }`}
                    </h2>

                    <span
                      className="status rounded statussmall"
                      style={{
                        // marginLeft: item.space,
                        background:
                          item.status === "inactive" ? "#FCE7E9" : "#E1F2E8",
                        color:
                          item.status === "inactive" ? "#E63946" : "#0B9444",
                        // display: item.align,
                        // width: item.StatusWidth,
                      }}
                    >
                      <div
                        className="StatusLine"
                        style={{
                          background:
                            item.status === "inactive" ? "#BA1A1A" : "#41AB37",
                        }}
                      ></div>
                      <span style={{ color: "black", fontWeight: "400" }}>
                        {capitalizeWords(item?.status)}
                      </span>
                    </span>
                    {/* <span className="">
                      <FiberManualRecordIcon fontSize="small" />
                    </span> */}
                  </div>
                  <p>{schoolData?.dba}</p>
                </div>
              </div>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default StudentsListing;
