import React, { useState } from "react";

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Tooltip from '../Tooltip/Tooltip';
import './FixedValueTextField.css';




{/* <PriceTextField
  label="Compare-at price"
  TooltipStatus={true}
  TooltipText="Compare-at price"
  cout={4}
/> */}

// const [values, setValues] = React.useState({
//   amount: '',
// });
// const handleChange = (prop) => (event) => {
//   setValues({ ...values, [prop]: event.target.value });
// };







function FixedValueTextField(props) {

  const { label, required, number, handleOnChange, value, error } = props;

  const handleOnInput = (e) => {
    let maxNum = props.cout;
    if (e.target.value.length > maxNum) {
      e.target.value = e.target.value.slice(0, maxNum);
    }
  };

  return (
    <div className="FixedValueTextField">
      <div className="FixedValueTextFieldLabel">{label} {required && <span>*</span>}
        {props.TooltipStatus &&
          <Tooltip
            TooltipText={props.TooltipText}
            Tooltip={<InfoRoundedIcon />}
          />
        }
      </div>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-adornment-amount"
          type="text"
          value={value}
          onChange={handleOnChange}
          price startAdornment={<InputAdornment position="start">{props.FixedValueLabel}</InputAdornment>}
          error={error}
          className="no-spinners"
          onInput={handleOnInput} 
        />
        {props?.helperText && <p style={{color: "red"}}>{props?.helperText}</p>}
      </FormControl>
    </div>
  );
}


export default FixedValueTextField;

