import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import moment from "moment-timezone";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import styles from "./css/Payments.module.css";
import Payment from "./stripe/payment";
import Button from "@mui/material/Button";
// import CouponLabel from "../../components/Coupon/CouponLabel";
import { toast } from "react-toastify";
import { capitalizeWords, truncateText } from "../../hooks/HelperFunctions";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {
  useGetAcademicYearsQuery,
  useLazyGetAcademicYearsQuery,
} from "../../redux/features/parentPortal/academicYear/academicYearApi";
import { useSelector } from "react-redux";
import CouponLabel from "../../components/Coupon/CouponLabel/CouponLabel";
import Modals from "../../components/CouponModals/Modals";
import TextField from "../../components/Form/TextField/TextField";
import SyncIcon from "@mui/icons-material/Sync";
import {
  useApplyCouponMutation,
  useGeneratePaymentIntentMutation,
  useGetCouponByCodeMutation,
} from "../../redux/features/parentPortal/payments/paymentsApi";
import useProtected from "../../hooks/useProtected";
import { loadStripe } from "@stripe/stripe-js";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress, Fade } from "@mui/material";
import { useLazyGetStripeConfigQuery } from "./stripe/stripeApi";
import Tooltip from "../../components/Form/Tooltip/Tooltip";
import QueryBuilderRoundedIcon from "@mui/icons-material/QueryBuilderRounded";

const Payments = ({ payments, setFoundRecurring, setupIntentData }) => {
  //=== STRIPE PAYMENT ===//
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [openPayment, setOpenPayment] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [promotionalCoupons, setPromotionalCoupons] = useState([]);
  const [promotionalCouponText, setPromotionalCouponText] = useState("");
  const [promotionalCouponError, setPromotionalCouponError] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [couponErrorStatus, setCouponErrorStatus] = useState(false);
  const [couponApplyDetails, setCouponApplyDetails] = useState({});
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [asc, setAsc] = useState(true);
  const [processStripeConfig] = useLazyGetStripeConfigQuery();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isError, isLoading } = useGetAcademicYearsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [coupunData, coupunDataOptions] = useGetCouponByCodeMutation();
  const [applCoupon, applCouponOptions] = useApplyCouponMutation();
  const [generatePaymentIntent, generatePaymentIntentOptions] =
    useGeneratePaymentIntentMutation();
  useProtected(coupunDataOptions?.error);
  useProtected(applCouponOptions?.error);
  useEffect(() => {
    if (data?.body?.data?.length > 0 && !isError && !isLoading) {
      setAcademicYears(data?.body?.data);
    }
  }, [data, isError, isLoading]);
  const userData = useSelector((state) => state.auth.user);
  const [rows, setRows] = useState([]);
  const onClick = () => {
    alert("onclick working fine!");
  };
  useEffect(() => {
    if (payments?.length > 0) {
      setRows(convertDataToCellRows(payments));
    }
  }, [payments]);

  ///=================================== sorting part start =============//
  const ascendingOrDescendingSorting = (field) => {
    let sortedPayments = [...payments];
    if (asc) {
      sortedPayments.sort((a, b) => a[field]?.localeCompare(b[field]));
    } else {
      sortedPayments.sort((a, b) => b[field]?.localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedPayments));
    setAsc(!asc);
  };
  ///=================================== sorting part start =============//
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Amount",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "status",
      width: "5%",
      textAlign: "left",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSorting("status"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "20%",
      textAlign: "left",
    },
    {
      id: "cycle",
      numeric: false,
      disablePadding: true,
      label: "Cycle",
      width: "10%",
      textAlign: "left",
      sort: "sort",
      headerOnClick: () => ascendingOrDescendingSorting("cycle"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Date",
      width: "15%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Academic year",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Student",
      width: "20%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    // {
    //   id: "name",
    //   numeric: false,
    //   disablePadding: true,
    //   label: "",
    //   width: "10%",
    //   textAlign: "left",
    // },
  ];

  const statustData = [
    {
      value: "success",
      statuscolor: "#EDF7EA",
      select: "Paid",
      textColor: "#66BD50",
    },
    {
      value: "active",
      statuscolor: "#EDF7EA",
      select: "Active",
      textColor: "#66BD50",
    },
    {
      value: "due",
      statuscolor: "#EDEDEF",
      select: "Due",
      textColor: "#676C7B",
    },
    {
      value: "pending",
      statuscolor: "#EDEDEF",
      select: "Pending",
      textColor: "#676C7B",
    },
    {
      value: "cancelled",
      statuscolor: "#FCE7E9",
      select: "Cancelled",
      textColor: "#E63946",
    },
    {
      value: "failed",
      statuscolor: "#FCE7E9",
      select: "Failed",
      textColor: "#E63946",
    },
  ];

  const handleOpenPaymentDrawer = async (payment) => {
    let locSubTotal = parseFloat(payment?.amount)?.toFixed(2);
    setSubTotal(locSubTotal);
    setPaymentInfo({
      amount: payment?.amount,
      name: payment?.name,
      discount: totalDiscount ? totalDiscount : 0,
      currency: payment?.currency,
      paymentId: payment?._id,
      student: payment?.student,
      allowableCouponCodes: payment?.allowableCouponCodes,
      autoApplyCouponCodes: payment?.autoApplyCouponCodes,
      appliedCouponCodes: payment?.appliedCouponCodes,
    });

    handleDrawerOpenPayment();
  };
  const handleDrawerOpenPayment = () => {
    setOpenPayment(true);
  };

  useEffect(() => {
    if (openPayment) {
      if (paymentInfo && Object.keys(paymentInfo).length) {
        fetchStripePaymentIntent(paymentInfo);
      }
    }
  }, [openPayment]);

  const fetchStripePaymentIntent = async (paymentInfo) => {
    let result = await generatePaymentIntent(paymentInfo);
    if (result?.data && !result?.data?.error && result?.data?.body?.data) {
      setClientSecret(result?.data?.body?.data);
    } else {
      toast?.error(result?.data?.message);
    }
  };
  const handleDrawerClosePayment = () => {
    setOpenPayment(false);
    setTotal(0);
    setTotalDiscount(0);
    setPromotionalCoupons([]);
  };
  const currentDate = new Date();
  const isCurrent = (data) => {
    const startDate = new Date(data?.startDate);
    const endDate = new Date(data?.endDate);

    return (
      currentDate >= startDate &&
      currentDate < new Date(endDate.setDate(endDate.getDate() + 1))
    );
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      let foundRecurringNumber = 0;
      return arr.map((item, index) => {
        if (item?.cycle == "recurring") {
          foundRecurringNumber++;
        }
        if (arr.length == index + 1) {
          setFoundRecurring(foundRecurringNumber > 0);
        }
        const foundYear = academicYears?.find(
          (year) => year?._id === item?.academicYear?.id
        );
        const isYearCurrent = isCurrent(foundYear);

        return {
          cell: [
            {
              text: (
                <span style={{ padding: "7px 0", display: "block" }}>
                  ${item?.amount || "N/A"}
                </span>
              ),
            },
            {
              Status: true,
              statusLine: true,
              linebackground: statustData?.find(
                (status) => status.value === item?.status
              )?.textColor,
              background: statustData?.find(
                (status) => status.value === item?.status
              )?.statuscolor,
              color: statustData?.find(
                (status) => status.value === item?.status
              )?.textColor,
              label: statustData?.find(
                (status) => status.value === item?.status
              )?.select,
            },
            { text: item?.name || "N/A" },
            {
              text:
                item?.cycle === "onetime"
                  ? "One-time"
                  : (
                      <span className="paymentDateContainer">
                        <SyncIcon fontSize="small" color="#7D8597" />
                        {capitalizeWords(item?.billingPeriod)}
                      </span>
                    ) || "N/A",
            },
            {
              text: item?.paymentDate ? (
                moment(item?.paymentDate)?.format("DD-MMM-YYYY")
              ) : item?.cycle === "onetime" && item?.dueDate ? (
                <span className="paymentDateContainer">
                  {item?.paymentDate
                    ? moment(item?.paymentDate)?.format("DD-MMM-YYYY")
                    : moment(item?.createdAt)?.format("DD-MMM-YYYY")}
                  <Tooltip
                    TooltipText={
                      <span>
                        Upcoming payment scheduled:{" "}
                        <b>{moment(item?.dueDate)?.format("DD-MMM-YYYY")}</b>
                      </span>
                    }
                    Tooltip={<QueryBuilderRoundedIcon fontSize="small" />}
                  />
                </span>
              ) : (
                "N/A"
              ),
            },
            {
              text: foundYear?.name || item?.academicYear?.name || "N/A",
              chip: isYearCurrent ? "Current" : "",
              chipStatus: isYearCurrent ? true : false,
            },
            {
              linkText: truncateText(
                `${item?.student?.firstName} ${item?.student?.lastName}`,
                25
              ),
              linkTextStatus: true,
              link: "#",
            },
            {
              Button:
                item?.status === "success" || item?.cycle == "recurring"
                  ? false
                  : true,
              buttonLabel: "Pay now",
              buttonColor: "#0450E1",
              buttonBorder: "#0450E1 1px solid",
              buttonTextcolor: "#fff",

              onClick: (e) =>
                item?.status === "success" || item?.cycle == "recurring"
                  ? console.log("No Drawer")
                  : handleOpenPaymentDrawer(item),
            },
            // {
            //   text: item?.status === "success" && (
            //     <span style={{ padding: "18px 16px" }}>.</span>
            //   ),
            // },
            // {
            //   ActionButtonDotIcon: false,
            //   ActionButtonLabel: "Action",
            //   TextAlign: "right",
            // },
          ],

          //======= Action Date Start===== //
          // action: [
          //   { label: "View", onClick: onClick },
          //   { label: "Delete", onClick: onClick },
          // ],
          //======= Action Date End===== //
        };
      });
    } else {
      return [];
    }
  };

  const calculateDisscount = (items) => {
    // console.log(items, 348);
    let amount = subTotal;
    // const total = items?.reduce(
    //   (acc, currentValue) => acc + Number(currentValue?.discountValue),
    //   0
    // );
    // const dissCountAmount = parseFloat(amount) * (total / 100);
    const dissCountAmount = items?.reduce((acc, currentItem) => {
      if (currentItem?.discountType === "percentage") {
        const percentageDiscount =
          parseFloat(amount) * (Number(currentItem?.discountValue) / 100);
        return acc + percentageDiscount;
      } else if (currentItem?.discountType === "fixed") {
        return acc + Number(currentItem?.discountValue);
      } else {
        return acc;
      }
    }, 0);

    return dissCountAmount;
  };

  useEffect(() => {
    if (paymentInfo && promotionalCoupons) {
      const discountAutoCoupons = calculateDisscount(
        paymentInfo?.autoApplyCouponCodes
      );
      const discountPromoCoupons = calculateDisscount(promotionalCoupons);
      const totalDiscountData = discountAutoCoupons + discountPromoCoupons;
      const locTotal = parseFloat(subTotal - totalDiscountData).toFixed(2);
      setTotal(locTotal);
      setTotalDiscount(totalDiscountData);
    }
  }, [promotionalCoupons, paymentInfo]);

  //================================= promotional code ========================================//

  //** ============================ checking errors ================================== **//

  useEffect(() => {
    if (couponErrorStatus) {
      let latestData = promotionalCoupons?.filter(
        (item) => item?._id !== couponApplyDetails?._id
      );
      setPromotionalCoupons(latestData);
    }
  }, [couponErrorStatus, couponApplyDetails]);
  const checkErrorCoupon = async () => {
    let exitedCoupon = promotionalCoupons?.find(
      (item) => item?.code === promotionalCouponText
    );
    // let allowableCouponCodes = paymentInfo?.allowableCouponCodes?.find(
    //   (item) => item === promotionalCouponText
    // );
    let exitedautoApplyCouponCode = paymentInfo?.autoApplyCouponCodes?.find(
      (item) => item?.code === promotionalCouponText
    );

    let error = 0;
    if (!promotionalCouponText) {
      error++;
      setPromotionalCouponError("Enter a valid coupon code.");
    } else if (exitedautoApplyCouponCode || exitedCoupon) {
      error++;
      setPromotionalCouponError(
        "Coupon code already exists. Please try a different one."
      );
    } else {
      setPromotionalCouponError("");
    }
    return error > 0 ? false : true;
  };

  useEffect(() => {
    if (errorStatus) {
      checkErrorCoupon();
    }
  }, [errorStatus, promotionalCouponText]);
  //** ============================ checking errors ================================== **//

  //** ============================ apply coupon code ================================== **//
  const onChangeHandlerPromotionalCouponInfo = (event, property) => {
    setPromotionalCouponText(event?.toUpperCase());
  };

  const createPromotionalCoupon = async () => {
    setErrorStatus(true);
    const isValid = await checkErrorCoupon();
    if (!isValid) {
      return false;
    }
    let data = {
      code: promotionalCouponText,
    };

    const result = await coupunData(data);
    const currentTime = moment();
    const limitTime = moment(
      result?.data?.body?.data?.redemptionLimit?.limitDateRangeData?.time
    );
    if (result?.data?.error) {
      // setPromotionalCouponError("Enter a valid coupon code");
      setPromotionalCouponError("Invalid coupon");
      return false;
    } else if (
      currentTime.isAfter(limitTime) &&
      result?.data?.body?.data?.redemptionLimit?.limitDateRange
    ) {
      setPromotionalCouponError("Coupon has expired");
      return false;
    } else {
      let datas = [...promotionalCoupons];
      datas.push(result?.data?.body?.data);
      setCouponApplyDetails(result?.data?.body?.data);
      setPromotionalCoupons(datas);
      setCouponErrorStatus(false);
      setOpen(true);
      await applyCouponCodes(datas);
      handleClosePromotionalCoupon();
    }
  };
  const applyCouponCodes = async (promotionalCouponsData) => {
    const discountAutoCoupons = calculateDisscount(
      paymentInfo?.autoApplyCouponCodes
    );
    const discountPromoCoupons = calculateDisscount(promotionalCouponsData);
    const totalDiscountData = discountAutoCoupons + discountPromoCoupons;
    const locTotal = parseFloat(subTotal - totalDiscountData).toFixed(2);

    const applyCouponData = {
      couponCodes: promotionalCouponsData?.map((codeData) => codeData?.code),
      paymentId: paymentInfo?.paymentId,
    };
    const result = await applCoupon(applyCouponData);

    if (!result?.data?.error && result?.data?.body?.data) {
      setClientSecret(result?.data?.body?.data);
      // setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY));
      setPaymentInfo((prevPaymentInfo) => ({
        ...prevPaymentInfo,
        amount: Number(locTotal),
        discount: Number(totalDiscountData),
        appliedCouponCodes: promotionalCouponsData,
      }));

      setTotal(locTotal);
      setTotalDiscount(totalDiscountData);
    } else {
      setCouponErrorStatus(true);
      toast.error(result?.data?.message);
    }
    setOpen(false);
  };

  const handleClosePromotionalCoupon = () => {
    setErrorStatus(false);
    setCouponModal(false);
    setPromotionalCouponError("");
    setPromotionalCouponText("");
  };
  //** ============================ apply coupon code ================================== **//

  //** ============================ remove coupon code ================================== **//
  const removePromotionalCoupon = async (id) => {
    let latestData = promotionalCoupons?.filter((item) => item?._id !== id);
    await applyCouponCodes(latestData);
    setPromotionalCoupons(latestData);
  };
  //** ============================ remove coupon code ================================== **//

  //** ============================ showing coupon code ================================== **//
  const showCouponLabel = (coupons) => {
    return (
      coupons?.length > 0 &&
      coupons?.map((coupon) => (
        <CouponLabel
          couponTitle={coupon?.code}
          couponPercentageValue={coupon?.discountValue}
          calculatedValue={
            coupon.discountType === "fixed"
              ? coupon?.discountValue
              : ((subTotal * Number(coupon?.discountValue)) / 100)?.toFixed(2)
          }
          showingPercentang={coupon.discountType !== "fixed" ? true : false}
          couponClose={
            paymentInfo?.autoApplyCouponCodes?.length > 0 ? false : true
          }
          couponCloseFn={(e) => removePromotionalCoupon(coupon?._id)}
        />
      ))
    );
  };
  //** ============================ showing coupon code ================================== **//

  //================================= promotional code ========================================//

  // useEffect(() => {
  //   console.log(paymentInfo, "hii", 348);
  // }, [paymentInfo]);

  useEffect(() => {
    console.log("clientSecret", clientSecret);
  }, [clientSecret]);

  return (
    <div>
      <Table headCells={headCells} rows={rows} footer={true} RowsPerPage={10} />

      <SideDrawer
        title="Checkout"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openPayment}
        handleDrawerClose={handleDrawerClosePayment}
        FooterWithoutTab={false}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={true}
        SubDrawerWithOutTab={true}
        SubDrawerWithOutTabBody={
          <div className={styles.OderSummary}>
            <h1>Order Summary</h1>

            <div className={styles.OderSummaryListingOuter}>
              <div className={styles.OderSummaryListing}>
                <li>{paymentInfo?.name || "N/A"}</li>
                <li>
                  {paymentInfo?.amount
                    ? `$${parseFloat(subTotal)?.toFixed(2)}`
                    : "0.00"}
                </li>
              </div>
            </div>

            <div className={styles.OderSummaryListingOuter}>
              <div className={styles.OderSummaryListing}>
                <li>Subtotal</li>
                <li>
                  {paymentInfo && paymentInfo?.amount ? `$${subTotal}` : "0.00"}
                </li>
              </div>
              {paymentInfo?.autoApplyCouponCodes?.length > 0
                ? showCouponLabel(paymentInfo?.autoApplyCouponCodes)
                : showCouponLabel(promotionalCoupons)}
              <Button
                className={styles.OderSummaryPromocode}
                onClick={() => setCouponModal(!couponModal)}
              >
                Add promotion code
              </Button>
            </div>

            <div className={styles.OderSummaryTotal}>
              <li>Total</li>
              <li>
                {paymentInfo && paymentInfo?.amount ? `$${total}` : "0.00"}
              </li>
            </div>
          </div>
        }
        body={
          <>
            {clientSecret && (
              <Payment
                paymentInfo={paymentInfo}
                setPaymentInfo={setPaymentInfo}
                handleDrawerClosePayment={handleDrawerClosePayment}
                stripePromise={stripePromise}
                setStripePromise={setStripePromise}
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                paymentSuccessful={paymentSuccessful}
                setPaymentSuccessful={setPaymentSuccessful}
              />
            )}
          </>
        }
      />

      <SideDrawer
        title="Confirmation"
        headerTextColor="#fff"
        backgroundColor="#5423FF"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={paymentSuccessful}
        handleDrawerClose={() => {
          setPaymentSuccessful(false);
          handleDrawerClosePayment();
        }}
        FooterWithoutTab={false}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={false}
        SubDrawerWithOutTab={false}
        body={
          <>
            <div className="successfulPaymentDrawerBody">
              <div className="tickCircleBorder">
                <DoneRoundedIcon sx={{ color: "#41ab37" }} fontSize="large" />
              </div>
              <div className="successfulTextContainer">
                <h4>Payment Complete</h4>
                <p>
                  Thank you, your payment has been successful. A confirmation
                  email has been sent to <b>{userData?.email || "N/A"}</b>
                </p>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0450E1",
                    fontWeight: "700",
                    borderRadius: "8px",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: "#0450E1",
                    },
                  }}
                  onClick={() => {
                    setPaymentSuccessful(false);
                    handleDrawerClosePayment();
                  }}
                >
                  DONE
                </Button>
              </div>
            </div>
          </>
        }
      />

      {/* ==== coupon modal ===== */}
      <div className={styles.couponCodeModal}>
        <Modals
          openDialog={couponModal}
          handleCloseDialog={handleClosePromotionalCoupon}
          title=""
          ModalFooter={false}
          body={
            <div className={styles.CouponModalContainer}>
              <h4>Enter coupon code</h4>
              <TextField
                value={promotionalCouponText}
                handleChange={(val, e) =>
                  onChangeHandlerPromotionalCouponInfo(val, "code")
                }
                placeholder="Enter code here"
                error={promotionalCouponError}
                helperText={promotionalCouponError}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={createPromotionalCoupon}
                sx={{
                  padding: "12px 0 !important",
                  marginTop: "12px !important",
                  fontWeight: "600 !important",
                  borderRadius: "999px",
                  background: "#FB8500 !important",
                  "&:hover": {
                    background: "#FB8500 !important",
                  },
                }}
              >
                Apply
              </Button>
            </div>
          }
        />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Payments;
