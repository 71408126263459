import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import TextField from '../../../components/Form/TextField/TextField';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';




const EditFamilyMembers = (props) => {

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (

    <>




      <div className={styles.ApplicationForm}>

        <Grid container spacing={1}>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Who does the child live with?*</div>
            <CheckBox
              label="Father"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Mother"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Stepfather"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Stepmother"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Other"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormGroup} style={{marginLeft:"30px"}}>
              <TextField
                placeholder="Name"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
              <TextField
                placeholder="Relationship"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>

          </Grid>


        </Grid>

        <div style={{marginTop:"20px"}}>
          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.FieldLabel}>Are parents divorced?</div>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="female" control={<Radio />} label="Yes" />
                  <FormControlLabel value="male" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>

      </div>



    </>
  );
};

export default EditFamilyMembers;
