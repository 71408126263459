import React, { useEffect, useState } from "react";
import Table from "../../../components/Table/Table";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import EmergencyContactDetails from "./EmergencyContactDetails";
import { capitalizeWords, truncateText } from "../../../hooks/HelperFunctions";

const EmergencyContact = ({ studentDetails }) => {
  const [parentsDetails, setParentDetails] = useState(false);
  const [parnetsDatas, setParnetsDatas] = useState([]);
  const [rows, setRows] = useState([]);
  const [showId, setshowId] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (studentDetails?.emergencyContacts) {
      setRows(convertCellToRowData(studentDetails?.emergencyContacts));
      setParnetsDatas(studentDetails?.gurdians);
    }
  }, [studentDetails]);

  const handleshowGardianDetails = (item) => {
    setUserDetails(item);
    setParentDetails(true);
  };
  //===================== show data to the table =====================================//
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      width: "30%",
      textAlign: "left",
    },
    // {
    //   id: "calories",
    //   numeric: true,
    //   disablePadding: false,
    //   label: "ID",
    //   width: "20%",
    //   textAlign: "left",
    // },
    {
      id: "fat",
      numeric: true,
      disablePadding: false,
      label: "Preferred contact",
      width: "25%",
      textAlign: "left",
    },
    {
      id: "carbs",
      numeric: true,
      disablePadding: false,
      label: "Relationship",
      width: "25%",
    },
    {
      id: "Details",
      numeric: true,
      disablePadding: false,
      label: "status",
      width: "15%",
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "",
      width: "5%",
    },
  ];
  const renderStatusColor = (status) => {
    switch (status) {
      case "inactive":
        return {
          background: "#FCE7E9",
          color: "#E63946",
          linebackground: "#BA1A1A",
        };
      case "active":
        return {
          background: "#E1F2E8",
          color: "#0B9444",
          linebackground: "#41AB37",
        };
      default:
        return { background: "", color: "", linebackground: "" };
    }
  };

  const convertCellToRowData = (arr) => {
    if (arr?.length > 0) {
      return arr?.map((item) => ({
        cell: [
          {
            text: item?.firstName
              ? capitalizeWords(
                  truncateText(`${item?.firstName} ${item?.lastName}`, 30)
                )
              : "No Data",
          },
          { text: item?.preferredContact ? item?.preferredContact : "No Data" },
          {
            text: item?.relationship
              ? capitalizeWords(item?.relationship)
              : "No Data",
          },
          {
            Status: true,
            background: renderStatusColor(item?.status).background,
            color: renderStatusColor(item?.status).color,
            linebackground: renderStatusColor(item?.status).linebackground,
            statusLine: true,
            label: item?.status ? capitalizeWords(item?.status) : "No Data",
          },
          {
            Button: true,
            buttonLabel: "Details",
            buttonTextcolor: "#1c62e4",
            onClick: (e) => handleshowGardianDetails(item),
          },
        ],
      }));
    } else {
      return [];
    }
  };
  //===================== show data to the table =====================================//

  return (
    <div className="EmergencyContactTableContainer">
      <Table
        title="Emergency Contacts"
        tableHeader={true}
        addLabel="Create course"
        // icon={<AddIcon />}
        AddButton={false}
        headCells={headCells}
        rows={rows}
        // onClick={handleFilterDrawerOpen}
        footer={true}
        // handleSearchText={handleSearchText}
        // handleSearch={handleSearch}
        // setResetSearchTerm={setResetSearchTerm}
        // setSearchTerm={setSearchTerm}
        // resetsearchTerm={resetsearchTerm}
        innerHeader={true}
        showSearchBox={false}
        searchBoxPlaceholder="Search courses"
        // isDataLoading={isLoading || options?.isLoading}
        RowsPerPage={10}
        SearchBoxTop={false}
        emptyTableTitle="No emergency contact added yet"
        emptyTableNote="This user don't have any emergency contact added"
        showButton={false}
        btnLabel="New Course"
        AddButtonInner={false}
        addLabelInner="Create course"
        InnerButtonColor="#0450E1"
        InnerButtonTextColor="#fff"
        // onClickEmptyCard={handleFilterDrawerOpen}
      />

      {/* ================================================ */}
      <SideDrawer
        open={parentsDetails}
        handleDrawerClose={() => setParentDetails(false)}
        title="Emergency Contact Details"
        sideOpen="right"
        ButtonLabel="Close"
        cancelButtonLabel=""
        FooterWithoutTab={true}
        clickSaveButton={() => setParentDetails(false)}
        padding="0"
        body={
          <>
            <EmergencyContactDetails
              showId={showId}
              userDetails={userDetails}
            />
            {/* <StudentDetails
              singleStudentDetails={singleStudentDetails}
              academicStartDate={academicStartDate}
              academicEndDate={academicEndDate}
              section_id={section_id}
            /> */}
          </>
        }
      />
    </div>
  );
};

export default EmergencyContact;
