import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CuponLabel.module.css";
import { IconButton } from "@mui/material";

const IconButtonStyle = {
  padding: "0px !important",
  margin: "0px",
  "&.MuiIconButton-root": {
    padding: "0px !important",
  },
};

const CouponLabel = ({
  couponTitle,
  couponPercentageValue,
  couponClose,
  calculatedValue,
  couponCloseFn,
  showingPercentang,
}) => {
  return (
    <div className={styles.couponMainContainer}>
      <div className={styles.couponLabelContainer}>
        <div className={styles.couponLabel}>
          <LocalOfferIcon fontSize="small" sx={{ color: "#0b9444" }} />{" "}
          {couponTitle}
          {couponClose && (
            <IconButton
              sx={IconButtonStyle}
              onClick={couponCloseFn ? couponCloseFn : undefined}
            >
              <CloseIcon fontSize="small" sx={{ color: "#5C677D" }} />
            </IconButton>
          )}
        </div>
        &nbsp;&nbsp;
        {/* ---- percentage ---- */}
        <span>
          {couponPercentageValue}
          {showingPercentang ? "%" : ""}off
        </span>
      </div>
      {/* ---- calculated price ---- */}
      <p>-${calculatedValue}</p>
    </div>
  );
};

export default CouponLabel;
