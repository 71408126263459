import React, { useState, useEffect, useRef } from "react";

import {
  getDataMap,
  getMonths,
  getYears,
  getDates,
  capitalizeWords,
} from "../../../../hooks/HelperFunctions";

import Grid from "@material-ui/core/Grid";
// import TextField from '../../../../../components/Form/TextField/TextField';
import GooglePlaceField from "../../../../components/Form/GooglePlaceField/GooglePlaceField";
// import GooglePlacesAutoField from '../../../../../components/Form/GooglePlaceField/GooglePlacesAutoField';

// import SelectField from '../../../../../components/Form/SelectField/SelectField';
import ComboBox from "../../../../components/Form/ComboBox/ComboBox";

import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";
import FileUplaoder from "../../../../components/Form/FileUplaoder/FileUplaoder";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import PhoneInputField from "../../../../components/Form/PhoneInputField/PhoneInputField";
import TextField from "../../../../components/Form/TextField/TextField";
import moment from "moment-timezone";
import { useMemo } from "react";
import { countries } from "../../../../assets/data/countries";
import {
  getProfileAvatarFromName,
  mapMonth,
  monthArray,
} from "../../../../utils/helpers";
import { type } from "@testing-library/user-event/dist/type";
import SideDrawer from "../../../../components/SideDrawer/SideDrawer";
import MediaLibrarySelectMedia from "../../../../components/MediaLibrary/MediaLibrarySelectMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Buttons from "../../../../components/Form/Button/Button";
import Externaluse from "../../../../components/MediaLibrary/Externaluse";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Button, Divider, IconButton } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import "./UpdatePersonalInfo.css";
import MenuThreeDot from "../../../../components/MenuThreeDot/MenuThreeDot";
import SelectField from "../../../../components/Form/SelectField/SelectField";
import AutoCompleteSelectFiled from "../../../../components/Form/AutoCompleteSelectFiled/AutoCompleteSelectFiled";
import useProtected from "../../../../hooks/useProtected";
import { useUserImageUplodaToDBMutation } from "../../../../redux/features/parentPortal/users/usersApi";
import axios from "axios";
import config from "../../../../config";

function UpdatePersonalInfo({
  personalInfo,
  setPersonalInfo,
  birthMonth,
  setBirthMonth,
  birthYear,
  birthDate,
  setBirthDate,
  setBirthYear,
  errors,
  manualAddress,
  setManualAddress,
}) {
  const [dotMenu, setDotMenu] = React.useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  // const [manualAddress, setManualAddress] = useState(false);
  const open = Boolean(dotMenu);
  const handlerDotMenu = (event) => {
    setDotMenu(event.currentTarget);
  };
  // console.log(dotMenu);
  const handleClose = () => {
    setDotMenu(null);
  };

  const contactOptions = [
    {
      label: `${personalInfo?.email} (Email)`,
      value: `${personalInfo?.email} (Email)`,
    },
    ...(personalInfo?.homePhone?.length > 8
      ? [
          {
            label: `+${personalInfo.homePhone} (Home Phone)`,
            value: `+${personalInfo.homePhone} (Home Phone)`,
          },
        ]
      : []),
    ...(personalInfo?.workPhone?.length > 8
      ? [
          {
            label: `+${personalInfo.workPhone} (Work Phone)`,
            value: `+${personalInfo.workPhone} (Work Phone)`,
          },
        ]
      : []),
    ...(personalInfo?.cellPhone?.length > 8
      ? [
          {
            label: `+${personalInfo.cellPhone} (Cell Phone)`,
            value: `+${personalInfo.cellPhone} (Cell Phone)`,
          },
        ]
      : []),
  ];

  const [preferredContactOptions, setPreferredContactOptions] =
    useState(contactOptions);

  useEffect(() => {
    setPreferredContactOptions(contactOptions);
  }, [personalInfo.homePhone, personalInfo.workPhone, personalInfo.cellPhone]);

  useEffect(() => {
    if (personalInfo?.preferredContact) {
      const input = personalInfo.preferredContact;
      const match = input.match(/\(([^)]+)\)/);
      const contactType = match ? match[1] : null;
      const preferredContactNumber = input.split(" (")[0];
      const phoneNumbers = {
        "Home Phone": personalInfo.homePhone,
        "Work Phone": personalInfo.workPhone,
        "Cell Phone": personalInfo.cellPhone,
      };

      if (contactType && phoneNumbers[contactType]) {
        const exactNumber = `+${phoneNumbers[contactType]}`;

        if (preferredContactNumber !== exactNumber) {
          setPersonalInfo((prev) => ({
            ...prev,
            preferredContact: "",
          }));
        }
      }
    }
  }, [personalInfo]);

  // ================= Time zone ====================
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [timezone, setTimezone] = useState("");
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  const [preview, setPreview] = useState(null);
  const handleFieldChange = (fieldName, fieldValue) => {
    // setFormFieldsValue({
    //     ...formFieldsValue,
    //     [fieldName]: fieldValue
    // });
  };

  useMemo(() => {
    const commonTimeZones = [
      // North America
      "America/Los_Angeles", // PT - Pacific Time (Los Angeles, Vancouver)
      "America/Denver", // MT - Mountain Time (Denver, Calgary)
      "America/Chicago", // CT - Central Time (Chicago, Mexico City)
      "America/New_York", // ET - Eastern Time (New York, Toronto)
      "America/Halifax", // AT - Atlantic Time (Halifax, St. John's)
      "America/Anchorage", // AKT - Alaska Time (Anchorage, Juneau)

      // South America
      "America/Sao_Paulo", // BRT - Brasília Time (Brasília, São Paulo)
      "America/Argentina/Buenos_Aires", // ART - Argentina Time (Buenos Aires, Mendoza)
      "America/Santiago", // CLT - Chile Time (Santiago, Valparaíso)

      // Europe
      "Europe/London", // GMT - Greenwich Mean Time (London, Dublin)
      "Europe/Paris", // CET - Central European Time (Berlin, Paris)
      "Europe/Athens", // EET - Eastern European Time (Athens, Bucharest)
      "Europe/Lisbon", // WET - Western European Time (Lisbon, Madrid)

      // Africa
      "Africa/Lagos", // WAT - West Africa Time (Lagos, Accra)
      "Africa/Nairobi", // CAT - Central Africa Time (Nairobi, Harare)
      "Africa/Johannesburg", // SAST - South Africa Standard Time (Johannesburg, Pretoria)
      "Africa/Addis_Ababa", // EAT - East Africa Time (Addis Ababa, Kampala)

      // Asia
      "Asia/Riyadh", // AST - Arabian Standard Time (Riyadh, Doha)
      "Asia/Dubai", // GST - Gulf Standard Time (Dubai, Manama)
      "Asia/Karachi", // PKT - Pakistan Standard Time (Islamabad, Karachi)
      "Asia/Kolkata", // IST - Indian Standard Time (New Delhi, Mumbai)
      "Asia/Kathmandu", // NPT - Nepal Time (Kathmandu, Biratnagar)
      "Asia/Dhaka", // BST - Bangladesh Standard Time (Dhaka, Chittagong)
      "Asia/Kuala_Lumpur", // MYT - Malaysia Standard Time (Kuala Lumpur, Putrajaya)
      "Asia/Shanghai", // CST - China Standard Time (Beijing, Shanghai)
      "Asia/Tokyo", // JST - Japan Standard Time (Tokyo, Kyoto)
      "Asia/Seoul", // KST - Korea Standard Time (Seoul, Pyongyang)
      "Asia/Bangkok", // ICT - Indochina Time (Bangkok, Vientiane)
      "Asia/Tehran", // IRST - Iran Standard Time (Tehran, Isfahan)

      // Australia
      "Australia/Sydney", // AEST - Australian Eastern Standard Time (Sydney, Melbourne)
      "Australia/Adelaide", // ACST - Australian Central Standard Time (Adelaide, Darwin)
      "Australia/Perth", // AWST - Australian Western Standard Time (Perth, Broome)

      // Oceania
      "Pacific/Guadalcanal", // SBT - Solomon Islands Time (Honiara, Gizo)
      "Pacific/Fiji", // FJT - Fiji Time (Suva, Nadi)
      "Pacific/Tongatapu", // TOT - Tonga Time (Nuku'alofa, Neiafu)
    ];

    const names = moment.tz.names();
    let groupedTmz = {};

    // Group timezones by the first letter
    names.forEach((name) => {
      if (commonTimeZones.includes(name)) {
        // Filter only common timezones
        const offset = moment.tz(name).utcOffset();
        const firstLetter = name[0].toUpperCase();
        if (!groupedTmz[firstLetter]) {
          groupedTmz[firstLetter] = [];
        }
        groupedTmz[firstLetter].push({
          name,
          offset,
          formatted: `${name} (GMT${moment.tz(name).format("Z")})`,
        });
      }
    });

    // Sort each group by GMT offset
    Object.keys(groupedTmz).forEach((letter) => {
      groupedTmz[letter].sort((a, b) => a.offset - b.offset);
    });

    // Flatten the grouped and sorted timezones into a single array
    let offsetTmz = [];
    Object.keys(groupedTmz)
      .sort()
      .forEach((letter) => {
        offsetTmz = [...offsetTmz, ...groupedTmz[letter]];
      });

    setTimeZoneOptions(
      offsetTmz.map((item) => ({
        label: item.formatted,
        value: item.formatted,
      }))
    );
  }, []);

  const handleChangeWithData = (fieldName, fieldData, setMethod) => {
    setMethod(fieldData);
    handleFieldChange(fieldName, fieldData);
  };
  // =================================================

  const onChangeHandler = (val, e, fieldName) => {
    const fieldData = e.target.value;
    if (fieldName === "ext" && fieldData?.length > 5) {
      return false;
    }
    let newObject = { ...personalInfo, [fieldName]: fieldData };
    setPersonalInfo(newObject);
  };

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if (
            (birthYear % 4 === 0 && birthYear % 100 !== 0) ||
            birthYear % 400 === 0
          ) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    setDateOptions(dates);
  }, [birthMonth, birthYear]);

  //============================= address part ==============================================//
  const checkJsonStringOrNot = (address) => {
    if (typeof address === "string") {
      if (address.length == 0) {
        return "";
      }
      if (
        /^[\],:{}\s]*$/.test(
          address
            .replace(/\\["\\\/bfnrtu]/g, "@")
            .replace(
              /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
              "]"
            )
            .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
        )
      ) {
        return JSON.parse(address);
      } else {
        return { place_id: "", value: "" };
      }
    } else {
      return address;
    }
  };

  const handleChangeWithDatas = (fieldName, fieldData, country) => {
    setPersonalInfo({
      ...personalInfo,
      [fieldName]: fieldData,
    });
  };

  //============================= address part ==============================================//

  // ================ media lib =================
  const [imgUpload, setImgUploadOptions] = useUserImageUplodaToDBMutation();
  useProtected(setImgUploadOptions.error);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);

  const handleDrawerCloseSelectMedia = () => {
    // setSelectedValue(false)
    // setMediaItem({})
    setOpenSelectMedia(false);
  };
  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };
  const handleMediaUploadClose = () => {
    setOpenSelectMedia(true);
    setOpenNewMedia(false);
  };
  const handleOpenNewMedia = (status) => {
    setOpenNewMedia(!status);

    setOpenSelectMedia(true);
  };

  const fileInputRef = useRef(null);

  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImage, setIsImage] = useState(false);
  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleFileInput = (e) => {
    // const item = e.target.files[0];
    // const maxSize = Number(10 * 1024 * 1024); // 15MB in bytes

    setSelectedFile(e.target.files[0]);

    if (setSelectedFile) {
      setSelectedFile(e.target.files[0]);
    }

    console.log(e.target.files[0], 324);
    // return '' ;
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = reader.result;
      const isImage = result.startsWith("data:image/");

      if (isImage) {
        const img = new Image();
        setIsImage(true);

        img.onload = function () {
          setWidth(img.naturalWidth);
          setHeight(img.naturalHeight);

          //check if props.setWidth is defined then set width
          if (setWidth) {
            setWidth(img.naturalWidth);
          }

          //check if props.setHeight is defined then set height
          if (setHeight) {
            setHeight(img.naturalHeight);
          }
        };

        img.src = event.target.result;
      }
    };

    reader.onloadend = () => {
      setPreview(reader.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  useEffect(() => {
    if (selectedFile) {
      uploadData(selectedFile);
    }
  }, [selectedFile]);

  const uploadData = async (imgData) => {
    const formData = new FormData();

    formData.append("file", imgData);

    const apiURL = `${config.api_url}/parent-portal/uploadProfileImage/${personalInfo?._id}`;

    axios
      .post(apiURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response?.data?.body?.data?.idPicture) {
          // console.log(response?.data?.body?.data?.idPicture, 324);
          setPersonalInfo({
            ...personalInfo,
            profilePic: response?.data?.body?.data?.idPicture,
          });
        }
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      });
  };

  const deleteUploadedFile = () => {
    //delete from local
    fileInputRef.current.value = "";
    setSelectedFile(null);
    setPersonalInfo({
      ...personalInfo,
      profilePic: "",
    });
  };

  const getFilenameFromUrl = (url) => {
    let filename = "";
    if (url) {
      const parts = url?.split("/");
      const filenameWithExtension = parts[parts?.length - 1];
      filename = filenameWithExtension?.split(".")[0];
    }
    return filename;
  };

  const options = [
    {
      label: "CHANGE PHOTO",
      onClick: () => fileInputRef.current.click(),
    },
    {
      label: "REMOVE CURRENT PHOTO",
      onClick: () => deleteUploadedFile(),
    },
  ];

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    let newObject = {
      ...personalInfo,
      preferredLanguage: selectedOption?.value,
    };
    setPersonalInfo(newObject);
  };
  useEffect(() => {
    if (personalInfo?.preferredLanguage) {
      let findData = LangArr?.find(
        (item) => item?.value === personalInfo?.preferredLanguage
      );
      setSelectedOption(findData);
    }
    // console.log(personalInfo, 341);
  }, [personalInfo]);

  return (
    <>
      {/* ========== Name ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol" style={{ width: "20%" }}>
          Name<span style={{ color: "#d43535" }}>*</span>
        </div>
        <div
          className="FormFieldCol profileNameField"
          style={{
            width: "65%",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="First name"
            required={true}
            // count={20}
            name={"section"}
            value={personalInfo.firstName}
            // helperText={errors?.firstName}
            error={errors?.firstName}
            // value={sectionObject.sectionName}
            handleChange={(val, e) => onChangeHandler(val, e, "firstName")}
          />
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Middle name"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            value={personalInfo.middleName}
            handleChange={(val, e) => onChangeHandler(val, e, "middleName")}
          />
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Last name"
            required={true}
            // count={20}
            name={"section"}
            // helperText={errors?.lastName}
            error={errors?.lastName}
            value={personalInfo.lastName}
            handleChange={(val, e) => onChangeHandler(val, e, "lastName")}
          />
        </div>
      </div>
      {/* ========== Profile Picture ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Profile Photo<span></span>
        </div>

        {/* ============================================ */}
        {/* ============================================ */}
        <div className="UploadPhotoCard" style={{ marginLeft: "80px" }}>
          <div className="UploadPhotoCardImg">
            <Avatar
              sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
              src={personalInfo?.profilePic ? personalInfo?.profilePic : ""}
            >
              {getProfileAvatarFromName(personalInfo)}
            </Avatar>
          </div>

          {personalInfo?.profilePic && (
            <div className="UploadPhotoFiled">
              <div className="UploadPhotoImgCol">
                <span>
                  <img src={personalInfo?.profilePic} alt="profile pic" />
                </span>
                <div className="ImgDetails">Profile Image</div>
              </div>
              <span className="UploadPhotoFiledDelete">
                <DeleteForeverRoundedIcon
                  onClick={() => {
                    setPersonalInfo({ ...personalInfo, profilePic: "" });
                  }}
                />
              </span>
            </div>
          )}

          {!personalInfo?.profilePic && (
            <div className="UplaodPhotoModal">
              <div>
                {/* <Buttons
                  label="Add From Media Library"
                  buttonColor="transparent"
                  border="#E4E8EC 1px solid"
                  icon={<AddRoundedIcon />}
                  color="#5423FF"
                  width="100%"
                  height="70px"
                  fontSize="14px"
                  link=""
                  // onClick={() => handleDrawerOpenSelectMedia()}
                  onClick={handleFileInput}
                /> */}
                <input
                  className="uploadfield"
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                  accept="audio/*,video/*,image/*"
                />

                <SideDrawer
                  title="Media Library"
                  ButtonLabel="Add"
                  //clickSaveButton={clickSaveButton}
                  cancelButtonLabel="Cancel"
                  sideOpen="right"
                  open={openSelectMedia}
                  handleDrawerClose={handleDrawerCloseSelectMedia}
                  FooterWithoutTab={true}
                  LeftButton={true}
                  ButtonLeft="Upload New"
                  ButtonLeftIcon={<FileUploadOutlinedIcon />}
                  clickButtonLeft={handleDrawerOpenNewMedia}
                  // clickSaveButton={clickSelectMediaItem}
                  padding="0"
                  body={
                    <MediaLibrarySelectMedia
                      open={openNewMedia}
                      handleCloseButton={handleMediaUploadClose}
                      handleOpenNewMedia={handleOpenNewMedia}
                    />
                  }
                />

                <Externaluse
                  open={openNewMedia}
                  handleCloseButton={handleMediaUploadClose}
                  handleOpenNewMedia={handleOpenNewMedia}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ========== Gender ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Gender<span></span>
        </div>

        <div className="FormFieldCol">
          <SelectField
            label="Select gender"
            name="gender"
            options={genderOptions}
            selectedOption={personalInfo?.gender}
            //   helperText={sectionErrors?.acededemicYear}
            //   error={sectionErrors?.acededemicYear}
            handleChange={(val) => onChangeHandler(val, val, "gender")}
          />
        </div>
      </div>

      {/* ========== Birthday ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Birthday<span></span>
        </div>

        <div className="FormFieldCol">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Month"
                placeholder="Month"
                // error={errors?.birthMonth}
                // helperText={errors?.birthMonth}
                options={getMonths()}
                selectedOption={birthMonth && birthMonth - 1}
                handleChange={(e) => setBirthMonth(e.target.value + 1)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Day"
                placeholder="Day"
                // error={errors?.birthDate}
                // helperText={errors?.birthDate}
                options={dateOptions}
                selectedOption={birthDate}
                handleChange={(e) => setBirthDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <SelectField
                label="Year"
                placeholder="Year"
                // error={errors?.birthYear}
                // helperText={errors?.birthYear}
                options={getYears()}
                selectedOption={birthYear}
                handleChange={(e) => setBirthYear(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Highest Education<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Select highest education"
            name="highestEducation"
            options={highestEducation}
            selectedOption={personalInfo?.highestEducation}
            // helperText={}
            // error={}
            handleChange={(val) =>
              onChangeHandler(val, val, "highestEducation")
            }
          />
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol" style={{ width: "20%" }}>
          Occupation<span></span>
        </div>
        <div className="FormFieldCol" style={{ width: "65%" }}>
          <TextField
            CharAlignMid="CharAlignMid"
            Uppercase="Uppercase"
            placeholder="Enter occupation"
            required={true}
            // count={20}
            name={"section"}
            // helperText={sectionErrors?.sectionName}
            // error={sectionErrors?.sectionName}
            value={personalInfo?.occupation}
            handleChange={(val, e) => onChangeHandler(val, e, "occupation")}
          />
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Preferred Language<span></span>
        </div>
        <div className="FormFieldCol">
          <AutoCompleteSelectFiled
            options={LangArr}
            onSelect={handleSelect}
            selectedValue={selectedOption}
            placeholder="Select preferred language"
          />
          {/* <SelectField
            label="Select preferred language"
            name="highestEducation"
            options={[{ label: "English", value: "english" }]}
            selectedOption={personalInfo.preferredLanguage}
            // helperText={}
            // error={}
            handleChange={(val) =>
              onChangeHandler(val, val, "preferredLanguage")
            }
          /> */}
        </div>
      </div>

      {/* ========== Highest Education ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Timezone<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Select Timezone"
            name="timezone"
            options={timeZoneOptions}
            selectedOption={personalInfo?.timezones}
            // helperText={}
            // error={}
            handleChange={(val) => onChangeHandler(val, val, "timezones")}
          />
        </div>
      </div>

      <Divider />

      {/* ========== Contact Information ============ */}
      <h3 style={{ fontWeight: 600, fontSize:"16px" }}>Contact Information</h3>

      {/* ========== Preferred Contact ============ */}
      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Preferred Contact<span></span>
        </div>
        <div className="FormFieldCol">
          <SelectField
            label="Preferred Contact"
            name="preferredContact"
            options={preferredContactOptions}
            selectedOption={personalInfo?.preferredContact}
            handleChange={(val) =>
              onChangeHandler(val, val, "preferredContact")
            }
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol">
          Email<span></span>
        </div>
        <div className="FormFieldCol">
          <div className="PreviewSection">{personalInfo?.email}</div>
          {/* <p style={{ color: "#d32f2f" }}>Required</p> */}
        </div>
      </div>

      <div className="FormFieldContainer profilePhoneFieldContainer">
        <div className="FloatLabelCol">
          Cell/Mobile Phone<span></span>
        </div>
        <div className="FormFieldCol">
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            value={personalInfo?.cellPhone}
            countryCodeEditable={false}
            onChange={(value, country, e, formattedValue) =>
              handleChangeWithDatas("cellPhone", value)
            }
            onBlur={() => console.log("onBlur")}
            // onKeyDown={handleChanges("phone")}
            variant="outlined"
            onlyCountries={["us", "ca", "bd"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            // labelFixed="Phone number"
            LabelStatus={true}
            //helpertext="Helper text"
            //error="error"
          />
        </div>
      </div>

      <div className="FormFieldContainer profilePhoneFieldContainer">
        <div className="FloatLabelCol">
          Work Phone<span></span>
        </div>
        <div className="FormFieldCol" style={{ display: "flex", gap: "12px" }}>
          <div style={{ flex: 1 }}>
            <PhoneInputField
              LabelStatus={true}
              labelFixed=""
              phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
              country={"us"}
              value={personalInfo?.workPhone}
              countryCodeEditable={false}
              onChange={(value, country, e, formattedValue) =>
                handleChangeWithDatas("workPhone", value)
              }
              onBlur={() => console.log("onBlur")}
              // onKeyDown={handleChanges("phone")}
              variant="outlined"
              onlyCountries={["us", "ca", "bd"]}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "40px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              errorText={"Phone number is required"}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              inputProps={{}}
              specialLabel={"Primary Phone"}
            />
          </div>
          <div>
            <TextField
              placeholder="Ext."
              required={false}
              number={true}
              value={personalInfo?.ext}
              handleChange={(val, e) => onChangeHandler(val, e, "ext")}
            />
          </div>
        </div>
      </div>

      {/* <Grid item xs={12} md={4}>
        <TextField
          label="Ext."
          // placeholder="Ext."
          required={false}
          number={true}
          value={personalInfo?.ext}
          handleChange={(val, e) => onChangeHandler(val, e, "ext")}
        />
      </Grid> */}

      <div className="FormFieldContainer profilePhoneFieldContainer">
        <div className="FloatLabelCol">
          Home Phone<span></span>
        </div>
        <div className="FormFieldCol">
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            value={personalInfo?.homePhone}
            countryCodeEditable={false}
            onChange={(value, country, e, formattedValue) =>
              handleChangeWithDatas("homePhone", value)
            }
            onBlur={() => console.log("onBlur")}
            // onKeyDown={handleChanges("phone")}
            variant="outlined"
            onlyCountries={["us", "ca", "bd"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorText={"Phone number is required"}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            // labelFixed="Phone number"
            LabelStatus={true}
            //helpertext="Helper text"
            //error="error"
          />
        </div>
      </div>

      <div className="FormFieldContainer">
        <div className="FloatLabelCol" style={{ marginTop: "28px" }}>
          Country<span></span>
        </div>
        <div className="FormFieldCol">
          <ComboBox
            label=""
            options={countries}
            selectedOption={personalInfo?.country || ""}
            handleChange={(fieldData) =>
              handleChangeWithDatas("country", fieldData)
            }
          />
        </div>
      </div>

      <Grid container>
        {manualAddress ? (
          <Grid item xs={12}>
            <div className="FormFieldContainer">
              <div className="FloatLabelCol">
                Address<span></span>
              </div>
              <div className="FormFieldCol">
                <GooglePlaceField
                  label=""
                  validation={true}
                  placeholder="Your current resident address"
                  value={checkJsonStringOrNot(personalInfo?.address)}
                  handleChange={(address) =>
                    handleChangeWithDatas("address", address)
                  }
                  error={errors?.adddress}
                  helperText={errors?.adddress}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={4}>
              <div style={{ marginTop: "14px" }}>
                <span style={{ fontWeight: "600" }}>Address</span>
              </div>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2} style={{ paddingLeft: "12px" }}>
                {/* == street address == */}
                <Grid item xs={12}>
                  <TextField
                    placeholder="Current resident address"
                    required={false}
                    value={personalInfo?.addressLine1}
                    handleChange={(val, e) =>
                      onChangeHandler(val, e, "addressLine1")
                    }
                    helperText={errors?.streetAddress}
                    error={errors?.streetAddress}
                  />
                </Grid>
                {/* == Apt/Floor address == */}
                <Grid item xs={12}>
                  <TextField
                    placeholder="Apt/Floor"
                    required={false}
                    value={personalInfo?.addressLine2}
                    handleChange={(val, e) =>
                      onChangeHandler(val, e, "addressLine2")
                    }
                  />
                </Grid>
                {/* == City/Town/Village address == */}
                <Grid item xs={12}>
                  <TextField
                    placeholder="City/Town/Village"
                    required={true}
                    value={personalInfo?.city}
                    handleChange={(val, e) => onChangeHandler(val, e, "city")}
                  />
                </Grid>
                {/* == State/Province/Territory address == */}
                <Grid item xs={12}>
                  <TextField
                    placeholder="State/Province/Territory"
                    required={true}
                    value={personalInfo?.state}
                    handleChange={(val, e) => onChangeHandler(val, e, "state")}
                  />
                </Grid>
                {/* == Zip/Postal code address == */}
                <Grid item xs={12}>
                  <TextField
                    placeholder="Zip/Postal code"
                    required={true}
                    value={personalInfo?.zip}
                    handleChange={(val, e) => onChangeHandler(val, e, "zip")}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* ==== switch button ==== */}
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          <Button
            variant="text"
            sx={{
              padding: "0px",
              marginTop: manualAddress ? "-26px" : "12px",
              marginLeft: "12px",
              color: "#0450E1",
              textTransform: "initial",
            }}
            onClick={() => setManualAddress(!manualAddress)}
          >
            {manualAddress ? "Enter address manually" : "Search your address"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdatePersonalInfo;

const genderOptions = [
  {
    label: "Male",
    value: "male",
  },

  {
    label: "Female",
    value: "female",
  },

  {
    label: "Others",
    value: "others",
  },
];

const highestEducation = [
  {
    label: "None",
    value: "None",
  },

  {
    label: "Less than high school diploma (or equivalent)",
    value: "Less than high school diploma (or equivalent)",
  },

  {
    label: "High school diploma (or equivalent)",
    value: "High school diploma (or equivalent)",
  },

  {
    label: "Some college but no degree",
    value: "Some college but no degree",
  },

  {
    label: "Associate Degree (e.g., AA, AS)",
    value: "Associate Degree (e.g., AA, AS)",
  },

  {
    label: "Bachelor's degree (e.g., BA, AB, BS)",
    value: "Bachelor's degree (e.g., BA, AB, BS)",
  },

  {
    label: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)",
    value: "Master's degree (e.g., MA, MS, MEng, MEd, MSW, MBA)",
  },

  {
    label: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0",
    value: "Professional school degree (e.g., MD, DDS, DVM, LLB, JD0",
  },

  {
    label: "Doctorate degree (e.g., PhD, EdD)",
    value: "Doctorate degree (e.g., PhD, EdD)",
  },
];

const LangArr = [
  {
    label: "Arabic",
    value: "arabic",
  },
  {
    label: "Armenian",
    value: "armenian",
  },
  {
    label: "Bengali",
    value: "bengali",
  },
  {
    label: "Chinese",
    value: "chinese",
  },
  {
    label: "English",
    value: "english",
  },
  {
    label: "French",
    value: "french",
  },
  {
    label: "German",
    value: "german",
  },
  {
    label: "Greek",
    value: "greek",
  },
  {
    label: "Haitian Creole",
    value: "haitian-creole",
  },
  {
    label: "Hindi",
    value: "hindi",
  },
  {
    label: "Italian",
    value: "italian",
  },
  {
    label: "Indonesian",
    value: "indonesian",
  },
  {
    label: "Japanese",
    value: "japanese",
  },
  {
    label: "Korean",
    value: "korean",
  },
  {
    label: "Persian",
    value: "persian",
  },
  {
    label: "Polish",
    value: "polish",
  },
  {
    label: "Portuguese",
    value: "portuguese",
  },
  {
    label: "Punjabi",
    value: "punjabi",
  },
  {
    label: "Russian",
    value: "russian",
  },
  {
    label: "Serbo-Croatian",
    value: "serbo-croatian",
  },
  {
    label: "Spanish",
    value: "spanish",
  },
  {
    label: "Tagalog",
    value: "tagalog",
  },
  {
    label: "Thai",
    value: "thai",
  },
  {
    label: "Urdu",
    value: "urdu",
  },
  {
    label: "Vietnamese",
    value: "vietnamese",
  },
];
