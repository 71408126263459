import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
// import styles from '../../../assets/css/form.module.css';
import Buttons from '../../components/Form/Button/Button';
import OtpInput from 'react-otp-input';
import '../../assets/css/otp.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axiosApi from '../../utils/axios';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import { useDispatch } from 'react-redux';
import { setToken, setUserData } from '../../redux/features/authSlice';
import SimpleBackdrop from '../../components/Backdrop/SimpleBackdrop';
import LogoHeader from './LogoHeader/LogoHeader';

function Otp(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [userId, setUserId] = useState('');
  const [is2fa, setIs2fa] = useState('');
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const [openBackdrop, setOpenBackdrop] = useState(true);

  useEffect(() => {
    const id = searchParams.get('userId');
    const otp = searchParams.get('otp');
    const mfa = searchParams.get('mfa');
    if (id) {
      setUserId(id);
    }
    if (otp) {
      setOtp(otp);
    }
    if (mfa) {
      setOpenBackdrop(false);
      setIs2fa(mfa);
    }
  }, [searchParams]);

  useEffect(() => {
    if (otp) {
      handleVerifyOtp();
    }
  }, [otp]);

  const handleVerifyOtp = async () => {
    try {
      const data = {
        otp,
        userId,
      };
      const result = await axiosApi.post('/users/public/verify', data);
      if (result.data.error) {
        setAlertOptions({
          msg: result.data.message,
          type: 'error',
        });
        return;
      }

      if (is2fa) {
        const token = location.state?.token;
        dispatch(setUserData(result.data.body.data));
        dispatch(setToken(token));
        localStorage.setItem('user', JSON.stringify(result.data.body.data));
        localStorage.setItem('token', token);

        navigate(location.state?.from ? location.state?.from : '/');
        return;
      }

      setAlertOptions({
        msg: result.data.message,
        type: 'success',
      });

      setOpenBackdrop(false);
      navigate('/signin');
    } catch (err) {
      setOpenBackdrop(false)
      setAlertOptions({
        msg: err.message,
        type: 'error',
      });
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">
          <div className="SignForm">
            <h1>Two-factor authentication</h1>
            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />
            <Grid container spacing={3}>

            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className="OtpFields">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    //renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    disabled={false}
                    style={false}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <p className="FormDesText14">
                  A message with a verification code has been sent to your phone
                  number. <br />
                  Enter the code to continue.
                </p>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="ContinueOtpButton">
                  <Buttons
                    label="Continue"
                    buttonColor="#FFB606"
                    border="#FFB606 1px solid"
                    color="#000"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    // disabled={continueButton}
                    link=""
                    onClick={handleVerifyOtp}
                  />
                </div>
                <div className="ResendOtpButton">
                  <Buttons
                    label="Resend Code"
                    buttonColor="#FFF"
                    border="#ccc 0px solid"
                    textDecoration="underline"
                    color="#5423FF"
                    width=""
                    height="48px"
                    fontSize="14px"
                    //disabled={resendOtpButton}
                    link=""
                    onClick={() => { }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="DontHaveAccount">
                  <span>Having trouble?</span>
                </div>

                <div className="ContinueOtpButton">
                  <Buttons
                    label="Sign up"
                    buttonColor="#fff"
                    border="#FFB606 1px solid"
                    color="#000"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    // disabled={continueButton}
                    link="/sign-up"
                    onClick={undefined}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>
      </div>
    </>
  );
}

export default Otp;
