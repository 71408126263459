import { api } from "../../../redux/api/apiSlice";

const stripeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStripeConfig: builder.query({
      query: () => ({
        url: `/stripe/parent/config`,
      }),
      providesTags: ["STRIPE_PUBLISHABLE_KEY"],
    }),
    getStripePaymentSession: builder.mutation({
      query: (data) => ({
        url: `/stripe/parent/create-payment-intent`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["clientSecret"],
    })   
    
  }),
});

export const {
  useGetStripeConfigQuery,
  useGetStripePaymentSessionMutation,
  useLazyGetStripeConfigQuery,
  useLazyGetStripePaymentSessionMutation,
} = stripeApi;