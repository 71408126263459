import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: "#5C677D",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    border: "1px solid #e8eaed",
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#FCFCFC",
    // },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const Contact = ({ guardianParentsDetails }) => {
  return (
    <div>
      <div
        className=" oddEvenTable"
        style={{
          overflow: "hidden",
          border: "0.5px solid #e8eaed",
          boxShadow: "0 0 8px #edeef1",
          borderRadius: "12px",
        }}
      >
        {/* <div
          className="TableInnerHeader"
          style={{
            border: "1px solid #e8eaed",
            borderBottom: "0px",
            padding: "12px",
            color: "#5C677D",
          }}
        >
          <h3>Access details</h3>
        </div> */}
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Preferred Contact
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <div className="cellwithchip" style={{ color: "#000" }}>
                    {/* +1 (123) 123-1234 (Home Phone) */}
                    {guardianParentsDetails?.preferredContact || (
                      <span style={{ color: "#BBBFC9" }}>
                        No data available
                      </span>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left" style={{ color: "#000" }}>
                  {guardianParentsDetails?.email || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Home Phone</StyledTableCell>
                <StyledTableCell align="left" style={{ color: "#000" }}>
                  {/* +1 (123) 123-1234 */}
                  {guardianParentsDetails?.homePhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Work Phone</StyledTableCell>
                <StyledTableCell align="left" style={{ color: "#000" }}>
                  {/* +1 (123) 123-1234 */}
                  {guardianParentsDetails?.workPhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">
                  Cell/Mobile Phone
                </StyledTableCell>
                <StyledTableCell align="left" style={{ color: "#000" }}>
                  {guardianParentsDetails?.cellPhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                  {/* <span style={{ color: "#BBBFC9" }}>No data available</span> */}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Contact;
