import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const PageTitle = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const path = location.pathname;
    let name = "";

    // You can define your own logic to extract the route name
    if (path === "/inbox") {
      name = "Inbox";
    } else if (path === "/students" || path === "/students-details/:id") {
      name = "Students";
    } else if (path === "/applications") {
      name = "Applications";
    } else if (path === "/calender") {
      name = "Calender";
    } else if (path === "/users" || path === "/users/:id") {
      name = "Users";
    } else if (path === "/profile") {
      name = "Profile";
    } else if (path === "/payments") {
      name = "Payments";
    } else if (path === "/media-library-table-view") {
      name = "Media lib -";
    } else if (path === "/settings") {
      name = "Settings -";
    } else if (path === "/signin") {
      name = "Sign in";
    } else {
      name = "KeeperEDU | Best Education Management System";
    }

    // Set the tab name along with the company name
    setPageTitle(`${name}`);
  }, [location]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>;
      <meta
        name="DeenSchool - Secured Portal for Education Management"
        content="DeenSchool is all-in-one, cloud based education management platform for Islamic K-12 & Weekend Schools.
      One secured platform for students, parents, faculty & staff in Islamic schools."
      />
    </Helmet>
  );
};

export default PageTitle;
