import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddSibling from './AddSibling';
import AddHouseholdIncome from './AddHouseholdIncome';


import HomeLanguageQuestionnaire from './HomeLanguageQuestionnaire';
import SchoolDetails from './SchoolDetails';
import ChildDescription from './ChildDescription';


const AdditionalDetails = (props) => {

  

  //=== Home Language Questionnaire Drawer===//
  const [openHomeLanguageQuestionnaire, setOpenHomeLanguageQuestionnaire] = useState(false);
  const handleDrawerOpenHomeLanguageQuestionnaire = () => {
    setOpenHomeLanguageQuestionnaire(true);
  };
  const handleDrawerCloseHomeLanguageQuestionnaire = () => {
    setOpenHomeLanguageQuestionnaire(false);
  };

  //=== Schoo Details Drawer ===//
  const [openSchooDetails, setOpenSchooDetails] = useState(false);
  const handleDrawerOpenSchooDetails = () => {
    setOpenSchooDetails(true);
  };
  const handleDrawerCloseSchooDetails = () => {
    setOpenSchooDetails(false);
  };

   //=== Child Description Drawer ===//
   const [openChildDescription, setOpenChildDescription] = useState(false);
   const handleDrawerOpenChildDescription = () => {
     setOpenChildDescription(true);
   };
   const handleDrawerCloseChildDescription = () => {
     setOpenChildDescription(false);
   };


  const guardianData = [
 
    {
      Title: "Home Language Questionnaire (HLQ)",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenHomeLanguageQuestionnaire,

    },

    

    {
      Title: "School Details",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenSchooDetails,

    },

    {
      Title: "Child description",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenChildDescription,

    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> Additional details</div>
      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                  CardBody={item.CardBody}
                />
              ))}
            </div>
          }
        />




      </div>





     


      {/*=== Home Language Questionnaire (HLQ) Drawer */}
      <SideDrawer
        title="Home Language Questionnaire (HLQ)"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openHomeLanguageQuestionnaire}
        handleDrawerClose={handleDrawerCloseHomeLanguageQuestionnaire}
        FooterWithoutTab={true}
        body={
          <HomeLanguageQuestionnaire />
        }
      />

      {/*=== School Details Drawer */}
      <SideDrawer
        title="School Details"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSchooDetails}
        handleDrawerClose={handleDrawerCloseSchooDetails}
        FooterWithoutTab={true}
        body={
          <SchoolDetails />
        }
      />

      {/*=== Child Description Drawer */}
      <SideDrawer
        title="Child Description"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openChildDescription}
        handleDrawerClose={handleDrawerCloseChildDescription}
        FooterWithoutTab={true}
        body={
          <ChildDescription />
        }
      />






    </>
  );
};

export default AdditionalDetails;
