import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '../../../components/Form/Button/Button';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddGuardian from './AddGuardian';
import FatherInformation from './FatherInformation';
import MothersInformation from './MothersInformation';



const GuardianInformation = (props) => {

  //=== Father Information ===//
  const [openFatherInformation, setOpenFatherInformation] = useState(false);
  const handleDrawerOpenFatherInformation = () => {
    setOpenFatherInformation(true);
  };
  const handleDrawerCloseFatherInformation = () => {
    setOpenFatherInformation(false);
  };

  //=== Mother Information ===//
  const [openMotherInformation, setOpenMotherInformation] = useState(false);
  const handleDrawerOpenMotherInformation = () => {
    setOpenMotherInformation(true);
  };
  const handleDrawerCloseMotherInformation = () => {
    setOpenMotherInformation(false);
  };

  //=== Mother Information ===//
  const [openAddGuardian, setOpenAddGuardian] = useState(false);
  const handleDrawerOpenAddGuardian = () => {
    setOpenAddGuardian(true);
  };
  const handleDrawerCloseAddGuardian = () => {
    setOpenAddGuardian(false);
  };



  



  const guardianData = [
    {
      Title: "Father’s information",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenFatherInformation,
    },

    {
      Title: "Mother’s information",
      Description: "Done",
      StatusIcon: <CheckCircleRoundedIcon />,
      StatusIconColor: "#0b9444",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenMotherInformation
    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> Guardian Information
          <div className={styles.ApplicationSubText}>At least 2 guardian info is required.</div>
        </div>


        <Button
          label="Add Guardian"
          icon={<AddRoundedIcon />}
          buttonColor="#0450E1"
          border="#0450E1 1px solid"
          color="#fff"
          width=""
          height="32px"
          fontSize="14px"
          onClick={handleDrawerOpenAddGuardian}
        />

      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                />
              ))}
            </div>
          }
        />




      </div>





      {/*=== Add Guardian Drawer */}
      <SideDrawer
        title="Add Guardian"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddGuardian}
        handleDrawerClose={handleDrawerCloseAddGuardian}
        FooterWithoutTab={true}
        body={
          <AddGuardian />
        }
      />


      {/*=== Father Information Drawer */}
      <SideDrawer
        title="Father’s information"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openFatherInformation}
        handleDrawerClose={handleDrawerCloseFatherInformation}
        FooterWithoutTab={true}
        body={
          <FatherInformation />
        }
      />

      {/*=== Mother Information Drawer */}
      <SideDrawer
        title="Mother’s information"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openMotherInformation}
        handleDrawerClose={handleDrawerCloseMotherInformation}
        FooterWithoutTab={true}
        body={
          <MothersInformation />
        }
      />



    </>
  );
};

export default GuardianInformation;
