import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import TextField from '../../../components/Form/TextField/TextField';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '../../../components/Form/Button/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddMore from './components/AddMore/AddMore';





const Medications = (props) => {


  const { id } = useParams();

  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
    setBirthDate,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear, errors } = props;

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    // console.log(dates, 893547685)

    // console.log(54645, birthMonth, birthYear, dates)
    setDateOptions(dates)
  }, [birthMonth, birthYear]);



  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  return (

    <>

      <div className={styles.ApplicationForm}>

        <Grid container spacing={2}>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Does your child regularly take medications?</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="female" control={<Radio />} label="Yes" />
                <FormControlLabel value="male" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Please list medications and describe the regimen:</div>


            <AddMore
              AddMoreButtonlabel="Add More"
              body={
                <div className={styles.FormGroup}>
                  <TextField
                    placeholder="Medicine name"
                  // value={courseData.Id}
                  // handleChange={handleChanges("Id")}
                  />
                  <TextField
                    placeholder="Regimen(dosage, schedule, duration)"
                  // value={courseData.Id}
                  // handleChange={handleChanges("Id")}
                  />
                </div>

              }

            />

          </Grid>

     

        </Grid>
      </div>

    </>
  );
};

export default Medications;
