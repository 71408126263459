import React from 'react'
import Buttons from '../../components/Form/Button/Button'
import { Grid } from '@mui/material';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import tick from "../../assets/imgs/verified.svg"
import logo from "../../assets/imgs/ds-logo.png";
import LogoHeader from './LogoHeader/LogoHeader';
import { useGetSchoolQuery } from '../../redux/features/schools/schoolApi';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';


const AccountVrify = () => {
    const [schoolInfo, setSchoolInfo] = useState({})
    const { schoolId } = useSelector(state => state.auth.user);
    const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId)
    useEffect(() => {
        if (!isLoading && data && !error) {
            setSchoolInfo(data?.body?.data)
        }
    }, [data, error, isLoading])

    return (
        <>
            <div className="SignContainer">
                <LogoHeader />
                <div className="SignContainerInner">
                    <div className="SignForm">
                        <div className="SignFormTopIcon">
                            <img src={tick} alt="Sent Email" />
                        </div>
                        <h1>Account verified successfully!</h1>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12} md={12}>
                <AlrtMessage
                  open={Boolean(alertOptions.msg)}
                  message={alertOptions.msg}
                  severity={alertOptions.type}
                  closeHandler={handleAlertClose}
                />
              </Grid> */}

                            <Grid item xs={12} md={12}>
                                <p className="FormDesText14" style={{ lineHeight: 'normal', fontSize: '1rem', color: '#5E5E62' }}>Thank you for joining <br />
                                    {schoolInfo?.dba}</p>

                                <div className="ContinueOtpButton">
                                    <Buttons
                                        label="Back to homepage"
                                        buttonColor="#5423FF"
                                        border="#5423FF 1px solid"
                                        color="#000"
                                        width="100%"
                                        height="48px"
                                        fontSize="14px"
                                        fontWeight="600"
                                        // disabled={true}
                                        link="/"
                                    // onClick={handleResendEmail}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="SignFormFooter">
                    <SecurityOutlinedIcon />
                    <span>SECURE SSL ENCRYPTION</span>
                </div>
            </div>
        </>
    )
}

export default AccountVrify