import React from "react";
import styles from "./css/application.module.css";

import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import CheckBox from "../../../components/Form/CheckBox/CheckBox";

const SchoolTerms = () => {
  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <>
      <div className={styles.ApplicationDescription}>
        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div className="ApplicationStartContent">
              <p>Bullet List Term</p>
              <ul>
                <li>Registration - $250.00 per student</li>
                <li>Student Fee - $250.00 per family</li>
              </ul>

              <p>Number List Term:</p>
              <ol>
                <li>Registration - $400 (includes application fees)</li>
                <li>Student Fee - $250.00 per family</li>
              </ol>

              <p>
                After-school care, field trips, uniforms and uniform demerits,
                etc. are not included in any of the above fees. The school board
                has the right to change tuition and fees upon adoption of the
                New Year's budget. The school board has the right to change
                tuition and fees upon adoption of the New Year's budget.
              </p>
              <br />
              <p>
                Quran Academy for Young Scholars (QAFYS) is an Islamic
                educational institution unlike any other Islamic school that
                exists in America. It was created to produce influential
                scholars and leaders for the community. With this mission and
                vision in mind, QAFYS has been working since 2017 to change the
                lives of young Muslim students in New York City. QAFYS has two
                campuses with over 300 students range from grades 2nd to 12th.
                Students from 2nd to 5th grade focusing on the memorization of
                the Holy Quran, engage in learning core academic subjects, such
                as English, Math, Science, and Social Studies; above all, gain
                fundamental Islamic knowledge. From 6th grade till 12th,
                students take on our unique and comprehensive Alim Course; while
                huffaz also take on our Ijazah Program; all the while
                maintaining core academic subjects that align with New York
                State standards.
              </p>

              <CheckBox
                label={<div>Checkbox term</div>}
                checked={state.checkedB}
                onChange={handleChangeCheck}
                color="primary"
              />
            </div>
          }
        />
      </div>
    </>
  );
};

export default SchoolTerms;
