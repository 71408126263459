import React, { useEffect, useRef, useState } from "react";
import TableSubHeader from "../../components/Table/TableSubHeader";
import Payments from "./Payments";
import PaymentMethods from "./PaymentMethods";
import {
  useGeneratePaymentIntentMutation,
  useGenerateSetupIntentMutation,
  useGetPaymentsQuery,
  useVerifyMicroDepositPaymentMethodMutation,
  useVerifySetupIntentMutation,
} from "../../redux/features/parentPortal/payments/paymentsApi";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import useProtected from "../../hooks/useProtected";
import { toast } from "react-toastify";
import Payment from "./stripe/payment";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "../../components/Form/TextField/TextField";
import { CurrencyDollar } from "../../assets/imgs/icons/CurrencyDollar";
import Buttons from "../../components/Form/Button/Button";
import { useLazyGetSingleParentDetailsQuery } from "../../redux/features/parentPortal/students/studentsApi";
import { setUserData } from "../../redux/features/authSlice";

import SelectCardTab from "../../components/SelectCardTab/SelectCardTab";
import PriceTextField from "../../components/Form/PriceTextField/PriceTextField";
import FixedValueTextField from "../../components/Form/FixedValueTextField/FixedValueTextField";
import { useGenerateSetupIntentPaymentMethodMutation } from "../../redux/features/parentPortal/payments/paymentsApi";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";

const microDepositeStyle = {
  padding: "0px 24px",
  marginTop: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "14px",
};

const PaymentsPage = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [schoolData, setSchoolData] = useState({});
  const {
    data: schoolInfoData,
    isLoading: schoolIsLoading,
    isError: schoolIsError,
  } = useGetSchoolQuery(user?.schoolId, {
    refetchOnMountOrArgChange: true,
  });

  const { data, isError, isLoading } = useGetPaymentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [verifySetupIntent, verifySetupIntentOptions] =
    useVerifySetupIntentMutation();
  const [generateSetupIntent, generateSetupIntentOptions] =
    useGenerateSetupIntentMutation();
  const [
    verifyMicroDepositPaymentMethod,
    verifyMicroDepositPaymentMethodOptions,
  ] = useVerifyMicroDepositPaymentMethodMutation();
  const [
    generateSetupIntentPaymentMethod,
    generateSetupIntentPaymentMethodOptions,
  ] = useGenerateSetupIntentPaymentMethodMutation();

  const [
    getGroupDetailsLazy,
    {
      data: lazyData,
      isLoading: lazyIsLoading,
      isError: lazyIsError,
      error: lazyError,
    },
  ] = useLazyGetSingleParentDetailsQuery(user?.userUniqueID, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (schoolInfoData && !schoolIsError && !schoolIsLoading) {
      setSchoolData(schoolInfoData?.body?.data);
    }
  }, [schoolInfoData, schoolIsLoading, schoolIsError]);

  useProtected(lazyError);
  useProtected(verifySetupIntentOptions.error);
  useProtected(generateSetupIntentOptions.error);
  useProtected(verifyMicroDepositPaymentMethodOptions.error);
  useProtected(generateSetupIntentPaymentMethodOptions.error);

  const initalStateOfMicroDeposit = {
    paymentMethodId: "",
    amount1: "",
    amount2: "",
    isMicroDeposit: true,
    descriptor_code: "",
  };

  const initalErrorStateOfMicroDeposit = {
    amount1: "",
    amount2: "",
    descriptor_code: "",
  };

  const [open, setOpen] = useState(true);
  const [payments, setPayments] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [microError, setMicroErro] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [OpenDrawerAddNew, setOpenAddNew] = useState(false);
  const [foundRecurring, setFoundRecurring] = useState(false);
  const [methodVerified, setMethodVerified] = useState(false);
  const [openPaymentDrawer, setOpenPayment] = useState(false);
  const [setupIntentData, setSetupIntentData] = useState(null);
  const [authorizedCheck, setAuthorizedCheck] = useState(false);
  const [authorizedFired, setAuthorizedFired] = useState(false);
  const [microErrorStatus, setMicroErrorStatus] = useState(false);
  const [paymentMethodsData, setPaymentMethodsData] = useState([]);
  const [microDepositDrawer, setMicroDepositDrawer] = useState(false);
  const [paymentSetUpDrawer, setPaymentSetUpDrawer] = useState(false);
  const [showSetupTopSticker, setShowSetupTopSticker] = useState(false);
  const [paymentMethodAddMode, setPaymentMethodAddMode] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(false);
  const [showVerifiedTopSticker, setShowVerifiedTopSticker] = useState(false);
  const [microDeposit, setMicroDeposit] = useState(initalStateOfMicroDeposit);
  const [authorizedFiredPaymentMethod, setAuthorizedFiredPaymentMethod] =
    useState(false);
  const [authorizedCheckPaymentMethod, setAuthorizedCheckPaymentMethod] =
    useState(false);
  const [microDepositErrors, setMicroDepositErrors] = useState(
    initalErrorStateOfMicroDeposit
  );

  useEffect(() => {
    console.log(defaultPaymentMethod, authorizedCheckPaymentMethod);
  }, [defaultPaymentMethod, authorizedCheckPaymentMethod]);

  const handleDrawerOpenAddNew = () => {
    setOpenAddNew(true);
    setupPaymentMethodHandler();
  };
  const CloseDrawerAddNew = () => {
    setOpenAddNew(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setMicroDeposit({ ...microDeposit, isMicroDeposit: newValue == 0 });
    setTabValue(newValue);
  };

  useEffect(() => {
    if (data?.body?.data?.length > 0 && !isError && !isLoading) {
      setPayments(data?.body?.data);
    }
  }, [data, isError, isLoading]);

  const tabLabel = [
    {
      label: "Payments",
    },
    {
      label: "Payment Methods",
    },
  ];

  const verifyMicroDepositsHandler = async (id) => {
    setMicroDeposit({
      ...microDeposit,
      paymentMethodId: id,
      isMicroDeposit: tabValue == 0,
    });
    setMicroDepositDrawer(true);
  };

  const tabPanel = [
    {
      id: 0,
      panel: (
        <Payments
          payments={payments}
          setFoundRecurring={setFoundRecurring}
          setupIntentData={setupIntentData}
        />
      ),
    },
    {
      id: 1,
      panel: (
        <PaymentMethods
          OpenDrawer={OpenDrawerAddNew}
          CloseDrawer={CloseDrawerAddNew}
          methodVerified={methodVerified}
          setupIntentData={setupIntentData}
          setMethodVerified={setMethodVerified}
          defaultPaymentMethod={defaultPaymentMethod}
          paymentMethodAddMode={paymentMethodAddMode}
          setPaymentMethodsData={setPaymentMethodsData}
          setPaymentMethodAddMode={setPaymentMethodAddMode}
          setDefaultPaymentMethod={setDefaultPaymentMethod}
          verifyMicroDepositsHandler={verifyMicroDepositsHandler}
          authorizedCheckPaymentMethod={authorizedCheckPaymentMethod}
          authorizedFiredPaymentMethod={authorizedFiredPaymentMethod}
          setAuthorizedCheckPaymentMethod={setAuthorizedCheckPaymentMethod}
          setAuthorizedFiredPaymentMethod={setAuthorizedFiredPaymentMethod}
        />
      ),
    },
  ];

  const setupPaymentHandler = async () => {
    setPaymentSetUpDrawer(true);
    let result = await generateSetupIntent();

    if (result?.data && result?.data?.error == false) {
      setSetupIntentData(result?.data?.body?.data);
    }
  };

  // Create a ref to pass to the GrandchildComponent
  const grandchildRef = useRef();

  const callGrandchildMethod = async () => {
    if (!authorizedCheck) {
      toast.error("Please confirm check");
      return false;
    }
    if (grandchildRef.current) {
      grandchildRef.current.handleClick();
    }
  };

  const refreshUser = async () => {
    let result = await getGroupDetailsLazy(user?.userUniqueID);
    if (result?.data?.body?.data) {
      let userInfo = result?.data?.body?.data;
      dispatch(setUserData(userInfo));
      localStorage.setItem("user", JSON.stringify(userInfo));
    }
  };
  const authorizeHandler = async () => {};

  const authorizeCheckHandler = (e) => {
    setAuthorizedCheck(e.target.checked);
  };

  useEffect(() => {
    if (!user?.paymentMethodId || user?.paymentMethodId == "") {
      setShowSetupTopSticker(true);
    }
    if (
      !user?.bankAccountVerified &&
      user?.paymentMethodId &&
      user?.paymentMethodId != ""
    ) {
      setShowVerifiedTopSticker(true);
    }
  }, [user]);

  const handleDrawerCloseMicroDeposit = () => {
    setMicroDepositDrawer(false);
    setMicroErro(false);
    // setSaveAccountSuccess(false);
    setMicroErrorStatus(false);
    setIsSaving(false);
    setMicroDeposit(initalStateOfMicroDeposit);
    setMicroDepositErrors(initalErrorStateOfMicroDeposit);
  };

  const handleChanges = (fieldName) => (event) => {
    const fieldData =
      fieldName == "descriptor_code"
        ? event.target.value.toUpperCase()
        : event.target.value;
    setMicroDeposit({ ...microDeposit, [fieldName]: fieldData });
  };

  const isValidMicroDeposit = async () => {
    let error = 0;
    let errorObejct = { ...microDepositErrors };

    if (microDeposit?.isMicroDeposit) {
      if (microDeposit?.amount1 == "" || microDeposit?.amount1 == 0) {
        error++;
        errorObejct.amount1 = "Required";
      } else {
        errorObejct.amount1 = "";
      }

      if (microDeposit?.amount2 == "" || microDeposit?.amount2 == 0) {
        error++;
        errorObejct.amount2 = "Required";
      } else {
        errorObejct.amount2 = "";
      }
    } else {
      if (microDeposit?.descriptor_code === "") {
        error++;
        errorObejct.descriptor_code = "Required";
      } else {
        errorObejct.descriptor_code = "";
      }

      if (microDeposit?.descriptor_code?.length < 4) {
        error++;
        errorObejct.descriptor_code = "Enter the correct descriptor code";
      } else {
        errorObejct.descriptor_code = "";
      }
    }

    setMicroDepositErrors({ ...microDepositErrors, ...errorObejct });
    return error > 0 ? false : true;
  };

  const processData = async () => {
    let newMicroDeposit = { ...microDeposit };

    if (microDeposit?.isMicroDeposit) {
      newMicroDeposit.descriptor_code = "";
    } else {
      newMicroDeposit.descriptor_code = `SM${microDeposit?.descriptor_code}`;
      newMicroDeposit.amount1 = "";
      newMicroDeposit.amount2 = "";
    }
    return newMicroDeposit;
  };

  //* ====================== save data ===============================  *//
  const handleVerifyMicroDeposit = async () => {
    console.log("popop");

    const isValid = await isValidMicroDeposit();
    if (!isValid) {
      return false;
    }

    let data = await processData();

    try {
      const result = await verifyMicroDepositPaymentMethod(data);

      if (!result?.data?.error && result?.data?.body?.data) {
        handleDrawerCloseMicroDeposit();
        toast.success("ACH verified");
        setMicroDeposit(initalStateOfMicroDeposit);
        setMethodVerified(true);
      } else {
        if (!microDeposit?.isMicroDeposit && result?.data?.statusCode == 400) {
          setMicroDepositErrors({
            ...microDepositErrors,
            descriptor_code: result?.data?.message,
          });
        } else if (
          microDeposit?.isMicroDeposit &&
          result?.data?.statusCode == 400
        ) {
          setMicroDepositErrors({
            ...microDepositErrors,
            amount1: result?.data?.message,
            amount2: result?.data?.message,
          });
          toast.error(
            "Ensure that you have entered the correct deposit amount"
          );
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      console.log(error?.message);
    }
  };
  //* ====================== save data ===============================  *//

  const onChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onChangeMicroDeposit = (val, field) => {
    if (field == "amount1" || field == "amount2") {
      val = 1 <= val ? `0.${val}` : val;
    } else if (field == "descriptor_code") {
      val = val?.toUpperCase();
    }
    setMicroDeposit({ ...microDeposit, [field]: val });
  };

  useEffect(() => {
    console.log("microDeposit", microDeposit);
  }, [microDeposit]);

  const tabsData = [
    {
      title: "Verify micro-deposits",
    },

    {
      title: "Verify descriptor code",
    },
  ];

  const panelDate = [
    {
      id: 0,
      panel: (
        <>
          <div>
            <PriceTextField
              label="Micro-deposit 1*"
              cout={4}
              values={microDeposit?.amount1}
              error={microDepositErrors?.amount1 != ""}
              handleChange={(e) =>
                onChangeMicroDeposit(e.target.value, "amount1")
              }
            />
          </div>

          <div style={{ marginTop: "24px" }}>
            <PriceTextField
              label="Micro-deposit 2*"
              cout={4}
              values={microDeposit?.amount2}
              error={microDepositErrors?.amount2 != ""}
              handleChange={(e) =>
                onChangeMicroDeposit(e.target.value, "amount2")
              }
            />
          </div>
        </>
      ),
    },

    {
      id: 1,
      panel: (
        <>
          <FixedValueTextField
            label="Descriptor code"
            FixedValueLabel="SM"
            cout={4}
            required={true}
            helperText={microDepositErrors?.descriptor_code}
            error={microDepositErrors?.descriptor_code}
            startAdornment={
              <>
                <CurrencyDollar height="18px" /> <p>.</p>
              </>
            }
            value={microDeposit?.descriptor_code}
            handleOnChange={(e) =>
              onChangeMicroDeposit(e.target.value, "descriptor_code")
            }
          />
        </>
      ),
    },
  ];

  const setupPaymentMethodHandler = async () => {
    // setPaymentSetUpDrawer(true);
    setPaymentMethodAddMode(true);
    let result = await generateSetupIntentPaymentMethod();

    if (result?.data && result?.data?.error == false) {
      setSetupIntentData(result?.data?.body?.data);
    }
  };

  return (
    <div>
      {showSetupTopSticker && (
        <div style={{ marginBottom: "12px" }}>
          <Collapse in={open}>
            <Alert
              icon={false}
              severity="error"
              sx={{
                borderRadius: open ? "8px" : "0px",
                color: "#001233",
                fontSize: "14px",
                fontWeight: 400,
              }}
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  sx={{ color: "#BA1A1A", fontSize: "14px", fontWeight: 700 }}
                  onClick={() => setupPaymentHandler()}
                >
                  Setup Payment
                </Button>
              }
            >
              Set up an ACH payment method in order to make recurring payments.
            </Alert>
          </Collapse>
        </div>
      )}

      {showVerifiedTopSticker && (
        <div style={{ marginBottom: "12px" }}>
          <Collapse in={open}>
            <Alert
              icon={false}
              severity="error"
              sx={{
                borderRadius: open ? "8px" : "0px",
                color: "#001233",
                fontSize: "14px",
                fontWeight: 400,
              }}
              action={
                <Button
                  aria-label="close"
                  color="inherit"
                  size="small"
                  sx={{ color: "#BA1A1A", fontSize: "14px", fontWeight: 700 }}
                  onClick={() => verifyMicroDepositsHandler()}
                >
                  Verify
                </Button>
              }
            >
              Verify micro-deposits
            </Alert>
          </Collapse>
        </div>
      )}
      <div className="paymentsTableContainer">
        <TableSubHeader
          HeaderTitle="Payments"
          Tabs={true}
          tabLabel={tabLabel}
          tabPanel={tabPanel}
          TabValue={value}
          TabHandleChange={handleChange}
          Filter={false}
          AddButton={value === 1 ? true : false}
          AddButtonLabel="NEW"
          addButtonDisabled={paymentMethodsData?.length > 2}
          AddButtonColor={
            paymentMethodsData?.length > 2 ? "#0012331F" : "#0450E1"
          }
          AddButtonTextColor={
            paymentMethodsData?.length > 2 ? "#00123361" : "#FFFFFF"
          }
          AddOnClick={handleDrawerOpenAddNew}
          Border={true}
        />
      </div>

      {/* ======== drawer =========== */}
      <SideDrawer
        title="Setup Payment Method"
        headerTextColor="##001233"
        backgroundColor="#fff"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={paymentSetUpDrawer}
        handleDrawerClose={() => setPaymentSetUpDrawer(false)}
        FooterWithoutTab={false}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={false}
        SubDrawerWithOutTab={false}
        body={
          <>
            {setupIntentData?.client_secret && (
              <Payment
                // paymentInfo={paymentInfo}
                // setPaymentInfo={setPaymentInfo}
                // handleDrawerClosePayment={handleDrawerClosePayment}
                stripePromise={stripePromise}
                setStripePromise={setStripePromise}
                clientSecret={setupIntentData?.client_secret}
                authorizedFired={authorizedFired}
                setAuthorizedFired={setAuthorizedFired}
                ref={grandchildRef}
                setPaymentSetUpDrawer={setPaymentSetUpDrawer}
                setShowSetupTopSticker={setShowSetupTopSticker}
                refreshUser={refreshUser}
                // setClientSecret={setClientSecret}
                // paymentSuccessful={paymentSuccessful}
                // setPaymentSuccessful={setPaymentSuccessful}
              />
            )}
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  sx={{ padding: "2px 0px 0px 0px", marginRight: "4px" }}
                  onChange={(e) => authorizeCheckHandler(e)}
                  checked={authorizedCheck}
                />
              }
              label={`I authorize ${schoolData?.dba} to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits`}
              labelPlacement="end"
              sx={{ marginLeft: "4px", alignItems: "flex-start" }}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                marginTop: "12px",
                padding: "10px 0",
                fontWeight: 700,
                borderRadius: "8px",
                boxShadow: "none",
                background: "#0450E1",
                "&:hover": {
                  background: "#0450E1",
                  boxShadow: "none",
                },
              }}
              // onClick={(e) => authorizeHandler()}
              onClick={(e) => callGrandchildMethod()}
            >
              Authorize
            </Button>
          </>
        }
      />

      {/* === Setup Payment Drawer === */}
      <SideDrawer
        title="Verify Payment Method"
        cancelButtonLabel="CANCEL"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={microDepositDrawer}
        handleDrawerClose={handleDrawerCloseMicroDeposit}
        body={
          <div>
            <Alert severity="warning" icon={<InfoOutlinedIcon />}>
              To verify account, enter the two micro-deposits or enter the
              descriptor code below.
              <br />
              If you don’t see them within 1-3 business days, please contact
              school admin.
            </Alert>

            <div style={{ marginTop: "24px" }}>
              <SelectCardTab
                tabsData={tabsData}
                panelDate={panelDate}
                value={tabValue}
                onChange={handleTabChange}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <Buttons
                label="Verify"
                buttonColor="#0450E1"
                border="#0450E1 1px solid"
                color="#fff"
                width="100%"
                height="48px"
                fontSize="14px"
                fontWeight="700"
                onClick={handleVerifyMicroDeposit}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PaymentsPage;
