import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import './Datefield.css';

function Datefield(props) {
  const {
    label,
    required,
    validation,
    error,
    placeholder,
    value,
    handleChange,
    disablePast,
    helperText,
    disabled,
    dateFormat = "MM/DD/YYYY"
  } = props;

  const [validationError, setValidationError] = useState(false);
  const [validationText, setValidationText] = useState('');

  useEffect(() => {
    if (required || validation) {
      console.log(value);

      if (!value) {
        setValidationError(true);
        setValidationText('Required');
      } else {
        setValidationError(false);
        setValidationText('');
      }
    }
  }, [value]);

  return (
    <div className="Datefield">
      {props.label && (
        <div className="FieldLabel">
          {label} {validation && <span>*</span>}
        </div>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DesktopDatePicker defaultValue={dayjs('2022-04-17')} /> */}
        <DesktopDatePicker
          icon={<AcUnitIcon />}
          label=""
          inputFormat={props.placeholder}
          format={dateFormat}
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} error={error || validationError}
          />}
          slotProps={{
            textField: {
              helperText: `${helperText || validationText}`
            }
          }}
          disablePast={disablePast ? true : false}
          disabled={disabled}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Datefield;
