import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';

import TextField from '../../../components/Form/TextField/TextField';
import Datefield from '../../../components/Form/Datefield/Datefield';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';

import { getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


const HomeLanguageQuestionnaire = (props) => {


  const { id } = useParams();

  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
    setBirthDate,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear, errors } = props;

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    // console.log(dates, 893547685)

    // console.log(54645, birthMonth, birthYear, dates)
    setDateOptions(dates)
  }, [birthMonth, birthYear]);



  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };


  return (

    <>

      <div className={styles.ApplicationForm}>

        <Grid container spacing={2}>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.ParagraghText}>
              <p>Dear Parent or Person in Parental Relation:</p>
              <p>In order to provide your child with the best possible education, we need to determine how well he or she understands, speaks, reads and writes in English, as well as prior school and personal history.
                Please complete the sections below entitled Language Background and Educational History.
                Your assistance in answering these questions is greatly appreciated.</p>
              <p>Thank you</p>


            </div>

          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What language(s) is(are) spoken in the student home or residence?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What was the first language your child learned? "
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What is the Home Language of each parent/guardian?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What language(s) does your child understand?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What language(s) does your child speak?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="What language(s) does your child write?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="Indicate the total number of years that your child has been enrolled in school: "
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Do you think your child may have any difficulties or conditions that affect his or her ability to understand, speak, read or write in English or any other language?</div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="female" control={<Radio />} label="Yes" />
                <FormControlLabel value="male" control={<Radio />} label="No" />
                <FormControlLabel value="male" control={<Radio />} label="Not Sure" />
              </RadioGroup>
            </FormControl>
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Has your child ever been referred for a special education evaluation in the past?</div>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="female" control={<Radio />} label="Yes" />
                <FormControlLabel value="male" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="Is there anything else you think is important for the school to know about your child (e.g., special talents, health concerns, etc.)?"
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FormSingleField}>
              <TextField
                LabelStatus={true}
                Fieldlabel="In what language(s) would you like to receive information from the school? "
                placeholder="Answer"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />
            </div>
          </Grid>



          <Grid item xs={12} sm={5} md={5}>


          </Grid>


          <Grid item xs={12} sm={7} md={7}>
            <div className={styles.FieldLabel}>Parent/Guardian Signature</div>
            <div className={styles.FormGroup}>
              <TextField
                placeholder="Parent/Guardian Signature"
              // value={courseData.Id}
              // handleChange={handleChanges("Id")}
              />

              <div className={styles.PhoneField} >
                <Datefield
                  placeholder="Signed Date"

                />
              </div>

            </div>

          </Grid>

        </Grid>
      </div>

    </>
  );
};

export default HomeLanguageQuestionnaire;
