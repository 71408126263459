import React from "react";
import styles from './css/application.module.css';

import CircleIcon from '@mui/icons-material/Circle';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';

const Start = () => {
  return (

    <>
      <div className={styles.ApplicationDate}>
        New Application <CircleIcon /> 2024-2025 Academic year

      </div>

      <div className={styles.ApplicationDescription}>

        <div className={styles.ApplicationStartTitle}>Qurʾān Academy for Young Scholars - Brooklyn Campus</div>
        <div className={styles.ApplicationChangeSchool}>Change School</div>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div className={styles.ApplicationStartContent}>
              <p>Please fill out the fields on each page as completely as possible and press Continue to proceed to the final submission page.</p>
              <p>Your changes are saved automatically as you continue through the pages. You may go back to review and correct entries using the Back button or the page buttons above</p>
              <p>The system will alert you if a required field is missed. To save time, some of the fields will be pre-populated from our current records. Other fields will require a fresh entry, such as all phone numbers, to ensure that our records are up-to-date, accurate, and consistently formatted. You must complete each section before continuing to the next page.</p>
              <p>Please fill out the fields on each page as completely as possible and press Continue to proceed to the final submission page.</p>
              <p>Your changes are saved automatically as you continue through the pages. You may go back to review and correct entries using the Back button or the page buttons above</p>
              <p>The system will alert you if a required field is missed. To save time, some of the fields will be pre-populated from our current records. Other fields will require a fresh entry, such as all phone numbers, to ensure that our records are up-to-date, accurate, and consistently formatted. You must complete each section before continuing to the next page.</p>
              <p>Please fill out the fields on each page as completely as possible and press Continue to proceed to the final submission page.</p>
              <p>Your changes are saved automatically as you continue through the pages. You may go back to review and correct entries using the Back button or the page buttons above</p>
              <p>The system will alert you if a required field is missed. To save time, some of the fields will be pre-populated from our current records. Other fields will require a fresh entry, such as all phone numbers, to ensure that our records are up-to-date, accurate, and consistently formatted. You must complete each section before continuing to the next page.</p>
              <p>Please fill out the fields on each page as completely as possible and press Continue to proceed to the final submission page.</p>
              <p>Your changes are saved automatically as you continue through the pages. You may go back to review and correct entries using the Back button or the page buttons above</p>
              <p>The system will alert you if a required field is missed. To save time, some of the fields will be pre-populated from our current records. Other fields will require a fresh entry, such as all phone numbers, to ensure that our records are up-to-date, accurate, and consistently formatted. You must complete each section before continuing to the next page.</p>
            </div>
          }
        />




      </div>

    </>
  );
};

export default Start;
