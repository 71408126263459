import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../../../components/Form/Button/Button';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import EditFamilyMembers from './EditFamilyMembers';
import AddSibling from './AddSibling';
import AddHouseholdIncome from './AddHouseholdIncome';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';


const Family = (props) => {

  //=== Family Members Drawer===//
  const [openFamilyMembers, setOpenFamilyMembers] = useState(false);
  const handleDrawerOpenFamilyMembers = () => {
    setOpenFamilyMembers(true);
  };
  const handleDrawerCloseFamilyMembers = () => {
    setOpenFamilyMembers(false);
  };

  //=== MAdd Sibling Drawer===//
  const [openAddSibling, setOpenAddSibling] = useState(false);
  const handleDrawerOpenAddSibling = () => {
    setOpenAddSibling(true);
  };
  const handleDrawerCloseAddSibling = () => {
    setOpenAddSibling(false);
  };

  //=== Mother Information ===//
  const [openAddHouseholdIncome, setOpenAddHouseholdIncome] = useState(false);
  const handleDrawerOpenAddHouseholdIncome = () => {
    setOpenAddHouseholdIncome(true);
  };
  const handleDrawerCloseAddHouseholdIncome = () => {
    setOpenAddHouseholdIncome(false);
  };







  const guardianData = [
    {
      Title: "Family members",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,

    },

    {
      Title: "Sibling(s)",
      Description: "Click on “+ ADD SIBLING” if the child has any sibling(s)",
      ButtonLabel: "Add Sibling",
      ButtonIcon: <AddRoundedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenAddSibling,

      CardBody: 
      <>
      <div className={styles.ListingContainer}>
        <div className={styles.BoldText}>Shayan Mahmud</div>
        <div className={styles.SubText}>Brother</div>
        <div className={styles.ListingActionButton}>
          <Button variant="text"><svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg></Button>
          <Button variant="text"><CloseRoundedIcon /></Button>
        </div>
      </div>

      <div className={styles.ListingContainer}>
        <div className={styles.BoldText}>Sakhina Mahmud</div>
        <div className={styles.SubText}>Sister</div>
        <div className={styles.ListingActionButton}>
          <Button variant="text"><svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg></Button>
          <Button variant="text"><CloseRoundedIcon /></Button>
        </div>
      </div>

      </>,

    },

    {
      Title: "Household income",
      Description: "Click on “+ ADD INCOME” to add household income",
      ButtonLabel: "Add Income",
      ButtonIcon: <AddRoundedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenAddHouseholdIncome,

    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> Family</div>
      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                  CardBody={item.CardBody}
                />
              ))}
            </div>
          }
        />




      </div>





      {/*=== Family Members Drawer */}
      <SideDrawer
        title="Family Members"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openFamilyMembers}
        handleDrawerClose={handleDrawerCloseFamilyMembers}
        FooterWithoutTab={true}
        body={
          <EditFamilyMembers />
        }
      />


      {/*=== Add Sibling Drawer */}
      <SideDrawer
        title="Add Sibling"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddSibling}
        handleDrawerClose={handleDrawerCloseAddSibling}
        FooterWithoutTab={true}
        body={
          <AddSibling />
        }
      />

      {/*=== Household Income Drawer */}
      <SideDrawer
        title="Household Income"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddHouseholdIncome}
        handleDrawerClose={handleDrawerCloseAddHouseholdIncome}
        FooterWithoutTab={true}
        body={
          <AddHouseholdIncome />
        }
      />



    </>
  );
};

export default Family;
