import React from 'react';

import Table from './Table';
import tableImg from '../../assets/imgs/tableImg.png';
import Visa from '../../assets/imgs/Visa.png';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';



function TableData(props) {
  const onClick = () => {
    alert('onclick working fine!');
  };

  const [selectedValue, setSelectedValue] = React.useState('a');
  const handleChangeRedio = (event) => {
    setSelectedValue(event.target.value);
  };

  const [age, setAge] = React.useState('');
  const SelectHandleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: 'Value',
      value: 'Value',
    },
  ];

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Image W/Primary Text', width: '200px', textAlign: 'left', },
    { id: 'calories', numeric: true, disablePadding: false, label: 'Primary Text', width: '150px', textAlign: 'left', },
    { id: 'fat', numeric: true, disablePadding: false, label: 'Primary Text W/Subtitle', width: '200px', textAlign: 'left', },
    { id: 'carbs', numeric: true, disablePadding: false, label: 'Primary Text W/Icon & Chip', width: '300px', },
    { id: 'protein', numeric: true, disablePadding: false, label: 'Secondary Text', width: '200px', },
    { id: 'Secondary', numeric: true, disablePadding: false, label: 'Secondary Text W/Chip', width: '300px', },
    { id: 'dateday', numeric: true, disablePadding: false, label: 'Date', width: '180px', },
    { id: 'datetime', numeric: true, disablePadding: false, label: 'Date W/Day', width: '180px', },
    { id: 'date', numeric: true, disablePadding: false, label: 'Date W/Time', width: '180px', },
    { id: 'Icon', numeric: true, disablePadding: false, label: 'Icon', width: '100px', },
    { id: 'Checkbox', numeric: true, disablePadding: false, label: 'Checkbox', width: '100px', },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Completed', width: '160px', },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip n Progress', width: '160px', },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Enrolled', width: '160px', },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Enrolled', width: '160px', },
    { id: 'Chip', numeric: true, disablePadding: false, label: 'Chip Online', width: '160px', },
    { id: 'Button', numeric: true, disablePadding: false, label: 'Buttons', width: '160px', },
    { id: 'ButtonWithIcon', numeric: true, disablePadding: false, label: 'Buttons with icon', width: '180px', },
    { id: 'ButtonDisabled', numeric: true, disablePadding: false, label: 'Button Disabled', width: '200px', },
    { d: 'ButtonFilld', numeric: true, disablePadding: false, label: 'Button Filld', width: '200px', },
    { id: 'Details', numeric: true, disablePadding: false, label: 'Details', width: '150px', },
    { id: 'Actions', numeric: true, disablePadding: false, label: 'Actions', width: '150px', },
  ];

  const rows = [
    {
      cell: [
        {
          img: tableImg, ImgSize: '60%', imgStatus: true, imgText: 'Name', align: 'left', textLink: '#', TextLight: true,
          Radio: true, handleChangeRedio: handleChangeRedio, RedioName: 'rizwan', RedioValue: 'a', selectedValue: selectedValue === 'a',
        },

        { img: tableImg, imgStatus: true, imgText: 'Name', align: 'left' },
        {
          icon: <FileDownloadOutlinedIcon />, iconLink: '#', iconStatus: true, textBold: 'Text Bold', textLight: 'History of Islamic Finance',
          textLink: '#', TextLight: true,
        },
        { textBold: 'Text Bold' },
        { textBold: 'Text Bold', subtitle: 'History of Islamic Finance', subtitle: true, },
        { cardicon: <img src={Visa} />, number: 'Visa - 4242', align: 'left', chip: 'PRIMARY', chipStatus: true, },
        { textBold: 'Diploma' },
        { linkText: 'Islamic Studies', chip: 'PRIMARY', chipStatus: true, linkTextStatus: true, link: '#', },
        { date: 'Jan 14, 2022' },
        { date: 'Wed, Dec 14, 2022' },
        { date: 'Dec 13, 2022, 10:00 AM' },
        { icon: <DownloadForOfflineOutlinedIcon />, iconLink: '#', iconStatus: true, },
        { Check: true },
        { Status: true, background: '#E1F2E8', color: '#0B9444', linebackground: "#41AB37", statusLine: true, label: 'Green', },
        { Status: true, background: '#EDF7EA', color: '#66BD50', linebackground: "#41AB37", statusLine: true, label: 'Light Green', },
        { Status: true, background: '#FFF5E6', color: '#FFAE35', linebackground: "#979DAC", statusLine: true, label: 'Yellow', },
        { Status: true, background: '#EEECFF', color: '#7266FB', linebackground: "#979DAC", statusLine: true, label: 'Purple', },
        { Status: true, background: '#FCE7E9', color: '#E63946', linebackground: "#BA1A1A", statusLine: true, label: 'Red', },
        { Status: true, background: '#EDEDEF', color: '#676C7B', linebackground: "#979DAC ", statusLine: true, label: 'Grey', },
        { Status: true, background: '#E1EAFB', color: '#0450E1', linebackground: "#979DAC", statusLine: true, label: 'Blue', },
        {
          Button: true, buttonLabel: 'Go To Course', buttonColor: '#FFFFFF', buttonBorder: '#E4E8EC 1px solid',
          buttonTextcolor: '#5423FF', buttonLink: '#',
        },
        {
          Button: true, buttonLabel: 'Download', buttonColor: '#FFFFFF', buttonBorder: '#E4E8EC 1px solid',
          buttonTextcolor: '#5423FF', buttonLink: '#', buttonTarget: '_blank', iconStatus: true, buttonIcon: <DownloadForOfflineOutlinedIcon />,
        },
        {
          Button: true, buttonLabel: 'Button Disabled', buttonColor: '#FFFFFF', buttonBorder: '#E4E8EC 1px solid', buttonTextcolor: '#5423FF',
          buttonLink: '#', buttonTarget: '_blank', disabled: 'disabled',
        },
        {
          Button: true, buttonLabel: 'Button Filled', buttonColor: '#5423FF', buttonBorder: '#5423FF 1px solid', buttonTextcolor: '#fff',
          buttonLink: '#', buttonTarget: '_blank', TooltipText: 'When class starts you will be notified and you will be able to join the class.',
        },
        { Button: true, buttonLabel: 'Details', buttonTextcolor: '#5423FF', buttonLink: '#', buttonTarget: '_blank', },
        { ActionButton: true, ActionButtonLabel: 'Action', },
        { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },
        { SelectFiled: true, options: options, onClick: SelectHandleChanges, },
      ],

      //======= Action Date Start===== //
      action: [
        { label: 'View', onClick: onClick },
        { label: 'Edit', onClick: onClick },
        { label: 'Delete', onClick: onClick },
      ],
      //======= Action Date End===== //
    },
  ];

  return (
    <div className="TableSection">
      <Table
        title="Course"
        tableHeader={true}
        AddButton={true}
        addLabel="New User"
        link="add-courses"
        icon={<AddRoundedIcon />}
        headCells={headCells}
        rows={rows}
        Checkbox={true}
        footer={true}
        innerHeader={true}
        innerTitle="Table Name"
        AddButtonInner={true}
        addLabelInner="Add Payment"
        InnerButtonColor="#5423FF"
        InnerButtonTextColor="#fff"
        InnderButtonIcon={<AddRoundedIcon />}
        //onClickInnerButton={onClickInnerButton}
        showSearchBox={true}
        AddButtonOutLine={true}
        //onClickOutlineButton={handleOpenAttendanceEntry}
        OutLineButtonLabel="Add entry"
        CheckboxDeleteModal={true}
        CheckboxDelete={true}
        DeleteText="REMOVE FROM GROUP"
        //DeleteButtonOnclick={DeleteButtonOnclick}
        RowsPerPage={10}

        emptyTableTitle="No users added yet"
        emptyTableNote="No items available"
        btnLabel="ADD USERS"
        showButton={true}
        btnLink="/invited-users"
        //onClickEmptyCard={handleOpenAttendanceEntry}

        FilterButtonDrawer={true}
        //onClickFilterButton={onClickFilterButton}

        ExportButtonInner={true}
      //OnclickExport={OnclickExport}

      />
    </div>
  );
}

export default TableData;
