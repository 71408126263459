import { forwardRef, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import useProtected from "../../../hooks/useProtected";
import {
  useLazyGetStripeConfigQuery,
  useGetStripePaymentSessionMutation,
} from "./stripeApi";
import { toast } from "react-toastify";
import {
  useGeneratePaymentIntentMutation,
  useUpdatePaymentStatusMutation,
} from "../../../redux/features/parentPortal/payments/paymentsApi";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export default forwardRef(function Payment(props, ref) {
  const {
    refreshUser,
    paymentInfo,
    clientSecret,
    stripePromise,
    setPaymentInfo,
    setClientSecret,
    authorizedFired,
    setStripePromise,
    paymentSuccessful,
    setAuthorizedFired,
    paymentMethodAddMode,
    setPaymentSuccessful,
    defaultPaymentMethod,
    setPaymentSetUpDrawer,
    setShowSetupTopSticker,
    setPaymentMethodAddMode,
    setDefaultPaymentMethod,
    handleDrawerClosePayment,
    setAuthorizedCheckPaymentMethod
  } = props;

  // const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [generatePaymentIntent, generatePaymentIntentOptions] =
    useGeneratePaymentIntentMutation();
  const [updatePaymentStatus, updatePaymentStatusOptions] =
    useUpdatePaymentStatusMutation();

  useProtected(updatePaymentStatusOptions.error);
  useProtected(generatePaymentIntentOptions.error);
  // const userData = useSelector((state) => state.auth.user);

  //async function to get stripe config
  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY));
  }, []);

  console.log(clientSecret,stripePromise)
  return (
    <>
      {clientSecret && stripePromise && (
        <Elements
          key={clientSecret}
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <CheckoutForm
            ref={ref}
            refreshUser={refreshUser}
            clientSecret={clientSecret}
            authorizedFired={authorizedFired}
            paymentSuccessful={paymentSuccessful}
            setAuthorizedFired={setAuthorizedFired}
            setPaymentSuccessful={setPaymentSuccessful}
            paymentMethodAddMode={paymentMethodAddMode}
            defaultPaymentMethod={defaultPaymentMethod}
            setPaymentSetUpDrawer={setPaymentSetUpDrawer}
            setShowSetupTopSticker={setShowSetupTopSticker}
            setPaymentMethodAddMode={setPaymentMethodAddMode}
            setDefaultPaymentMethod={setDefaultPaymentMethod}
            handleDrawerClosePayment={handleDrawerClosePayment}
            setAuthorizedCheckPaymentMethod={setAuthorizedCheckPaymentMethod}
          />
        </Elements>
      )}
      <br />

      {/* <SideDrawer
        title="Confirmation"
        headerTextColor="#fff"
        backgroundColor="#5423FF"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={paymentSuccessful}
        handleDrawerClose={() => {
          setPaymentSuccessful(false);
          handleDrawerClosePayment();
        }}
        FooterWithoutTab={false}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={false}
        SubDrawerWithOutTab={false}
        body={
          <>
            <div className="successfulPaymentDrawerBody">
              <div className="tickCircleBorder">
                <DoneRoundedIcon sx={{ color: "#41ab37" }} fontSize="large" />
              </div>
              <div className="successfulTextContainer">
                <h4>Payment Complete</h4>
                <p>
                  Thank you, your payment has been successful. A confirmation
                  email has been sent to <b>{userData?.email || "N/A"}</b>
                </p>
                <Button
                  variant="contained"
                  sx={{
                    background: "#0450E1",
                    fontWeight: "700",
                    borderRadius: "8px",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      background: "#0450E1",
                    },
                  }}
                  onClick={() => {
                    setPaymentSuccessful(false);
                    handleDrawerClosePayment();
                  }}
                >
                  DONE
                </Button>
              </div>
            </div>
          </>
        }
      /> */}
    </>
  );
})

// export default Payment;
