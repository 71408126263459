import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: "#5C677D",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderTop: "1px solid #e8eaed",
    borderBottom: "1px solid #e8eaed",
    border: "0px",
    "&:first-child": {
      borderTop: "0px",
    },
    "&:last-child": {
      borderBottom: "0px",
    },
    // "&:nth-of-type(even)": {
    //   backgroundColor: "#FCFCFC",
    // },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const Contact = ({ userDetails }) => {
  return (
    <div>
      <div
        className="TableContainer oddEvenTable"
        style={{
          overflow: "hidden",
          borderRadius: "12px",
          border: "1px solid #e8eaed",
        }}
      >
        {/* <div
          className="TableInnerHeader"
          style={{
            border: "1px solid #e8eaed",
            borderBottom: "0px",
            padding: "12px",
            color: "#5C677D",
          }}
        >
          <h3>Access details</h3>
        </div> */}
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left" className="TableLabel">
                  Preferred Contact
                </StyledTableCell>
                <StyledTableCell align="left" className="TableInfo">
                  <div className="cellwithchip">
                    {userDetails?.preferredContact || (
                      <span style={{ color: "#BBBFC9" }}>
                        No data available
                      </span>
                    )}
                  </div>
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Email</StyledTableCell>
                <StyledTableCell align="left">
                  {userDetails?.email || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Home Phone</StyledTableCell>
                <StyledTableCell align="left">
                  {userDetails?.homePhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">Work Phone</StyledTableCell>
                <StyledTableCell align="left">
                  {userDetails?.workPhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell align="left">
                  Cell/Mobile Phone
                </StyledTableCell>
                <StyledTableCell align="left">
                  {userDetails?.cellPhone || (
                    <span style={{ color: "#BBBFC9" }}>No data available</span>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Contact;
