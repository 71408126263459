import React, { useEffect, useState } from "react";
import { useUpdatePaymentStatusMutation } from "../../redux/features/parentPortal/payments/paymentsApi";
import useProtected from "../../hooks/useProtected";
import { toast } from "react-toastify";

const PaymentsThanksPage = (props) => {

    const [updatePaymentStatus, updatePaymentStatusOptions] = useUpdatePaymentStatusMutation();

    useProtected(updatePaymentStatusOptions.error)

    const updatePayment = async (data) => {
        let result = await updatePaymentStatus(data)
        console.log(result)
        if(result?.data && result?.data?.error != true){
            toast?.success("Paid successfully")
        }else{
            toast?.error("Something went wrong")
        }
    }
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        let paymentIntent = query.get('payment_intent');
        let data = {
            "paymentIntentId": paymentIntent
        }
        updatePayment(data)
    },[])
  return (
    <div>
        <p style={{fontWeight: "bold", textAlign: "center", marginTop : "30px", fontSize : "20px"}}>Thank you!</p>
    </div>
  );
};

export default PaymentsThanksPage;
