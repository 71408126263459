import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';

import TextField from '../../../components/Form/TextField/TextField';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';

import { getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';


const StudentInformation = (props) => {


  const { id } = useParams();

  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
    setBirthDate,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear, errors } = props;

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    // console.log(dates, 893547685)

    // console.log(54645, birthMonth, birthYear, dates)
    setDateOptions(dates)
  }, [birthMonth, birthYear]);



  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });




  return (

    <>
      <div className={styles.FormTitle}>Student information</div>

      <div className={styles.ApplicationDescription}>



        <ScrollBar
          ScrollBarHeight=""
          body={
            <div className={styles.ApplicationForm}>

              <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.FieldLabel}>Full name* <div className={styles.ErrorState}>Required</div></div>
                  <div className={styles.FormGroup}>
                    
                    <TextField
                      placeholder="First name"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                      error
                    />
                    <TextField
                      placeholder="Middle name"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    error
                  
                    />
                    <TextField
                      placeholder="Last name"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    error
                    />

                  </div>

                </Grid>

                <Grid item xs={12} sm={8} md={8}>

                  <div className={styles.FieldLabel}>Date of birth* <div className={styles.ErrorState}>Required</div></div>
                  <div className={styles.FormGroup}>
                    <ComboBox
                      placeholder="Month"
                      validation={false}
                      options={getMonths()}
                      selectedOption={birthMonth && birthMonth - 1}
                      handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                     error
                    />

                    <ComboBox
                      placeholder="Day"
                      validation={false}
                      options={dateOptions}
                      selectedOption={birthDate && birthDate}
                      handleChange={(fieldData) => setBirthDate(fieldData)}
                      error
                    />

                    <ComboBox
                      placeholder="Year"
                      validation={false}
                      options={getYears()}
                      selectedOption={birthYear}
                      handleChange={(fieldData) => setBirthYear(fieldData)}
                      error
                    />

                  </div>

                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  <div className={styles.FieldLabel}>Gender* <div className={styles.ErrorState}>Required</div></div>
                  <div className={styles.FieldLabelHide}>
                    <ComboBox
                      placeholder="Select"
                      validation={false}
                      options={getMonths()}
                      selectedOption={birthMonth && birthMonth - 1}
                      handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                      error
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <div className={styles.FormSingleField}>
                    <TextField
                      LabelStatus={true}
                      Fieldlabel="Student State ID#"
                      TooltipStatus={true}
                      TooltipIcon={<HelpOutlineRoundedIcon />}
                      placeholder="9 digit ID"
                      TooltipText="This number is on top of every report card"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    />
                  </div>
                </Grid>


                <Grid item xs={12} sm={6} md={6}>
                  <div className={styles.FieldLabel}>Entering grade/program* <div className={styles.ErrorState}>Required</div></div>
                  <div className={styles.FieldLabelHide}>
                    <ComboBox
                      placeholder="Select"
                      validation={false}
                      options={getMonths()}
                      selectedOption={birthMonth && birthMonth - 1}
                      handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                      error
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.FieldLabel}>Contact info <div className={styles.ErrorState}>Required</div></div>
                  <div className={styles.FormGroup}>
                    <TextField
                      placeholder="Email address"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    error
                    
                    />

                    <div className={styles.PhoneField} >
                    <PhoneInputField
                      phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                      country={'us'}
                      value={phone.number}
                      countryCodeEditable={false}
                      onChange={(phone, country) => console.log("onChange", phone, country)}
                      onBlur={() => console.log("onBlur")}
                      onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                      variant="outlined"
                      // onlyCountries={["us", "ca", "gb", "my"]}
                      containerStyle={{}}
                      buttonStyle={{}}
                      inputStyle={{
                        marginLeft: "0px",
                        height: "48px",
                        width: "100%",
                        fontFamily: "Roboto-Regular",
                        fontSize: "16px",
                      }}
                      containerClass={{}}
                      autoFormat={true}
                      disableDropdown={false}
                      disabled={false}
                      disableCountryCode={false}
                      enableSearch={false}
                      disableSearchIcon={false}
                      placeholder={""}
                      inputProps={{}}
                      specialLabel={'Primary Phone'}
                      error="error"
                      errorStatus={true}
                    />
                    </div>

                  </div>

                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <div className={styles.FormSingleField}>
                    <ComboBox
                      label="Ethnicity*"
                      placeholder="Select"
                      validation={false}
                      options={getMonths()}
                      selectedOption={birthMonth && birthMonth - 1}
                      handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                      TooltipStatus={true}
                      TooltipIcon={<HelpOutlineRoundedIcon />}
                      TooltipText="Select all that apply - one is required"
                      RequiredLabel={true}
                      error
                    />
                  </div>
                </Grid>


                <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.FormSingleField}>
                    <TextField
                      LabelStatus={true}
                      Fieldlabel="Resident address*"
                      placeholder="Full address"
                    // value={courseData.Id}
                    // handleChange={handleChanges("Id")}
                    RequiredLabel={true}
                    error
                    />
                  </div>
                  <div className={styles.LinkText}><a href='#'>Enter address manually</a></div>
                </Grid>





              </Grid>
            </div>
          }
        />




      </div>

    </>
  );
};

export default StudentInformation;
