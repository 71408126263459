import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';
import Grid from '@mui/material/Grid';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';
import FileUplaoder from '../../../components/Form/FileUplaoder/FileUplaoder';

const Upload = (props) => {



  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (

    <>




      <div className={styles.ApplicationForm}>

        <Grid container spacing={2}>


        <Grid item xs={12} sm={12} md={12}>
            <CheckBox
              label="Check here if insurance is not applicable"
              checked={state.checkedB}
              onChange={handleChangeCheck}
              color="primary"
            />
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
              <FileUplaoder 
                label="Front"
              />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
              <FileUplaoder 
                label="Back"
              />
          </Grid>


        </Grid>
      </div>



    </>
  );
};

export default Upload;
