import React from "react";
import Header from "../../components/Header/Header";

const ParentPortal = () => {
  return (
    <>
      {/* <Header /> */}
      <div className="LaunchingCard">
        <a className="PortalLogo">
          <img src="https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png" />
          <h1>
            We are launching <br />
            very soon
          </h1>
          <p>
            DeenSchool is all-in-one, cloud based and secured <br />
            education management platform for Islamic K-12 & <br /> weekend
            schools
          </p>
        </a>
      </div>
    </>
  );
};

export default ParentPortal;
