import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../../../components/Form/Button/Button';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import EditFamilyMembers from './EditFamilyMembers';
import AddSibling from './AddSibling';
import AddHouseholdIncome from './AddHouseholdIncome';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const ReportCardAndEssay = (props) => {

  //=== Family Members Drawer===//
  const [openFamilyMembers, setOpenFamilyMembers] = useState(false);
  const handleDrawerOpenFamilyMembers = () => {
    setOpenFamilyMembers(true);
  };
  const handleDrawerCloseFamilyMembers = () => {
    setOpenFamilyMembers(false);
  };

  //=== MAdd Sibling Drawer===//
  const [openAddSibling, setOpenAddSibling] = useState(false);
  const handleDrawerOpenAddSibling = () => {
    setOpenAddSibling(true);
  };
  const handleDrawerCloseAddSibling = () => {
    setOpenAddSibling(false);
  };

  //=== Mother Information ===//
  const [openAddHouseholdIncome, setOpenAddHouseholdIncome] = useState(false);
  const handleDrawerOpenAddHouseholdIncome = () => {
    setOpenAddHouseholdIncome(true);
  };
  const handleDrawerCloseAddHouseholdIncome = () => {
    setOpenAddHouseholdIncome(false);
  };







  const guardianData = [
    {
      Title: "Copy of Birth Certificate",
      Description: "Please upload your child’s birth certificate (optional)",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },

    {
      Title: "Proof of Residency",
      Description: "Any current home utility bill or lease agreement, please no cell phone bills.",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenAddSibling,

      CardBody: 
      <>
      <div className={styles.ListingContainer}>
        <div className={styles.BoldText}>Filename.pdf</div>
        <div className={styles.SubText}>20MB</div>
        <div className={styles.ListingActionButton}>
          <Button variant="text"><CloseRoundedIcon /></Button>
        </div>
      </div>

      <div className={styles.ListingContainer}>
        <div className={styles.BoldText}>Filename.pdf</div>
        <div className={styles.SubText}>20MB</div>
        <div className={styles.ListingActionButton}>
          <Button variant="text"><CloseRoundedIcon /></Button>
        </div>
      </div>

      </>,
    },

    {
      Title: "Report Card/Transcript",
      Description: "Please upload your child’s latest report card (optional)",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },

    {
      Title: "Copy of NYS Health Physical",
      Description: "Subtitle",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },

    {
      Title: "Copy of current immunization records",
      Description: "Subtitle",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },

    {
      Title: "Essay",
      Description: "Upload your child's writing here",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },

    {
      Title: "Report Card",
      Description: "Please upload your child’s latest report card (optional)",
      ButtonLabel: "CHOOSE FILE",
      ButtonIcon: <FileUploadOutlinedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenFamilyMembers,
    },


  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> File Attachments
          <div className={styles.ApplicationSubText}>Please make sure to upload the following documents only for new enrollment. 
          You can also drop off hard copies to the school if preferred. You do not need to resubmit these documents for re-enrollment. 
          You will only need to submit the most up-to-date.</div>
        </div>
      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonFilled={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                  CardBody={item.CardBody}
                />
              ))}
            </div>
          }
        />




      </div>





      {/*=== Family Members Drawer */}
      <SideDrawer
        title="Family Members"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openFamilyMembers}
        handleDrawerClose={handleDrawerCloseFamilyMembers}
        FooterWithoutTab={true}
        body={
          <EditFamilyMembers />
        }
      />


      {/*=== Add Sibling Drawer */}
      <SideDrawer
        title="Add Sibling"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddSibling}
        handleDrawerClose={handleDrawerCloseAddSibling}
        FooterWithoutTab={true}
        body={
          <AddSibling />
        }
      />

      {/*=== Household Income Drawer */}
      <SideDrawer
        title="Household Income"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddHouseholdIncome}
        handleDrawerClose={handleDrawerCloseAddHouseholdIncome}
        FooterWithoutTab={true}
        body={
          <AddHouseholdIncome />
        }
      />



    </>
  );
};

export default ReportCardAndEssay;
