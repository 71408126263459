import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';

import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '../../../components/Form/Button/Button';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddContact from './AddContact';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';



const Contacts = (props) => {

  //=== ADD Contact ===//
  const [openAddContact, setOpenAddContact] = useState(false);
  const handleDrawerOpenAddContact = () => {
    setOpenAddContact(true);
  };
  const handleDrawerCloseAddContact = () => {
    setOpenAddContact(false);
  };




  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };



  const guardianData = [
    {
      Title: "Contact 1",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenAddContact,

    },

    {
      Title: "Shakib Mahmud",
      Description: "Done",
      StatusIcon: <CheckCircleRoundedIcon />,
      StatusIconColor: "#0b9444",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenAddContact

    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> Contacts
          <div className={styles.ApplicationSubText}>Please add at least two contacts</div>
        </div>


        <Button
          label="Contact"
          icon={<AddRoundedIcon />}
          buttonColor="#0450E1"
          border="#0450E1 1px solid"
          color="#fff"
          width=""
          height="32px"
          fontSize="14px"
          onClick={handleDrawerOpenAddContact}
        />

      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                />
              ))}

              <CheckBox
                label={<div>If a need arises for my child to be picked up from school and I cannot be reached,<br/>
                  I authorize school personnel to call any of the above persons to pick up my child.</div>}
                checked={state.checkedB}
                onChange={handleChangeCheck}
                color="primary"
              />
            </div>
          }
        />




      </div>


 


      {/*=== Add Guardian Drawer */}
      <SideDrawer
        title="Add Contact"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddContact}
        handleDrawerClose={handleDrawerCloseAddContact}
        FooterWithoutTab={true}
        body={
          <AddContact />
        }
      />


      



    </>
  );
};

export default Contacts;
