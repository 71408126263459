import React, { useState } from "react";
import styles from "./css/application.module.css";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import SelectPlanCard from "../../../components/Form/SelectPlanCard/SelectPlanCard";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import Payment from "./stripe/payment";
import Button from "@mui/material/Button";

const SelectPlan = () => {
  //=== Father Information ===//
  const [openPayment, setOpenPayment] = useState(false);
  const handleDrawerOpenPayment = () => {
    setOpenPayment(true);
  };
  const handleDrawerClosePayment = () => {
    setOpenPayment(false);
  };

  const [value, setValue] = React.useState(0);
  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsData = [
    {
      title: "PLAN 1",
      price: "$400",
      time: "/school year",
      description:
        "Full payment of tuition by June 30th. This qualifies for the early pay discount",
      onClick: handleDrawerOpenPayment,
    },

    {
      title: "PLAN 2",
      price: "$200",
      time: "/month",
      description:
        "Eleven (11) monthly payments made by the 1st of every month through automat",
      //onClick:onClick,
    },

    {
      title: "PLAN 3",
      price: "$100",
      time: "/school year",
      description:
        "Full payment of tuition by June 30th. This qualifies for the early pay discount",
      //onClick:onClick,
    },
  ];

  return (
    <>
      <div className={styles.ApplicationDescription}>
        <div className={styles.ApplicationStartTitle}>Select Plan</div>
        <SelectPlanCard tabsData={tabsData} value={value} onChange={onChange} />
        <div className={styles.TuitionFeeDetailsTitle}>
          <h1>Tuition/Fee Details</h1>
        </div>
        <ScrollBar
          ScrollBarHeight="400px"
          body={
            <div className={styles.TuitionFeeDetails}>
              <h2>Re-enrollment Registration Fees:</h2>
              <ul>
                <li>Registration - $250.00 per student</li>
                <li>Student Fee - $250.00 per family</li>
              </ul>

              <h2>New Student:</h2>
              <ul>
                <li>Registration - $400 (includes application fees)</li>
                <li>Student Fee - $250.00 per family</li>
              </ul>

              <h2>Annual Tuition:</h2>
              <ol>
                <li>K-8th Full Day Program......$6,426 per student</li>
                <li>High School 9th-12th........$8,500 per student</li>
                <li>Hifz Program (optional).....$800 per student</li>
              </ol>

              <p>
                After-school care, field trips, uniforms and uniform demerits,
                etc. are not included in any of the above fees. The school board
                has the right to change tuition and fees upon adoption of the
                New Year's budget.
                <span>The school board</span> has the right to change tuition
                and fees upon adoption of the New Year's budget.
              </p>
            </div>
          }
        />
      </div>

      {/*=== Payment Drawer */}
      <SideDrawer
        title="Checkout"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openPayment}
        handleDrawerClose={handleDrawerClosePayment}
        FooterWithoutTab={false}
        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={true}
        SubDrawerWithOutTab={true}
        SubDrawerWithOutTabBody={
          <div className={styles.OderSummary}>
            <h1>Order Summary</h1>

            <div className={styles.OderSummaryListingOuter}>
              <div className={styles.OderSummaryListing}>
                <li>PLAN 1</li>
                <li>$400</li>
              </div>
            </div>

            <div className={styles.OderSummaryListingOuter}>
              <div className={styles.OderSummaryListing}>
                <li>Subtotal</li>
                <li>$2,000.80</li>
              </div>
              <Button className={styles.OderSummaryPromocode}>
                Add promotion code
              </Button>
            </div>

            <div className={styles.OderSummaryTotal}>
              <li>Total</li>
              <li>$1,900.80</li>
            </div>
          </div>
        }
        body={
          <>
            <Payment />
          </>
        }
      />
    </>
  );
};

export default SelectPlan;
