import React, { useEffect, useRef, useState } from "react";
import Table from "../../components/Table/Table";
import Img from "../../assets/imgs/Visa.png";

import SideDrawer from "../../components/SideDrawer/SideDrawer";
import AddPaymentMethod from "./AddPaymentMethod";
import SelectCardTab from "../../components/SelectCardTab/SelectCardTab";
import BankAccount from "./BankAccount";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import useProtected from "../../hooks/useProtected";
import Payment from "./stripe/payment";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import {
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useLazyGetPaymentMethodsQuery,
} from "../../redux/features/parentPortal/payments/paymentsApi";
import DraggableDialog from "../../components/Dialog/Dialog";
import { useSelector } from "react-redux";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";

const PaymentMethods = (props) => {
  const {
    methodVerified,
    setupIntentData,
    setMethodVerified,
    defaultPaymentMethod,
    paymentMethodAddMode,
    setPaymentMethodsData,
    setDefaultPaymentMethod,
    setPaymentMethodAddMode,
    verifyMicroDepositsHandler,
    authorizedCheckPaymentMethod,
    authorizedFiredPaymentMethod,
    setAuthorizedCheckPaymentMethod,
    setAuthorizedFiredPaymentMethod,
  } = props;

  const {
    data: paymentMethods,
    isFetching,
    isLoading,
    error,
  } = useGetPaymentMethodsQuery(undefined);
  const [
    getPaymentMethodsLazy,
    {
      data: paymentMethodsLazy,
      isFetching: isFetchingLazy,
      isLoading: isLoadingLazy,
      error: errorLazy,
    },
  ] = useLazyGetPaymentMethodsQuery(undefined);

  const user = useSelector((state) => state.auth.user);
  const [schoolData, setSchoolData] = useState({});
  const {
    data: schoolInfoData,
    isLoading: schoolIsLoading,
    isError: schoolIsError,
  } = useGetSchoolQuery(user?.schoolId, {
    refetchOnMountOrArgChange: true,
  });
  const [deletePaymentMethod, deletePaymentMethodOptions] =
    useDeletePaymentMethodMutation();

  useProtected(deletePaymentMethodOptions.error);
  useProtected(errorLazy);

  const [rows, setRows] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [removePaymentId, setRemovePaymentId] = useState("");
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setRows(convertDataToCellRows(paymentMethods?.body?.data));
      setPaymentMethodsData(paymentMethods?.body?.data);
    }
  }, [paymentMethods, isFetching, isLoading]);

  useEffect(() => {
    if (schoolInfoData && !schoolIsError && !schoolIsLoading) {
      setSchoolData(schoolInfoData?.body?.data);
    }
  }, [schoolInfoData, schoolIsLoading, schoolIsError]);

  useEffect(() => {
    if (methodVerified) {
      setRowsAgain();
    } else {
      setMethodVerified(false);
    }
  }, [methodVerified]);

  const setRowsAgain = async () => {
    let result = await getPaymentMethodsLazy();
    console.log(result);
  };

  const paymentMethodRemoveHandler = (id) => {
    setRemovePaymentId(id);
    setOpenRemoveModal(true);
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      let foundRecurringNumber = 0;
      return arr.map((item, index) => {
        console.log(item, "item");
        return {
          cell: [
            {
              iconStart: <AccountBalanceIcon style={{ color: "#6d768a" }} />,
              IconColor: "#6d768a !important",
              text: item?.accountType
                ? `${item?.accountType} - ${item?.lastFourDigits}`
                : "N/A",
              align: "left",
              chip: item?.isDefault ? "Default" : "",
              chipStatus: item?.isDefault,
            },
            { text: item?.accountHolderName || "N/A" },
            {
              Status: true,
              background: item?.isVerified ? "#EDF7EA" : "#FDEEE6",
              color: "#001233",
              linebackground: item?.isVerified ? "#41AB37" : "#EF7531",
              statusLine: true,
              label: item?.isVerified ? "Active" : "Pending",
            },
            {
              Button: !item?.isVerified,
              buttonLabel: "VERIFY",
              buttonColor: "#5423FF",
              buttonBorder: "#5423FF 1px solid",
              buttonTextcolor: "#fff",
              buttonLink: false,
              onClick: (e) => verifyMicroDepositsHandler(item?._id),
            },
            {
              ActionButtonDotIcon: true,
              ActionButtonLabel: "Action",
              TextAlign: "right",
            },
          ],

          //======= Action Date Start===== //
          action: [
            { label: "UPDATE", onClick: onClick },
            { label: "VERIFY ", onClick: onClick },
            {
              label: "REMOVE  ",
              onClick: (e) => paymentMethodRemoveHandler(item?._id),
            },
          ],
        };
      });
    } else {
      return [];
    }
  };

  const onClick = () => {
    alert("onclick working fine!");
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Account",
      width: "20%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Account holder",
      width: "50%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Status",
      width: "20%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "",
      width: "10%",
      textAlign: "left",
    },
  ];

  // const rows = [
  //   {
  //     cell: [
  //       { iconStart: <AccountBalanceIcon style={{color:'#6d768a'}}/>, IconColor:"#6d768a !important", text: 'MasterCard - 9213', align: 'left', chip: 'Default', chipStatus: true, },
  //       { text: 'Shayan Mahmud' },
  //       { Status: true, background: '#EDF7EA', color: '#001233', linebackground: "#41AB37", statusLine: true, label: 'Active', },
  //       { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: 'UPDATE', onClick: onClick },
  //       { label: 'VERIFY ', onClick: onClick },
  //       { label: 'REMOVE  ', onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       { iconStart: <AccountBalanceIcon style={{color:'#6d768a'}}/>, IconColor:"#6d768a !important", text: 'MasterCard - 9213', align: 'left', chip: 'Default', chipStatus: true, },
  //       { text: 'Shayan Mahmud' },
  //       { Status: true, background: '#EDF7EA', color: '#001233', linebackground: "#41AB37", statusLine: true, label: 'Active', },
  //       { ActionButtonDotIcon: true, ActionButtonLabel: 'Action', TextAlign: "right" },

  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: 'UPDATE', onClick: onClick },
  //       { label: 'VERIFY ', onClick: onClick },
  //       { label: 'REMOVE  ', onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  // ];

  const [value, setValue] = React.useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsData = [
    {
      title: "Credit Card",
    },

    {
      title: "ACH/Bank Account",
    },
  ];

  const panelDate = [
    {
      id: 0,
      panel: <AddPaymentMethod />,
    },

    {
      id: 1,
      panel: <BankAccount />,
    },
  ];

  const authorizePaymentMethodCheckHandler = (e) => {
    setAuthorizedCheckPaymentMethod(e.target.checked);
  };

  const defaultCheckHandler = (e) => {
    setDefaultPaymentMethod(e.target.checked);
  };

  // Create a ref to pass to the GrandchildComponent
  const grandchildRef = useRef();

  const callGrandchildMethod = async () => {
    if (!authorizedCheckPaymentMethod) {
      toast.error("Please confirm check");
      return false;
    }
    if (grandchildRef.current) {
      grandchildRef.current.handleClick();
    }
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveModal(false);
  };

  const confirmRemovePaymentMethodHandler = async () => {
    let result = await deletePaymentMethod(removePaymentId);
    if (!result?.error) {
      toast.success("Payment method removed");
      handleCloseRemoveDialog();
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <div>
      <Table headCells={headCells} rows={rows} footer={true} RowsPerPage={10} />

      <SideDrawer
        title="Setup Payment Method"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={props.OpenDrawer}
        handleDrawerClose={props.CloseDrawer}
        FooterWithoutTab={true}
        ButtonLabel="Save"
        body={
          <div>
            {setupIntentData?.client_secret && (
              <>
                <Payment
                  ref={grandchildRef}
                  stripePromise={stripePromise}
                  setStripePromise={setStripePromise}
                  setPaymentSetUpDrawer={props.CloseDrawer}
                  paymentMethodAddMode={paymentMethodAddMode}
                  defaultPaymentMethod={defaultPaymentMethod}
                  clientSecret={setupIntentData?.client_secret}
                  setPaymentMethodAddMode={setPaymentMethodAddMode}
                  setDefaultPaymentMethod={setDefaultPaymentMethod}
                  setAuthorizedCheckPaymentMethod={
                    setAuthorizedCheckPaymentMethod
                  }
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      sx={{ padding: "2px 0px 0px 0px", marginRight: "4px" }}
                      onChange={(e) => authorizePaymentMethodCheckHandler(e)}
                      checked={authorizedCheckPaymentMethod}
                    />
                  }
                  label={`I authorize ${schoolData?.dba} to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits`}
                  labelPlacement="end"
                  sx={{ marginLeft: "4px", alignItems: "flex-start" }}
                />
                <br />
                <br />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      sx={{ padding: "2px 0px 0px 0px", marginRight: "4px" }}
                      onChange={(e) => defaultCheckHandler(e)}
                      checked={defaultPaymentMethod}
                    />
                  }
                  label={`Make this my default payment method`}
                  labelPlacement="end"
                  sx={{ marginLeft: "4px", alignItems: "flex-start" }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginTop: "12px",
                    padding: "10px 0",
                    fontWeight: 700,
                    borderRadius: "8px",
                    boxShadow: "none",
                    background: "#0450E1",
                    "&:hover": {
                      background: "#0450E1",
                      boxShadow: "none",
                    },
                  }}
                  // onClick={(e) => authorizeHandler()}
                  onClick={(e) => callGrandchildMethod()}
                >
                  Authorize
                </Button>
              </>
            )}
            {/* <SelectCardTab
              tabsData={tabsData}
              panelDate={panelDate}
              value={value}
              onChange={onChange}
            /> */}
          </div>
        }
      />

      <DraggableDialog
        openDialog={openRemoveModal}
        handleCloseDialog={handleCloseRemoveDialog}
        handleConfirmCloseDialog={(e) => confirmRemovePaymentMethodHandler()}
        title="Remove payment method?"
        body={
          <p>
            Are you sure you want to remove this payment method?
            <br />
            Once you delete it, you can't get it back.
          </p>
        }
        ModalFooter={true}
        actionButton="Remove"
      />
    </div>
  );
};

export default PaymentMethods;
