import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import AddMedicalContact from './AddMedicalContact';
import HomeLanguageQuestionnaire from './HomeLanguageQuestionnaire';
import SchoolDetails from './SchoolDetails';
import ChildDescription from './ChildDescription';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InsuranceDetails from './InsuranceDetails';
import Allergies from './Allergies';
import Medications from './Medications';
import MedicalRecords from './MedicalRecords';


const MedicalInformation = (props) => {

  //=== Add Medical Contact Drawer===//
  const [openAddMedicalContact, setOpenAddMedicalContact] = useState(false);
  const handleDrawerOpenAddMedicalContact = () => {
    setOpenAddMedicalContact(true);
  };
  const handleDrawerCloseAddMedicalContact = () => {
    setOpenAddMedicalContact(false);
  };

  //=== Insurance Details Drawer===//
  const [openInsuranceDetails, setOpenInsuranceDetails] = useState(false);
  const handleDrawerOpenInsuranceDetails = () => {
    setOpenInsuranceDetails(true);
  };
  const handleDrawerCloseInsuranceDetails = () => {
    setOpenInsuranceDetails(false);
  };

  //=== Schoo Details Drawer ===//
  const [openMedications, setOpenMedications] = useState(false);
  const handleDrawerOpenMedications = () => {
    setOpenMedications(true);
  };
  const handleDrawerCloseMedications = () => {
    setOpenMedications(false);
  };

   //=== Allergies Drawer ===//
   const [openAllergies, setOpenAllergies] = useState(false);
   const handleDrawerOpenAllergies = () => {
     setOpenAllergies(true);
   };
   const handleDrawerCloseAllergies = () => {
     setOpenAllergies(false);
   };

   //=== Medical Records Drawer ===//
   const [openMedicalRecords, setOpenMedicalRecords] = useState(false);
   const handleDrawerOpenMedicalRecords = () => {
     setOpenMedicalRecords(true);
   };
   const handleDrawerCloseMedicalRecordss = () => {
     setOpenMedicalRecords(false);
   };


   




  const guardianData = [
    {
      Title: "Medical contact",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "ADD CONTACT",
      ButtonIcon: <AddRoundedIcon />,
      ButtonOutlineOnClick: handleDrawerOpenAddMedicalContact,

    },

    {
      Title: "Insurance details",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenInsuranceDetails,

    },

    

    {
      Title: "Medications",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenMedications,

    },

    {
      Title: "Allergies",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Edit",
      ButtonIcon: <svg  width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.375 2.625a2.121 2.121 0 1 1 3 3L12 15l-4 1l1-4Z"/></g></svg>,
      ButtonOutlineOnClick: handleDrawerOpenAllergies,

    },

    {
      Title: "Medical records access authorization",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Sign",
      ButtonIcon: <i class="ri-sketching"></i>,
      ButtonOutlineOnClick: handleDrawerOpenMedicalRecords,

    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div> Medical Information
          <div className={styles.ApplicationSubText}>In case of emergency when parents or authorized persons cannot be contacted, 
          I hereby grant permission to the school personnel to secure care for my child from the doctor below, or if that doctor 
          is unavailable, from a local medical center.</div>
        </div>
      </div>


      <div className={styles.ApplicationContainer}>

        <ScrollBar
          ScrollBarHeight="600px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                  CardBody={item.CardBody}
                />
              ))}
            </div>
          }
        />




      </div>





      {/*=== Add Medical Contact Drawer */}
      <SideDrawer
        title="Add Medical Contact"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddMedicalContact}
        handleDrawerClose={handleDrawerCloseAddMedicalContact}
        FooterWithoutTab={true}
        body={
          <AddMedicalContact />
        }
      />


      {/*=== Home Language Questionnaire (HLQ) Drawer */}
      <SideDrawer
        title="Insurance Details"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openInsuranceDetails}
        handleDrawerClose={handleDrawerCloseInsuranceDetails}
        FooterWithoutTab={true}
        body={
          <InsuranceDetails />
        }
      />

      {/*=== School Details Drawer */}
      <SideDrawer
        title="Medications"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openMedications}
        handleDrawerClose={handleDrawerCloseMedications}
        FooterWithoutTab={true}
        body={
          <Medications />
        }
      />

      {/*=== Child Description Drawer */}
      <SideDrawer
        title="Allergies"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAllergies}
        handleDrawerClose={handleDrawerCloseAllergies}
        FooterWithoutTab={true}
        body={
          <Allergies />
        }
      />

      {/*=== Medical Records Access Authorization Drawer */}
      <SideDrawer
        title="Medical Records Access Authorization"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openMedicalRecords}
        handleDrawerClose={handleDrawerCloseMedicalRecordss}
        FooterWithoutTab={true}
        body={
          <MedicalRecords />
        }
      />






    </>
  );
};

export default MedicalInformation;
