import { api } from "../../../api/apiSlice";

const academicYearApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAcademicYears: builder.query({
      query: () => ({
        url: `/parent-portal/current-academic-year`,
      }),
      providesTags: ["Academic-years"],
    }),
  }),
});

export const { useGetAcademicYearsQuery, useLazyGetAcademicYearsQuery } =
  academicYearApi;
