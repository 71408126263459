import React from "react";
import styles from './css/emptycard.module.css';


import OrganizationLogo from "../../../assets/imgs/application-organization-logo.png";
import DeenSchoolLogo from "../../../assets/imgs/ds-logo.png";
import Button from '@mui/material/Button';

const StudentsEmpty = () => {
  return (

    <>
      <div className={styles.PageHeader}>
        <a href="#"><img src={OrganizationLogo} /></a>

      </div>

      <div className={styles.CardContainer}>

        <div className={styles.RedirectedText}>You are being redirected to DeenSchool, please wait...</div>

        <div className={styles.Card}>
          <div className={styles.CardLogo}>
            <a href="#"><img src={DeenSchoolLogo} /></a>
          </div>

          <div className={styles.MainText}>Quran Academy for Young Scholars have partnered with <span>DeenSchool</span> for reliable and secured application.</div>
          <div className={styles.SubText}>If you haven’t been redirected in 10 seconds,<br/> please click the button below.</div>

          <div className={styles.CardButtonContainer}>
          <Button href="/new-application" className={styles.CardButton}>Continue</Button>
          </div>

        </div>


      </div>

    </>
  );
};

export default StudentsEmpty;
