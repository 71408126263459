import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
// import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
// import styles from '../../../assets/css/form.module.css';
import Buttons from '../../components/Form/Button/Button';
import EmailIcon from '../../assets/imgs/email-icon.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import axiosApi from '../../utils/axios';
import LogoHeader from './LogoHeader/LogoHeader';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

function ForgotPasswordEmailSent(props) {
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState('');
  const [signUpEmail, setSignUpEmail] = useState('');
  const [searchParams] = useSearchParams();
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');

  useEffect(() => {
    const id = searchParams.get('userId');
    const innerSignUpEmail = searchParams.get('signUpEmail');
    const innerEmail = searchParams.get('email');
    if (id && innerSignUpEmail) {
      setUserId(id);
      setSignUpEmail(innerSignUpEmail);
      setModalTitle('Check your email');
      setModalDescription(`We've sent a verification link to ${signUpEmail}.
      Simply click the verification link in your inbox.`);
    }

    if (innerEmail) {
      setEmail(innerEmail);
      setModalTitle(
        'Thanks, email has been sent with reset password instructions',
      );
      setModalDescription(
        `If you haven't received an email in 5 minutes, check your spam or`,
      );
    }
  }, [searchParams]);

  const handleResendEmail = async () => {
    if (email || userId) {
      let data;
      let result;

      if (email) {
        data = {
          email,
        };
      } else if (userId) {
        data = {
          userId,
        };
      }

      try {
        if (email) {
          result = await axiosApi.post('/users/public/forgot-password', data);
        } else if (userId) {
          result = await axiosApi.post('/users/public/resend-signup-otp', data);
        }

        if (result.data.error) {
          setAlertOptions({
            msg: result.data.message,
            type: 'error',
          });
          return;
        }

        setAlertOptions({
          msg: result.data.message,
          type: 'success',
        });
      } catch (err) {
        setAlertOptions({
          msg: err.message,
          type: 'error',
        });
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOptions({
      msg: '',
      type: '',
    });
  };

  const navigator = useNavigate()
  const handleSendToHomePage = () => {
    navigator("/")
  }

  return (
    <>
      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner" style={{ borderRadius: "12px", }}>
          <div className="SignForm">
            <div className="SignFormTopIcon">
              <img src={EmailIcon} alt="Sent Email" />
            </div>
            <h1>{modalTitle}</h1>
            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />
            <Grid container spacing={2}>

              <Grid item xs={12} md={12}>
                <p className="FormDesText14">{userId ? <span>We've sent a verification link to <a href={`mailto:${signUpEmail}`}>{signUpEmail}</a>.
                  Simply click the verification link in your inbox.</span> : <span>If you haven't received an email in 5 minutes, check your spam or</span>}</p>

                {
                  email && (
                    <div className="RedendButtom">
                      <Buttons
                        label="resend"
                        buttonColor="#FFF"
                        border="#ccc 0px solid"
                        color="#5423FF"
                        width=""
                        height="48px"
                        fontSize="14px"
                        fontWeight="500"
                        textDecoration="underline"
                        textTransform="initial"
                        // disabled={true}
                        link=""
                        onClick={handleResendEmail}
                      />
                    </div>
                  )
                }


                {/* <div className="ContinueOtpButton">
                  <Buttons
                    label="Send it again"
                    buttonColor="#FFB606"
                    border="#FFB606 1px solid"
                    color="#000"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    // disabled={true}
                    link=""
                    onClick={handleResendEmail}
                  />
                </div> */}

                <div className="ContinueOtpButton">
                  <Buttons
                    label="Back to sign in"
                    buttonColor="#5423FF"
                    border="#5423FF 1px solid"
                    color="#fff"
                    width="100%"
                    height="48px"
                    fontSize="14px"
                    fontWeight="600"
                    link=""
                    onClick={handleSendToHomePage}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordEmailSent;
