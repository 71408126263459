import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

//  * ==== How to use it ==== * //
// const [selectedOption, setSelectedOption] = useState(null);

// const handleSelect = (selectedOption) => {
//     setSelectedOption(selectedOption);
//     console.log("Selected Option in Parent:", selectedOption);
//   };

// <AutoCompleteSelectFiled
//    options={options}
//    onSelect={handleSelect}
//    selectedValue={selectedOption}
// />

// const options = [
//     {
//       label: "Arabic",
//       value: "arabic",
//     },
//     {
//       label: "English",
//       value: "english",
//     },
//   ];

// * ======================== * //

const AutoCompleteSelectFiled = (props) => {
  const [selectedValue, setSelectedValue] = useState(
    props.selectedValue || null
  );

  useEffect(() => {
    setSelectedValue(props.selectedValue || null);
  }, [props.selectedValue]);

  const handleOptionSelect = (event, selectedOption) => {
    setSelectedValue(selectedOption);
    props.onSelect(selectedOption);
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        value={selectedValue}
        options={props.options}
        onChange={handleOptionSelect}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} />}
        placeholder={props.placeholder}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": { padding: "8px" },
          "& .MuiButtonBase-root": {
            padding: "12px",
          },
          "& .MuiAutocomplete-endAdornment": {
            top: "2px",
          },
          "& .MuiButtonBase-root:last-child": {
            padding: "12px 0",
          },
          "& .MuiButtonBase-root:last-child:hover": {
            background: "transparent",
          },
        }}
      />
    </div>
  );
};

export default AutoCompleteSelectFiled;
