import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import HorizontalTab from '../../../components/HorizontalTab/HorizontalTab';

import AddManually from './AddManually';
import Upload from './Upload';



const InsuranceDetails = (props) => {


    const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const tabLabel = [
    {
      label: "Add Manually",
    },
    {
      label: "Upload",
    },
  ]

  const tabPanel = [
    {
      id: 0,
      panel: <AddManually />,
    },
    {
      id: 1,
      panel: <Upload />,
    },

  ]


  return (

    <>




      <div className={styles.ApplicationForm}>
        <HorizontalTab
          tabLabel={tabLabel}
          tabPanel={tabPanel}
          value={value}
          handleChange={handleChange}
        />

      </div>



    </>
  );
};

export default InsuranceDetails;
