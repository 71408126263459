import React, { useState, useEffect } from "react";
// import { useSearchParams } from "react-router-dom";
// import axios from 'axios';

// import { getUserData } from '../../../hooks/HelperFunctions';

// import CustomizedSnackbar from '../../../hooks/Snackbar';
// import SimpleBackdrop from '../../../components/Form/Backdrop/SimpleBackdrop'
import VerticalTab from "../../../components/VerticalTab/VerticalTab";
import { Person, Receipt, Security } from "@mui/icons-material";
// import PersonalInfo from './personal-info/PersonalInfo';
// import Billing from './billing/Billing';
// import Account from './account/Account';
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import { RiLockPasswordFill } from "react-icons/ri";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Buttons from "../../../components/Form/Button/Button";
import AccSecurity from "./Security/AccSecurity";
import Profile from "./Profile/Profile";
import { useGetUserDetailsQuery } from "../../../redux/features/users/userApi";
import { useSelector } from "react-redux";
import {
  getProfileAvatarFromName,
  toTitleCase,
  mapMonth,
} from "../../../utils/helpers";
import useProtected from "../../../hooks/useProtected";
import { useUpdateSettingsMutation } from "../../../redux/features/users/userApi";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/features/authSlice";

// import Buttons from '../../../components/Form/Button/Button';
import SimpleBackdrop from "./../../../components/Form/Backdrop/SimpleBackdrop";
import { Divider } from "@mui/material";
import SideDrawer from "../../../components/SideDrawer/SideDrawer";
import UpdatePersonalInfo from "./Profile/UpdatePersonalInfo";
import ParentHome from "../ParentHome";
import { useGetSingleParentDetailsQuery } from "../../../redux/features/parentPortal/students/studentsApi";
import { useUpdateUserDataMutation } from "../../../redux/features/parentPortal/users/usersApi";
import { capitalizeWords } from "../../../hooks/HelperFunctions";

function SettingsPage(props) {
  //  * ========================= redux api ====================== * //
  const [updateSettings, updateSettingsOptions] = useUpdateUserDataMutation();

  useProtected(updateSettingsOptions.error);

  const userData = useSelector((state) => state.auth.user);
  // console.log(data, 328);
  const { data, isLoading, error } = useGetSingleParentDetailsQuery(
    userData?.userUniqueID,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  useProtected(error);
  const envType = process.env.REACT_APP_ENV;
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [startTab, setStartTab] = useState(0);

  const [birthDate, setBirthDate] = useState(null);
  const [birthMonth, setBirthMonth] = useState(null);
  const [birthYear, setBirthYear] = useState(null);
  const [manualAddress, setManualAddress] = useState(false);
  const [personalInfo, setPersonalInfo] = useState({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    status: "",
    preferredContact: "",
    homePhone: "",
    homePhoneDialCode: "",
    workPhone: "",
    cellPhone: "",
    ext: "",
    address: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    occupation: "",
    preferredLanguage: "",
    gender: "",
    userUniqueID: "",
    profilePic: "",
    birthDate: "",
    highestEducation: "",
    timezone: "",
    country: "",
    userType: "",
  });

  useEffect(() => {
    if (data && !isLoading && !error) {
      getPersonalInfo(data?.body?.data);
      let addressStatus = data?.body?.data?.addressLine1 ? false : true;
      setManualAddress(addressStatus);
    }
  }, [data, isLoading, error]);
  // console.log("formData", formData)

  // * ================= Edit sideDrawer ================= * //

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const EditDrawerOpen = () => setOpenEditDrawer(true);
  const EditDrawerClose = () => {
    // setPersonalInfo(userData);

    setOpenEditDrawer(false);
  };

  // * ================ Edit profile handler ================== * //

  //================================== sturcted the data =====================================//

  const getPersonalInfo = (data) => {
    return setPersonalInfo({
      ...personalInfo,
      _id: data?._id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      status: data?.status,
      preferredContact: data?.preferredContact,
      homePhone: data?.homePhone,
      homePhoneDialCode: data?.homePhone,
      workPhone: data?.workPhone,
      address: data?.address,
      addressLine1: data?.addressLine1,
      addressLine2: data?.addressLine2,
      city: data?.city,
      state: data?.state,
      zip: data?.zip,
      occupation: data?.occupation,
      preferredLanguage: data?.preferredLanguage,
      highestEducation: data?.highestEducation,
      gender: data?.gender,
      userUniqueID: data?.userUniqueID,
      profilePic: data?.profilePic,
      birthDate: data?.birthDate,
      cellPhone: data?.cellPhone,
      ext: data?.ext,
      country: data?.country,
      role: data?.role,
      nysedId: data?.nysedId,
      whatsappNumber: data?.whatsappNumber,
      timezones: data?.timezones,
      userType: data?.userType,
    });
  };
  //================================== sturcted the data =====================================//

  //========================= setting birthDate ===============================================//

  useEffect(() => {
    // setFormData(data)
    if (personalInfo?.birthDate) {
      const dateObj = moment(personalInfo?.birthDate);

      const year = dateObj.get("year");
      const month = dateObj.get("month") + 1;
      const day = dateObj.get("date");
      if (!birthDate && personalInfo?.birthDate) setBirthDate(day);
      if (!birthMonth && personalInfo?.birthDate) setBirthMonth(month);
      if (!birthYear && personalInfo?.birthDate) setBirthYear(year);
    }
  }, [personalInfo]);
  useEffect(() => {
    if (birthYear && birthMonth && birthDate) {
      setPersonalInfo({
        ...personalInfo,
        [birthDate]: new Date(birthYear, birthMonth, birthDate),
      });
    }
  }, [birthYear, birthMonth, birthDate]);

  //========================= setting birthDate ===============================================//
  //=========================== errors hangdeling ======================================//
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    timezone: "",
    birthMonth: "",
    state: "",
    birthDate: "",
    birthYear: "",
  });

  const checkBasicFormField = async () => {
    let errorObject = { ...errors };
    let error = 0;
    if (personalInfo.firstName == "") {
      errorObject = { ...errorObject, firstName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, firstName: "" };
    }

    if (personalInfo.lastName == "") {
      errorObject = { ...errorObject, lastName: "Required" };
      error++;
    } else {
      errorObject = { ...errorObject, lastName: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };
  //=========================== errors hangdeling ======================================//
  //============================ update data ==========================================//
  const handleUpdate = async () => {
    let isValid = await checkBasicFormField();

    if (!isValid) {
      // alert(3425);
      return false;
    }

    const data = {
      role: personalInfo?.role,
      gender: personalInfo?.gender,
      whatsappNumber: personalInfo?.whatsappNumber,
      homePhone: personalInfo?.homePhone,
      homePhoneDialCode: personalInfo?.homePhoneDialCode,
      workPhone: personalInfo?.workPhone,
      cellPhone: personalInfo?.cellPhone,
      ext: personalInfo?.ext,
      country: personalInfo?.country,
      firstName: personalInfo?.firstName,
      lastName: personalInfo?.lastName,
      middleName: personalInfo?.middleName,
      address: manualAddress ? personalInfo?.address : "",
      addressLine1: !manualAddress ? personalInfo?.addressLine1 : "",
      addressLine2: !manualAddress ? personalInfo?.addressLine2 : "",
      city: !manualAddress ? personalInfo?.city : "",
      state: !manualAddress ? personalInfo?.state : "",
      zip: !manualAddress ? personalInfo?.zip : "",
      profilePic: personalInfo?.profilePic,
      profilePictureName: personalInfo?.profilePictureName,
      nysedId: personalInfo?.nysedId,
      occupation: personalInfo?.occupation,
      preferredLanguage: personalInfo?.preferredLanguage,
      preferredContact: personalInfo?.preferredContact,
      highestEducation: personalInfo?.highestEducation,
      email: personalInfo?.email,
      secondaryEmail: personalInfo?.secondaryEmail,
      status: personalInfo?.status,
      timezones: personalInfo?.timezones,
      birthDate:
        birthYear && birthMonth && birthDate
          ? new Date(birthYear, birthMonth - 1, birthDate)
          : "",
    };
    const result = await updateSettings({ ...data });
    // console.log(result?.data?.body?.data, 328);
    if (!result?.data?.error) {
      let userInfo = result?.data?.body?.data || null;
      getPersonalInfo(userInfo);
      dispatch(setUserData(userInfo));
      localStorage.setItem("user", JSON.stringify(userInfo));
      toast.success("User data has updated successfully");
      setOpenEditDrawer(false);
    }
  };

  //============================ update data ==========================================//
  // const ffdghgfdhdg = async (id) => {
  //   await useUploadImgefdhfdhData();
  // };

  const handleEditProfile = () => {
    setEditProfile(true);
  };

  const tabData = [
    {
      icon: <Person />,
      label: "Profile",
    },

    envType !== "prod" && {
      icon: <RiLockPasswordFill />,
      label: "Access",
    },

    // {
    //     icon: <Receipt />,
    //     label: "Billing",
    // },
  ];

  const tabBody = [
    {
      id: 0,
      panel: (
        <Profile personalInfo={personalInfo} EditDrawerOpen={EditDrawerOpen} />
      ),
    },
    envType !== "prod" && {
      id: 1,
      panel: (
        <>
          <ParentHome />
        </>
      ),
    },
  ];

  const handleTabSwitch = (activeTab) => {
    console.log(activeTab);
    setCurrentTab(activeTab);
  };

  // useEffect(() => {
  //     if (!isLoading && data && !error) {
  //         setPersonalInfo(data.body.data)
  //     }
  // }, [data, error, isLoading])

  // const handleUpdateProfile = async (profileInfo) => {
  //     const data = {
  //         firstName: profileInfo?.firstName,
  //         middleName: profileInfo?.middleName,
  //         lastName: profileInfo?.lastName,
  //         gender: profileInfo?.gender,
  //         profilePic: profileInfo?.profilePic,
  //         country: profileInfo?.country,
  //         address: profileInfo?.address,
  //         birthDate: (profileInfo?.birthMonth && profileInfo?.birthYear) ? new Date(profileInfo?.birthYear, typeof profileInfo?.birthMonth === 'string' ? mapMonth(profileInfo?.birthMonth) : profileInfo?.birthMonth, profileInfo?.birthDay) : profileInfo?.birthDate,

  //     }
  //     const result = await updateSettings((data));

  //     if (!result?.data?.error) {
  //         EditDrawerClose()
  //         toast.success("Profile updated")
  //         // setFormData(personalInfo);
  //         const response = await refetch();
  //         if (!response?.data?.error) {
  //             const data = {
  //                 ...response.data.body.data,
  //                 birthMonth: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).month() : "",
  //                 birthYear: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).year() : "",
  //                 birthDay: response?.data?.body?.data?.birthDate ? moment(response?.data?.body?.data?.birthDate).date() : ""
  //             }
  //             setFormData(data);
  //             dispatch(setUserData({ ...data }));
  //         }
  //     } else {
  //         toast.error(result.data.message)
  //     }
  // };

  return (
    <div className="">
      {/* <SimpleBackdrop open={isLoading} /> */}
      <div className="UserDetails">
        <li>
          <div className="UserDetailsleftcol">
            <div className="UserDetailsrIcon">
              <Avatar
                sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                src={personalInfo?.profilePic || ""}
              >
                {" "}
                {personalInfo?.profilePic
                  ? ""
                  : getProfileAvatarFromName(personalInfo)}{" "}
              </Avatar>
              {/* {studentData.image ?
                                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={studentData.image ? studentData.image : ""} ></Avatar>
                                :
                            <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} > {studentData.firstName ? Array.from(studentData.firstName)[0] : ""} </Avatar>
                            } */}
            </div>

            <div className="UserDetailsDetail">
              <span className="settings-persone-name">
                <h1>
                  {personalInfo?.firstName}
                  &nbsp;
                  {personalInfo?.lastName}
                </h1>
                <span
                  className="activeLabel"
                  style={{ fontSize: "12px", fontWeight: 600 }}
                >
                  {capitalizeWords(personalInfo?.status)}
                </span>
              </span>
              <p style={{ fontSize: "16px" }}>
                <span>{capitalizeWords(personalInfo?.userType) || "N/A"}</span>
                <Divider
                  orientation="vertical"
                  sx={{
                    mb: 0,
                    mx: 0.5,
                    height: "10px",
                    display: "inline-block",
                    border: " 1px solid #C6C6D0",
                  }}
                  variant="middle"
                  flexItem
                />
                <span>ID: {personalInfo?.userUniqueID}</span>
              </p>
              {/* {studentData?.firstName && <h1>{`${studentData.firstName} ${studentData.lastName}`}</h1>}
                            {studentData?.email && <p>{studentData.email}</p>} */}
            </div>
          </div>

          {/* {currentTab === 0 && */}
          <div className="EditUserProfile">
            <Buttons
              label="Edit Profile"
              buttonColor="#fff"
              border="#E4E8EC 1px solid"
              color="#5423FF"
              width=""
              height="48px"
              fontSize="14px"
              // onClick={() => handleEditProfile()}
              onClick={() => EditDrawerOpen()}
            />
          </div>
          {/* } */}
        </li>

        {/* <div className="UserStatus">
          <div className="UserStatusCol">{totalRunningCourses} Running Courses</div>
          <div className="UserStatusCol">{totalCompletedCourses} Completed Courses</div>
          <div className="UserStatusCol">{totalCirtificates} Certificates</div>
        </div> */}
      </div>

      <div>
        <VerticalTab
          //title="Introduction to Islamic Economics and Finance"
          //name="MUNA Academy"
          link="#"
          tabData={tabData}
          tabBody={tabBody}
          onTabSwitch={handleTabSwitch}
          activeTab={startTab}
          //onClick={onClick()}
          //ButtonLabel="Start Class"
        />
      </div>
      {/* <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} /> */}

      {/* ============== profile update drawer ================ */}
      <SideDrawer
        title="Edit Profile"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        sideOpen="right"
        open={openEditDrawer}
        handleDrawerClose={EditDrawerClose}
        ButtonLeft="Upload New"
        FooterWithoutTab={true}
        ButtonLabel="Save"
        clickSaveButton={handleUpdate}
        body={
          <div>
            <UpdatePersonalInfo
              personalInfo={personalInfo}
              setPersonalInfo={setPersonalInfo}
              birthDate={birthDate}
              setBirthDate={setBirthDate}
              birthMonth={birthMonth}
              setBirthMonth={setBirthMonth}
              birthYear={birthYear}
              setBirthYear={setBirthYear}
              errors={errors}
              manualAddress={manualAddress}
              setManualAddress={setManualAddress}
            />
          </div>
        }
      />
    </div>
  );
}

export default SettingsPage;
