import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import "./SelectPlanCard.css";

//  const [value, setValue] = React.useState(0);

// const onChange = (event, newValue) => {
//   setValue(newValue);
// };

// const tabsData = [
//   {
//     title: "Individual",
//     description: "Customer is an individual",
//onClick:onClick,
//   },

//   {
//     title: "Organization",
//     description: "Customer is an organization",
//   },
// ]

// const panelData = [
//   {
//     id: 0,
//     panel: <Individual />,
//   },

//   {
//     id: 1,
//     panel: <p>scsdsfvjsdvlds</p>,
//   },

// ]

{
  /* <SelectPlanCard
  tabsData={tabsData}
  panelData={panelData}
  value={value}
  onChange={onChange}

/> */
}

function SelectPlanCard(props) {
  const { tabsData, panelData, onChange, value } = props;

  return (
    <div className={props.FullWidth}>
      <div className="SelectPlanCard">
        {props.FieldLabelStatus && (
          <div className="FieldLabel">
            {props.label} {props.validation && <span>*</span>}
          </div>
        )}
        <Tabs value={value} onChange={onChange}>
          {tabsData.map((item, i) => (
            <Tab
              key={i}
              disabled={item.disabled}
              label={
                <div className="SelectPlanCardContents" onClick={item.onClick}>
                  <h1>{item.title}</h1>
                  <div className="SelectPlanCardPrice">
                    {item.price} <span>{item.time}</span>
                  </div>
                  <p>{item.description}</p>

                  <div className="SelectPlanCardButton">
                    {/* Selected <CheckCircleRoundedIcon /> */}
                    {value === i ? (
                      <>
                        Selected <CheckCircleRoundedIcon />
                      </>
                    ) : (
                      "Select"
                    )}
                  </div>
                </div>
              }
              {...a11yProps(0)}
            />
          ))}
        </Tabs>
        {panelData && (
          <div className="RedioBoxTabBody">
            {panelData?.map((item, i) => (
              <TabPanel value={value} index={item.id}>
                {item.panel}
              </TabPanel>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default SelectPlanCard;
