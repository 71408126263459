import { api } from "../../../api/apiSlice";

const paymentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: () => ({
        url: `/payments/parent/find-all`,
      }),
      providesTags: ["Payments"],
    }),
    getPaymentMethods: builder.query({
      query: () => ({
        url: `/payment-methods/parent/find-all-payment-methods`,
      }),
      providesTags: ["PaymentMethods"],
    }),
    generatePaymentIntent: builder.mutation({
      query: (data) => ({
        url: `/payments/parent/generate-payment-intent`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
    updatePaymentStatus: builder.mutation({
      query: (data) => ({
        url: `/payments/parent/update-payment-status`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
    getCouponByCode: builder.mutation({
      query: (data) => ({
        url: `/coupons/parent/getCouponByCode`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Coupons"],
    }),
    applyCoupon: builder.mutation({
      query: (data) => ({
        url: `/payments/parent/apply-coupon`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Coupons"],
    }),
    generateSetupIntent: builder.mutation({
      query: () => ({
        url: `/payments/parent/generate-setup-intent`,
        method: "POST"
      })
    }),
    generateSetupIntentPaymentMethod: builder.mutation({
      query: () => ({
        url: `/payment-methods/parent/generate-setup-intent`,
        method: "POST"
      })
    }),
    confirmPaymentMethod: builder.mutation({
      query: (data) => ({
        url: `/payments/parent/confirm-payment-method`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Payments"],
    }),
    confirmPaymentMethodMain: builder.mutation({
      query: (data) => ({
        url: `/payment-methods/parent/confirm-payment-method`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PaymentMethods"],
    }),
    verifySetupIntent: builder.mutation({
      query: (data) => ({
        url: `/payments/parent/verify-setup-intent`,
        method: "POST",
        body: data,
      })
    }),
    verifyMicroDepositPaymentMethod: builder.mutation({
      query: (data) => ({
        url: `/payment-methods/parent/verify-micro-deposit`,
        method: "POST",
        body: data,
      })
    }),
    deletePaymentMethod: builder.mutation({
      query: (id) => ({
        url: `/payment-methods/parent/delete-payment-method/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PaymentMethods'],
    })
    
    
    
  }),
});

export const {
  useGetPaymentsQuery,
  useApplyCouponMutation,
  useGetPaymentMethodsQuery,
  useGetCouponByCodeMutation,
  useVerifySetupIntentMutation,
  useLazyGetPaymentMethodsQuery,
  useUpdatePaymentStatusMutation,
  useDeletePaymentMethodMutation,
  useGenerateSetupIntentMutation,
  useConfirmPaymentMethodMutation,
  useGeneratePaymentIntentMutation,
  useConfirmPaymentMethodMainMutation,
  useVerifyMicroDepositPaymentMethodMutation,
  useGenerateSetupIntentPaymentMethodMutation,
} = paymentsApi;
