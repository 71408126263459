import { api } from "../../../api/apiSlice";

const studentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudentsList: builder.query({
      query: (id) => ({
        url: `/parent-portal/students/${id}`,
      }),
      providesTags: ["Students"],
    }),
    getSingleStudentDetails: builder.query({
      query: (id) => ({
        url: `/parent-portal/student-details/${id}`,
      }),
      providesTags: ["Student"],
    }),
    getSingleStudentAttendance: builder.query({
      query: (id) => ({
        url: `/parent-portal/student-attendance/${id}`,
      }),
      providesTags: ["Student"],
    }),
    getAccademicYears: builder.query({
      query: () => ({
        url: `/parent-portal/academic-years`,
      }),
      providesTags: ["AccademicYears"],
    }),
    getSingleParentDetails: builder.query({
      query: (id) => ({
        url: `/parent-portal/user-details/${id}`,
      }),
      providesTags: ["Student"],
    }),

    addSingleStudentMainAttendance: builder.mutation({
      query: (data) => ({
        url: `/parent-portal/getStudentMainAttendance`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Attendance"],
    }),
    addSingleStudentCourseAttendance: builder.mutation({
      query: (data) => ({
        url: `/parent-portal/getStudentCourseAttendance`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Attendance"],
    }),
  }),
});

export const {
  useGetStudentsListQuery,
  useGetAccademicYearsQuery,
  useGetSingleParentDetailsQuery,
  useGetSingleStudentDetailsQuery,
  useLazyGetSingleParentDetailsQuery,
  useGetSingleStudentAttendanceQuery,
  useAddSingleStudentMainAttendanceMutation,
  useAddSingleStudentCourseAttendanceMutation,
} = studentsApi;
