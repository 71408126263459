import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import PortalSwitcher from "../../components/PortalSwitcher/PortalSwitcher";
import { useLocation } from "react-router-dom";

import websiteLogo from "../../assets/imgs/websiteLogo.png";
import ClassRoundedIcon from "@mui/icons-material/ClassRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { RiSurveyLine } from "react-icons/ri";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/authSlice";

import { AiOutlineCalendar } from "react-icons/ai";
import { AiFillCalendar } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import { RiHome4Fill } from "react-icons/ri";
import { RiPresentationLine } from "react-icons/ri";
import { RxPerson } from "react-icons/rx";
import { RxHome } from "react-icons/rx";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CustomMenuItem from "./CustomMenuItem";
import "../../assets/css/SideBarMenu.css";
import { useGetSchoolQuery } from "../../redux/features/schools/schoolApi";
import useProtected from "../../hooks/useProtected";
import { useEffect } from "react";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import {
  isTheActionPermitted,
  isTheSectionPermitted,
} from "../../hooks/HelperFunctionsPermission";
import { UserFill } from "../../assets/imgs/icons/UserFill";

function SideBarMenu(props) {
  // getting data from env file
  const dataFromEnvFile = process.env.REACT_APP_ENV;

  // const schoolInformation = useSelector(state => state.auth.user);
  const { schoolId } = useSelector((state) => state.auth.user);

  const { data, isError, isLoading, error } = useGetSchoolQuery(schoolId);

  useProtected(error);

  const pathname = useLocation().pathname;
  const qafysLogo =
    "https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png";
  const dsLogoUrl =
    process.env.REACT_APP_ENV === "stage"
      ? "https://stage-parent-portal.keeperedu.com/students"
      : "https://parent-portal.keeperedu.com/students";
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState("home");
  const [schoolInfo, setSchoolInfo] = useState({});
  const [schoolLogoIcon, setSchoolLogoIcon] = useState("");
  const [schoolLogoIconName, setSchoolLogoIconName] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolLogoName, setSchoolLogoName] = useState("");

  // useEffect(() => {
  //   setSchoolInfo(schoolInformation);
  // }, [schoolInformation])

  useEffect(() => {
    if (!isLoading && data && !error) {
      setSchoolInfo(data?.body?.data);
    }
  }, [data, error, isLoading]);

  useEffect(() => {
    setSchoolLogoIcon(schoolInfo?.schoolIcon || "");
    setSchoolLogoIconName(schoolInfo?.schoolIconName || "");
    setSchoolLogo(schoolInfo?.schoolLogo || "");
    setSchoolLogoName(schoolInfo?.schoolLogoName || "");
  }, [schoolInfo]);

  // const isTheSectionPermitted = (sectionName) => {
  //   let found = false
  //   rolesWithPermissions?.map(roles => {
  //     roles?.permissions?.map(singlePermission => {
  //       if (singlePermission?.name == sectionName) {
  //         found = true
  //       }
  //     })
  //   })
  //   return found
  // }

  return (
    <div className="SideBarContainer">
      <div className="SideBarInnerContainer">
        <div className="BrandMainLogo">
          {dsLogoUrl?.length > 0 && (
            <a
              className="Branding"
              href={dsLogoUrl?.length > 0 ? dsLogoUrl : "#"}
            >
              <img
                src={schoolLogo != "" ? schoolLogo : qafysLogo}
                style={{ height: "40px", objectFit: "contain" }}
              />
            </a>
          )}
        </div>

        <div className="SideBarMenu">
          <Sidebar collapsed={props.collapsed}>
            <PortalSwitcher />

            {/* ---------------------- School portal ------------------------ */}
            {props.ParentRoute && (
              <Menu>
                {/* <CustomMenuItem className={`${pathname === '/listing-example' ? 'LinkActive' : 'link'}`} href="/listing-example"> <MenuBookRoundedIcon />  <span className="MenuPer">Table Example</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/listing-example">Table Example</a></div></CustomMenuItem> */}

                {/* <CustomMenuItem
                  className={`${pathname === "/" ? "LinkActive" : "link"}`}
                  href="/"
                >
                  <RxHome /> <span className="MenuPer">Home</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/id">
                      Home
                    </a>
                  </div>
                </CustomMenuItem>

                <CustomMenuItem
                  className={`${pathname === "/inbox" ? "LinkActive" : "link"}`}
                  href="/inbox"
                >
                  <SmsOutlinedIcon /> <span className="MenuPer">Inbox</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/message">
                      Inbox
                    </a>
                  </div>
                </CustomMenuItem> */}

                <CustomMenuItem
                  className={`${
                    pathname === "/students" || pathname === "/"
                      ? "LinkActive"
                      : "link"
                  }`}
                  href="/students"
                >
                  {/* <PersonOutlineOutlinedIcon /> */}
                  <UserFill />
                  <span className="MenuPer">Students</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/students">
                      Students
                    </a>
                  </div>
                </CustomMenuItem>

                <CustomMenuItem
                  className={`${
                    pathname === "/payments" ? "LinkActive" : "link"
                  }`}
                  href="/payments"
                >
                  <RiSurveyLine /> <span className="MenuPer">Payments</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/payments">
                      Payments
                    </a>
                  </div>
                </CustomMenuItem>

                {/* <CustomMenuItem
                  className={`${
                    pathname === "/applications" ? "LinkActive" : "link"
                  }`}
                  href="/applications"
                >
                  <RiSurveyLine /> <span className="MenuPer">Applications</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/applications">
                      Applications
                    </a>
                  </div>
                </CustomMenuItem>

                <CustomMenuItem
                  className={`${
                    pathname === "/calender" ? "LinkActive" : "link"
                  }`}
                  href="/calender"
                >
                  <CalendarTodayOutlinedIcon />{" "}
                  <span className="MenuPer">Calendar</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/calender">
                      Calendar
                    </a>
                  </div>
                </CustomMenuItem> */}

                {/* <CustomMenuItem
                  className={`${
                    pathname === "/media-library-table-view"
                      ? "LinkActive"
                      : "link"
                  }`}
                  href="/media-library-table-view"
                >
                  <PermMediaOutlinedIcon />{" "}
                  <span className="MenuPer">Media</span>{" "}
                  <div className="MenuTooltip">
                    <a
                      className="MenuTooltipLink"
                      href="/media-library-table-view"
                    >
                      Media
                    </a>
                  </div>
                </CustomMenuItem> */}

                <CustomMenuItem
                  className={`${
                    pathname === "/profile" ? "LinkActive" : "link"
                  }`}
                  href="/profile"
                >
                  <AccountCircleOutlinedIcon />{" "}
                  <span className="MenuPer">Profile</span>{" "}
                  <div className="MenuTooltip">
                    <a className="MenuTooltipLink" href="/settings">
                      Settings
                    </a>
                  </div>
                </CustomMenuItem>

                {/* <CustomMenuItem
                  onClick={() => dispatch(logout())}
                >
                  <LogoutIcon />{' '}
                  <span className="MenuPer">Logout</span>{' '}
                  <div className="MenuTooltip">
                    <span className="MenuTooltipLink">
                      Log Out
                    </span>
                  </div>
                </CustomMenuItem> */}

                {/* <span className="divider"></span>
                            <CustomMenuItem className={`${pathname === '/coupons' ? 'LinkActive' : 'link'}`} href="/coupons"><InsightsRoundedIcon /> <span className="MenuPer">Coupons</span> <div className="MenuTooltip"><a className="MenuTooltipLink" href="/coupons">Coupons</a></div></CustomMenuItem> */}
              </Menu>
            )}
          </Sidebar>
        </div>
        <div className="poweredByContainer">
          <p>Powered by</p>
          {/* <img src={schoolLogo != "" ? schoolLogo : qafysLogo} style={{ height: "40px", objectFit: 'contain' }} /> */}
          <img
            src="https://web-assets.keeperedu.com/assets/logos/keeperedu-logos/keeper-education-management-logo-bg-wht.png"
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
}

export default SideBarMenu;
