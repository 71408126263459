import React, { useState } from "react";
import styles from './css/application.module.css';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';
import CardComponent from './components/CardComponent/CardComponent';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SchoolTerms from './SchoolTerms';
import PhotographPermission from './PhotographPermission';
import SchoolRecords from './SchoolRecords';
import ACHAuthorization from './ACHAuthorization';

const Contacts = (props) => {

  //=== ADD Contact ===//
  const [openAddContact, setOpenAddContact] = useState(false);
  const handleDrawerOpenAddContact = () => {
    setOpenAddContact(true);
  };
  const handleDrawerCloseAddContact = () => {
    setOpenAddContact(false);
  };


  //=== Photograph Permission Drawer===//
  const [openPhotographPermission, setOpenPhotographPermission] = useState(false);
  const handleDrawerOpenPhotographPermission = () => {
    setOpenPhotographPermission(true);
  };
  const handleDrawerClosePhotographPermission = () => {
    setOpenPhotographPermission(false);
  };

    //=== Photograph Permission Drawer===//
    const [openSchoolRecords, setOpenSchoolRecords] = useState(false);
    const handleDrawerOpenSchoolRecords = () => {
      setOpenSchoolRecords(true);
    };
    const handleDrawerCloseSchoolRecords = () => {
      setOpenSchoolRecords(false);
    };


    //=== ACHAuthorization Drawer===//
    const [openACHAuthorization, setOpenACHAuthorization] = useState(false);
    const handleDrawerOpenACHAuthorization = () => {
      setOpenACHAuthorization(true);
    };
    const handleDrawerCloseACHAuthorization = () => {
      setOpenACHAuthorization(false);
    };



  
    




  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const guardianData = [
    {
      Title: "School terms",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Sign",
      ButtonIcon: <i class="ri-sketching"></i>,
      ButtonOutlineOnClick: handleDrawerOpenAddContact,
    },

    {
      Title: "School records access authorization",
      Description: "Done",
      StatusIcon: <CheckCircleRoundedIcon />,
      StatusIconColor: "#0b9444",
      ButtonLabel: "Sign",
      ButtonIcon: <i class="ri-sketching"></i>,
      ButtonOutlineOnClick: handleDrawerOpenSchoolRecords
    },

    {
      Title: "Photograph permission",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Sign",
      ButtonIcon: <i class="ri-sketching"></i>,
      ButtonOutlineOnClick: handleDrawerOpenPhotographPermission,
    },

    {
      Title: "ACH authorization",
      Description: "Pending",
      StatusIcon: <WarningRoundedIcon />,
      StatusIconColor: "#ffb703",
      ButtonLabel: "Sign",
      ButtonIcon: <i class="ri-sketching"></i>,
      ButtonOutlineOnClick: handleDrawerOpenACHAuthorization,
    },
  ]


  return (

    <>
      <div className={styles.FormTitle}>
        <div>Terms</div>
      </div>


      <div className={styles.ApplicationContainer}>
        <ScrollBar
          ScrollBarHeight="530px"
          body={
            <div>
              {guardianData?.map((item, index) => (
                <CardComponent
                  Title={item.Title}
                  StatusIcon={item.StatusIcon}
                  StatusIconColor={item.StatusIconColor}
                  Description={item.Description}
                  ButtonOutline={true}
                  ButtonLabel={item.ButtonLabel}
                  ButtonIcon={item.ButtonIcon}
                  ButtonOutlineOnClick={item.ButtonOutlineOnClick}
                />
              ))}
            </div>
          }
        />

      </div>





      {/*=== Add Guardian Drawer */}
      <SideDrawer
        title="School Terms"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openAddContact}
        handleDrawerClose={handleDrawerCloseAddContact}
        FooterWithoutTab={true}
        body={
          <SchoolTerms />
        }
      />

      {/*=== Photograph Permission Drawer */}
      <SideDrawer
        title="Photograph Permission"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openPhotographPermission}
        handleDrawerClose={handleDrawerClosePhotographPermission}
        FooterWithoutTab={true}
        body={
          <PhotographPermission />
        }
      />

      {/*=== SchoolRecords Permission Drawer */}
      <SideDrawer
        title="School Record Release"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openSchoolRecords}
        handleDrawerClose={handleDrawerCloseSchoolRecords}
        FooterWithoutTab={true}
        body={
          <SchoolRecords />
        }
      />

      {/*=== ACHAuthorization Drawer */}
      <SideDrawer
        title="ACH Authorization"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={openACHAuthorization}
        handleDrawerClose={handleDrawerCloseACHAuthorization}
        FooterWithoutTab={true}
        body={
          <ACHAuthorization />
        }
      />






    </>
  );
};

export default Contacts;
