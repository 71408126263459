import React, { useState, useCallback } from 'react';
import styles from './css/application.module.css';

import Grid from '@mui/material/Grid';
import ScrollBar from '../../../components/ScrollBar/ScrollBar';

import TextField from '../../../components/Form/TextField/TextField';
import ComboBox from '../../../components/Form/ComboBox/ComboBox';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';
import Button from '../../../components/Form/Button/Button';
import CheckBox from '../../../components/Form/CheckBox/CheckBox';


import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { getDataMap, getDates, getDates2, getMonths, getYears } from "../../../hooks/HelperFunctions";
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import AddMore from './components/AddMore/AddMore';


const AddHouseholdIncome = (props) => {


  const { id } = useParams();

  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, birthDate,
    setBirthDate,
    birthMonth,
    setBirthMonth,
    birthYear,
    setBirthYear, errors } = props;

  const [selectedValue, setSelectedValue] = React.useState(false);
  const [openSelectMedia, setOpenSelectMedia] = useState(false);
  const [openNewMedia, setOpenNewMedia] = useState(false);
  const [mediaItem, setMediaItem] = useState({});
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  // dates
  const [dateOptions, setDateOptions] = useState([]);
  useEffect(() => {
    let dates = [];
    if (birthMonth !== undefined && birthYear !== undefined) {
      let daysInMonth;
      switch (birthMonth) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          daysInMonth = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          daysInMonth = 30;
          break;
        case 2:
          if ((birthYear % 4 === 0 && birthYear % 100 !== 0) || birthYear % 400 === 0) {
            daysInMonth = 29;
          } else {
            daysInMonth = 28;
          }
          break;
        default:
          daysInMonth = 0; // Invalid month
          break;
      }

      if (daysInMonth > 0) {
        for (let i = 1; i <= daysInMonth; i++) {
          dates.push({ value: i, label: i });
        }
      }
    }
    // console.log(dates, 893547685)

    // console.log(54645, birthMonth, birthYear, dates)
    setDateOptions(dates)
  }, [birthMonth, birthYear]);



  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (

    <>




      <div className={styles.ApplicationForm}>

        <Grid container spacing={2}>


          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.FieldLabel}>Income</div>
            <AddMore
              AddMoreButtonlabel="Add More"
              PrimaryLabelStatus={false}
              body={

                <div className={styles.FormGroup}>
                  <ComboBox
                    placeholder="Select"
                    validation={false}
                    options={getMonths()}
                    selectedOption={birthMonth && birthMonth - 1}
                    handleChange={(fieldData) => setBirthMonth(fieldData + 1)}
                  />
                  <TextField
                    placeholder="Yearly Income (USD)"
                    number="number"
                    CurrencyStatus="true"
                  // value={courseData.Id}
                  // handleChange={handleChanges("Id")}
                  />


                </div>

              }

            />

          </Grid>





          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.DividerLine}></div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={styles.TotalIncome}>
                  <h1>Total yearly income</h1>
                  <h2>0</h2>
                </div>
              </Grid>

            </Grid>
          </Grid>






        </Grid>
      </div>



    </>
  );
};

export default AddHouseholdIncome;
