"use client";

import React, { useState } from "react";
import TextFieldCom from "@mui/material/TextField";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Tooltip from "../Tooltip/Tooltip";
import "./TextField.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputAdornment from "@mui/material/InputAdornment";
{
  /* <TextField
    label="Enter Course Id"
    validation={false}
    CharAlignMid="CharAlignMid"
    Uppercase="Uppercase"
    count={6}
    value={courseData.Id}
    handleChange={handleChanges("Id")}
  /> */
}

function TextField(props) {
  const {
    label,
    required,
    validation,
    email,
    number,
    error,
    placeholder,
    value,
    handleChange,
    handleValidation,
    helperText,
    count,
    disabled,
    CharAlignMid,
    onkeyDown,
    name,
    LabelStatus,
    Fieldlabel,
    showErrorMsg = true,
    password,
  } = props;

  // const [validationText, setValidationText] = useState("");
  // const [innerError, setInnerError] = useState(error);

  const handleOnChange = (event) => {
    const value = event.target.value;
    // let error = false;

    // required field validation
    // if(required && value?.length > 0){
    //   setValidationError(false);
    //   setValidationText("");
    // } else{
    //   error = true;
    //   setValidationError(true);
    //   setValidationText("Required");
    // }

    // email field validation
    // if(email){
    //   if(emailValidation(value)){
    //     setInnerError(false)
    //     setValidationText("");
    //   } else{
    //     setInnerError(true)
    //     setValidationText("Email is not valid");
    //   }
    // }

    handleChange(value, event);
    if (handleValidation) {
      handleValidation(error);
    }
  };

  //   const emailValidation = (email) => {
  //     const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //     if(!email || regex.test(email) === false){
  //         return false;
  //     }
  //     return true;
  // }

  return (
    <>
      <div className="TextField">
        {label && (
          <div className="FieldLabel">
            <div>
              {label} {(required || validation) && <span>*</span>}
            </div>
            {props.TooltipStatus && (
              <Tooltip
                TooltipText={props.TooltipText}
                Tooltip={<InfoOutlinedIcon />}
              />
            )}
          </div>
        )}

        {LabelStatus && (
          <div className="FieldLabel">
            <div className="FieldLabelContainer">
              {Fieldlabel} {(required || validation) && <span>*</span>}
              {props.TooltipStatus && (
                <Tooltip
                  TooltipText={props.TooltipText}
                  Tooltip={props.TooltipIcon}
                />
              )}
            </div>
            {props.RequiredLabel && (
              <div className="FieldErrorState">Required</div>
            )}
          </div>
        )}

        <div className={props.CurrencyStatus}>
          <div className="FieldContainer">
            {/* <div className="FieldCurrencyIcon">$</div> */}
            <div className={CharAlignMid}>
              <TextFieldCom
                id="outlined-basic"
                variant="outlined"
                type={number ? "number" : password ? "password" : "text"}
                error={error}
                placeholder={placeholder}
                value={value}
                name={name}
                onChange={handleOnChange}
                helperText={showErrorMsg && helperText}
                disabled={disabled ? disabled : false}
                inputProps={{
                  maxLength: count,
                }}
                onkeyDown={onkeyDown}
                onBlur={props.onBlur}
                style={{ textTransform: "uppercase" }}
                sx={{ width: "25ch" }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">kg</InputAdornment>
                    ),
                  },
                }}
                InputProps={{
                  startAdornment: props.startAdornment && (
                    <InputAdornment position="start">
                      {props.startAdornment}
                    </InputAdornment>
                  ),
                  endAdornment: props.endAdornment && (
                    <InputAdornment position="end">
                      {props.endAdornment}
                    </InputAdornment>
                  ),
                }}
              />
              <span className="FieldCount">
                {count && (
                  <span
                    className="CharInner"
                    style={{ color: "#77767A", alignSelf: "flex-end" }}
                  >
                    {" "}
                    <span className="CHAR">CHAR</span>{" "}
                    {!value ? "0" : value.length}/{count}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        {/* temporary disabled by sujan */}
        {/* <a className="AvailableButton">Available?</a> */}
      </div>
    </>
  );
}

export default TextField;
