import { api } from "../../../api/apiSlice";

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateUserData: builder.mutation({
      query: (data) => ({
        url: `parent-portal/update-user`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UserDetails"],
    }),

    userImageUplodaToDB: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `/parent-portal/uploadProfileImage/${userId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UserDetails"],
    }),
  }),
});

export const { useUpdateUserDataMutation, useUserImageUplodaToDBMutation } =
  usersApi;
