import React from "react";
import styles from './CardComponent.module.css';
import Button from '../../../../../components/Form/Button/Button';




{/* <CardComponent
  Title="Family members"
  StatusIcon={<WarningRoundedIcon />}
  StatusIconColor="#ffb703"
  Description="Pending"
  ButtonOutline={true}
  ButtonLabel="Choose file"
  ButtonIcon={<i class="ri-edit-box-line"></i>}
  ButtonFilled={true}
  CardBody={
    <div>Body</div>
  }
/> */}




const CardComponent = (props) => {
  return (

    <>

      <div className={styles.CardContainer}>
        <div className={styles.CardInnerContainer}>
          <div className={styles.CardLeftCol}>

            <div className={styles.CardTitle}>
              {props.Title}
            </div>
            <div className={styles.CardDescription}>
              <span style={{ color: props.StatusIconColor }}>{props.StatusIcon}</span> {props.Description}
            </div>

          </div>

          <div className={styles.CardLeftCol}>
            {props.ButtonOutline &&
              <Button
                label={props.ButtonLabel}
                icon={props.ButtonIcon}
                buttonColor="#fff"
                border="#E8EAED 1px solid"
                color="#0450E1"
                width=""
                height="32px"
                fontSize="14px"
                onClick={props.ButtonOutlineOnClick}
              />
            }

            {props.ButtonFilled &&
              <Button
                label={props.ButtonLabel}
                icon={props.ButtonIcon}
                buttonColor="#0450E1"
                border="#0450E1 1px solid"
                color="#fff"
                width=""
                height="32px"
                fontSize="14px"
                onClick={props.ButtonFilledOnClick}
              />
            }
          </div>
        </div>

        <div>{props.CardBody}</div>

      </div>

    </>
  );
};

export default CardComponent;
