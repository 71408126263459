import React from "react";
import styles from './css/stepper.module.css';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import StepLabel from '@mui/material/StepLabel';


import DSLOGO from '../../../assets/imgs/ds-logo.png';

import Start from './Start';
import StudentInformation from './StudentInformation';
import GuardianInformation from './GuardianInformation';
import Family from './Family';
import Contacts from './Contacts';
import ReportCardAndEssay from './ReportCardAndEssay';
import Academics from './Academics';
import AdditionalDetails from './AdditionalDetails';
import MedicalInformation from './MedicalInformation';
import Attachments from './Attachments';
import Terms from './Terms';
import SelectPlan from './SelectPlan';




const StudentsEmpty = () => {

  const [activeStep, setActiveStep] = React.useState(1);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };







  const steps = ['Start', 'Student info', 'Guardian', 'Family', 'Contacts', 'Academics', 'Details', 'Medical', 'Attachments', 'Terms', 'Tuitions',];


  const stepsBody = [
    {
      id: 0,
      body: <Start />,
    },
    {
      id: 1,
      body: <StudentInformation />,
    },
    {
      id: 2,
      body: <GuardianInformation />,
    },
    {
      id: 3,
      body: <Family />,
    },
    {
      id: 4,
      body: <Contacts />,
    },
    {
      id: 5,
      body: <Academics/>,
    },

    // {
    //   id: 6,
    //   body: <ReportCardAndEssay />,
    // },

    {
      id: 6,
      body: <AdditionalDetails />,
    },
    {
      id: 7,
      body: <MedicalInformation />,
    },

    {
      id: 8,
      body: <Attachments />,
    },

    {
      id: 9,
      body: <Terms />,
    },

    {
      id: 10,
      body: <SelectPlan />,
    },




  ];


  return (

    <>

      <div className={styles.PageHeader}>
        <div className={styles.PageHeaderLeft}>
          <a href="/students" className={styles.PageHeaderBack}><ArrowBackIosNewRoundedIcon /></a>
          <a href="/students"><i class="ri-user-line"></i> Students</a>
          <span>/</span>
          <a href="#">New application</a>
        </div>

        <div className={styles.PageHeaderRight}>
          <Button className={styles.PageHeaderButton} variant="text">Save & exit</Button>
        </div>
      </div>



      <div className={styles.FormCotainer}>

        <div className={styles.StepperCotainer}>

          <div className={styles.StepperLabelContainer}>
            <div className={styles.StepperLabelContainerInner}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel  {...labelProps} onClick={handleStep(index)}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div className={styles.CompanyLogo}>
                <p>Powered by</p>
                <a href="#" ><img src={DSLOGO} /></a>
              </div>
            </div>
          </div>

          <div className={styles.StepperBodyContainer}>

            <div className={styles.StepperBodyInnerContainer}>
              {allStepsCompleted() ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>

                  {stepsBody.map((item, index) => (
                    <div>
                      {activeStep == item.id &&
                        <div>{item.body}</div>
                      }
                    </div>

                  ))}

                  <div className={styles.StepperButtonContainer}>
                    <div className={styles.StepperBackButton}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}

                      >
                        <ArrowBackRoundedIcon /> Back
                      </Button>
                    </div>

                    <Box sx={{ flex: '1 1 auto' }} />

                    {activeStep == 0 &&
                      <Button className={styles.ButtonContinue} onClick={handleNext} sx={{ mr: 1 }}>
                        Get started
                      </Button>
                    }

                    {activeStep > 0 &&
                      <Button className={styles.ButtonContinue} onClick={handleNext} sx={{ mr: 1 }}>
                        {activeStep < 10 && "Continue"}{activeStep == 9 && "Agree & Continue"} {activeStep > 9 && "Continue"} <ArrowForwardRoundedIcon />
                      </Button>
                    }



                    {/* {activeStep !== steps.length &&
                    (completed[activeStep] ? (
                      <Typography variant="caption" sx={{ display: 'inline-block' }}>
                        Step {activeStep + 1} already completed
                      </Typography>
                    ) : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? 'Finish'
                          : 'Complete Step'}
                      </Button>
                    ))} */}
                  </div>


                </React.Fragment>
              )}
            </div>
          </div>
        </div>


      </div>

    </>
  );
};

export default StudentsEmpty;
